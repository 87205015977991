import React from 'react';
import { checkForNull } from '../../../../utils/utils';

const DataDetails = ({ Label, LabelFor = [], children }) => {
  return (
    <div className='card-header-top d-flex'>
      <div className='card-lt-pane'>
        <h5>{Label}</h5>
        {Array.isArray(LabelFor) ? (
          <p>
            {LabelFor.map((value, index) => checkForNull(value)).join(', ')}
          </p>
        ) : (
          <p>{checkForNull(LabelFor)}</p>
        )}
        {children}
      </div>
    </div>
  );
};

export default DataDetails;
