import { useSelector, useDispatch } from 'react-redux';
import '../../assets/css/style.css';
import Header from '../../components/common/Header';
import Sidebar from '../../components/common/Sidebar';
import Card from '../../components/common/Card';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';
import SimpleSelectBox from '../../components/common/SimpleSelectBox';
import AdvancedCardHeader from '../../components/common/AdvancedCardHeader';
import atdplaceholder from '../../assets/images/atd-placeholder.jpg';
import { useEffect, useState } from 'react';
import Footer from '../../components/common/Footer';
import ReactSelect from '../../components/common/ReactSelect';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
import { useNavigate, Link, useLocation, useParams } from 'react-router-dom';
import moment from 'moment/moment';
import ErrorDisplay from '../../components/common/ErrorDisplay';
import axios from 'axios';
import CommonModal from '../../components/common/commonModal';
import Accordion from 'react-bootstrap/Accordion';

import { getApi, putApi, postApi } from '../../utils/Apis';
import SideTreeMenuForCreate from '../../components/dv360/SideTreeMenuForCreate';
import {
  setLineItemSelected,
  setLineItemName,
  setOptimizationType,
  setFlightType,
  setFlightStartDate,
  setFlightEndDate,
  setSelectedFrequenceyType,
  setSelectedOptimizedTarget,
  setFrequencyVal,
  setExposuresType,
  setBudgetSpacingType,
  setBudgetSpacingAmount,
  setBudgetSpacingFirstType,
  setBudgetSpacingSecondType,
  setQuality,
  setPublicInvetory,
  setDealsAndInventoryPackages,
  setGroups,
  setBudgetSpacingDailyPrice,
  setBudgetSpacingSecondOptionType,
  setAutomatedBiddingOptionOneVal,
  setAutomatedBiddingOptionTwoVal,
  setAutomatedBiddingExceedAvg,
  setAutomatedBiddingExceedAmount,
  setAutomatedBiddingPrioritizeDeal,
  setAutomatedBiddingType,
  setFixedBiddingPrice,
  setExposuresValue,
  setCreativeOptimizationVal,
  setAutomatedBiddingPrioritizeTargetCpm,
  setBudgetType,
  preFillValues,
  resetValues,
} from '../../state/DVLineItem/CreateCampaignActions';
import {
  lengthFieldCheck,
  multiSelectVal,
  decodeURI,
  prefillSelect,
  lineItemUrlFormat,
} from '../../utils/validations';
import {
  commonOptions,
  commonOptionWithDaily,
  budgetSpacingUnlimitedUpToFlightOptions,
  budgetSpacingUnlimitedUpToDailyOptions,
  budgetSpacingUnlimitedUpToFlightNumericOptions,
  budgetSpacingUnlimitedUpToDailyNumericOptions,
  budgetSpacingAutomatedAdjustFlightOptions,
  budgetSpacingAutomatedAdjustDailyOptions,
  budgetSpacingAutomatedAdjustFlightNumericOptions,
  budgetSpacingAutomatedAdjustDailyNumericOptions,
  creativeOptimization,
  baseUrlStg,
  groupsOptions,
  EnabledApproval,
} from '../../utils/constants';
import Loader from '../../components/common/Loader';
import AudioComponent from '../../components/dv360/audioComponent';
import ControlBidAndBudgetCase from '../../components/dv360/ControlBidAndBudgetCase';
import ControlBidAndBudgetAndAutomaticallyOptimize from '../../components/dv360/ControlBidAndBudgetAndAutomaticallyOptimize';
import BidAndStrategy from '../../components/dv360/BidAndStrategy';
import AutomateAndBudgetCase from '../../components/dv360/AutomateAndBudgetCase';
import DvErrorDisplay from '../../components/dv360/dvErrorDisplay';

// media widget
import MediaWidget from '../mediaLibrary/MediaWidgets/MediaWidget';
import { getCookies, renderMedia } from '../../utils/utils';
import SelectApprovalPublisher from '../../components/common/SelectApprovalPublisher';
import ApprovalConfirmationPopup from '../../components/common/ApprovalConfirmationPopup';
import ThankYouApprovalPopup from '../../components/common/ThankYouApprovalPopup';
import { ApproveReject } from '../../components/ApproveReject/ApproveReject';
const creativeArr = [];

function LineItem() {
  const [formErrors, setFormErrors] = useState({});
  const [numberCheckPacingAmount, setNumberCheckPacingAmount] = useState('');
  const [numberCheckDailySpacing, setNumberCheckDailySpacing] = useState('');
  const [numberCheckFixedBid, setNumberCheckFixedBid] = useState('');
  const [
    getAutomatedBiddingPrioritizeTargetCPAError,
    setAutomatedBiddingPrioritizeTargetCPAError,
  ] = useState('');
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState([]);
  const [landingUrl, setLandingUrl] = useState([]);
  const [logoDimension, setLogoDimension] = useState({ height: 0, width: 0 });
  const [campaignTreeData, setCampaignTreeData] = useState({});
  const handleShow = () => setShow(true);
  const [getQueryParam, setQueryParam] = useState({
    insertion_order_id: '',
    optimization: [],
    flag: '',
  });
  const [getInsertionOrderId, setInsertionOrderId] = useState(0);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [
    getAutomatedBiddingExceedAmountError,
    setAutomatedBiddingExceedAmountError,
  ] = useState('');
  const [getModerationStatus, setgetModerationStatus] = useState('');
  const [getFeqErr, setFeqErr] = useState('');
  const [getOptimizationTypeStatus, setOptimizationTypeStatus] = useState('');
  const [getOptimizationActionTypeStatus, setOptimizationActionTypeStatus] =
    useState('');
  const [isUpdate, setIsUpdate] = useState('');
  const [getTaxonomyCampaignId, setTaxonomyCampaignId] = useState('');
  const [getPublicInventoryValues, setPublicInvetoryValues] = useState('');
  const [getGroupsValues, setGroupsValues] = useState('');
  const [
    getDealsAndInventoryPackagesValues,
    setDealsAndInventoryPackagesValues,
  ] = useState('');
  const [UpdateTreeOnsaveDraft, setUpdateTreeOnsaveDraft] = useState(false);
  const location = useLocation();
  const dataFromRoute = location.state;
  const params = useParams();
  const [updatedCreative, setUpdatedCreative] = useState([]);
  const [getCampaignId, setCampaignId] = useState('');
  const [getMultipleSelectParam, setMultipleSelectParam] = useState('');
  const [showTreeMenu, setShowTreeMenu] = useState(false);
  const [lineCampaignID, setLineCampaignID] = useState('');
  const [
    getApprovalPublisherModelShowStatus,
    setApprovalPublisherModelShowStatus,
  ] = useState(false);
  const [
    getApprovalCoinfirmationPopupShowStatus,
    setApprovalCoinfirmationPopupShowStatus,
  ] = useState(false);
  const [
    getThankYouApprovalPopupShowStatus,
    setThankYouApprovalPopupShowStatus,
  ] = useState(false);
  const [getPublisherInfo, setPublisherInfo] = useState([]);
  const [getPublisherError, setPublisherError] = useState('');
  const [getCreateStatus, setCreateStatus] = useState(false);
  const [getCreateId, setCreateID] = useState('');
  const [isPublisher, setIsPublisher] = useState(false);
  const { handleAPICall } = ApproveReject();

  let getCookie = getCookies();
  //Created By Prashant
  let re = /^[0-9\b]+$/;
  const navigate = useNavigate();
  const initialvalues = {
    campaignGoal: 1,
    campaignName: '',
    kpi: '',
    ['kpi goal']: '',
    creative: [],
    ['Frequency cap']: '',
    ['planned Start date']: '',
  };
  let header = {
    accessToken: 'Test',
  };

  const [createCampignData, setCreateCampignData] = useState(initialvalues);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const DvCreateLineItemData = state?.DvCreateLineItem;
  const getUserInfo = state?.loginReducer?.userToken;

  Array.prototype.remove = function (value) {
    this.splice(this.indexOf(value), 1);
  };
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();

  useEffect(() => {
    setImages(updatedCreative);
  }, [updatedCreative]);
  // const handleBudgetType = (e) => {
  //   dispatch(setBudgetType(e.target.value));
  // };

  const onChangeHandler = (e) => {
    // console.log("jkjkl", e.target.value)
    if (e.target.type == 'checkbox') {
      if (e.target.checked) {
        creativeArr.push(e.target.value);
      } else {
        let Item = creativeArr.find((item) => item == e.target.value);
        creativeArr.remove(Item);
      }

      setCreateCampignData((prev) => {
        return { ...prev, [e.target.name]: creativeArr };
      });
    } else if (e.target.name == 'Frequency cap') {
      if (e.target.value == 'no limit') {
        setCreateCampignData((prev) => {
          return {
            ...prev,
            [e.target.name]: e.target.value,
            'Frequency limit exposure': '',
            'frequency limit value': '',
          };
        });
      }
      setCreateCampignData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else {
      setCreateCampignData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };

  //Created by Prashant Chavan
  const automateBiddingOptiWithThreeValDisplay = [
    'Maximize conversions',
    'Maximize clicks',
    'Maximize viewable impressions',
  ];
  const automateBiddingOptiWithFiveValVideo = [
    'Maximize conversions',
    'Maximize clicks',
    'Maximize viewable impressions',
    'Maximize completed in-view and audible',
    'Maximize viewable for at least 10 seconds',
  ];
  const automateBiddingOptiWithOneValAudio = ['Maximize conversions'];
  const automateBiddingSecondOptiWithOneValAudio = [
    'while prioritizing spending my full budget (recommended)',
    'while prioritizing a target viewable CPA',
  ];
  const automateBiddingOptionsTwoCPC = [
    'while prioritizing spending my full budget (recommended)',
    'while prioritizing a target viewable CPC',
  ];
  const automateBiddingOptionsTwo = [
    'while prioritizing spending my full budget (recommended)',
    'while prioritizing a target viewable CPM',
  ];
  const handleLineItemNameChange = (event) => {
    const value = event.target.value;
    dispatch(setLineItemName(value));
  };
  const handleLineItemSelect = (v) => {
    if (DvCreateLineItemData?.selected_line_item !== v) {
      dispatch(setLineItemSelected(v));
      setImages([]);
    }
  };

  const handleOptimizedTargeting = (e) => {
    dispatch(setSelectedOptimizedTarget(e.target.checked));
  };

  const handleFlightDates = (e) => {
    dispatch(setFlightType(e));
  };

  const startDateHandler = (e) => {
    dispatch(setFlightStartDate(e));
    let nextDay = moment(e).add(1, 'days');
    dispatch(setFlightEndDate(nextDay));
  };

  const endDateHandler = (e) => {
    dispatch(setFlightEndDate(e));
  };

  const handleLimitFrequencey = (e) => {
    dispatch(setSelectedFrequenceyType(e.target.value));
  };

  const handleExposureVal = (e) => {
    if (e.target.type == 'checkbox') {
      if (e.target.checked) {
        creativeArr.push(e.target.value);
      } else {
        let Item = creativeArr.find((item) => item == e.target.value);
        creativeArr.remove(Item);
      }

      setCreateCampignData((prev) => {
        return { ...prev, [e.target.name]: creativeArr };
      });
    } else if (e.target.name == 'Frequency cap') {
      if (e.target.value == 'no limit') {
        setCreateCampignData((prev) => {
          return {
            ...prev,
            [e.target.name]: e.target.value,
            'Frequency limit exposure': '',
            'frequency limit value': '',
          };
        });
      }
      setCreateCampignData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else {
      setCreateCampignData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }

    dispatch(setExposuresType(e.target.value));
  };

  const handlesExposuresValue = (e) => {
    dispatch(setExposuresValue(e.target.value));
  };

  const handleBudgetPacingType = (e) => {
    dispatch(setBudgetSpacingType(e.target.value));
  };

  const handleBudgetPacingAmount = (e) => {
    let currentVal = e.target.value;
    if (/^0/.test(currentVal)) {
      currentVal.replace(/^0/, '');
      setNumberCheckPacingAmount('The value should be greater than 0');
    } else {
      if (e.target.value === '' || re.test(e.target.value)) {
        dispatch(setBudgetSpacingAmount(e.target.value));
      } else {
        setNumberCheckPacingAmount('Enter a monetary value');
      }
    }
  };

  const handleBudgetSpacingFirstType = (e) => {
    if (e?.target?.value == 'Flight') {
      dispatch(setBudgetSpacingDailyPrice(''));
    }
    dispatch(setBudgetSpacingFirstType(e.target.value));
  };

  const handleBudgetSpacingSecondType = (e) => {
    dispatch(setBudgetSpacingSecondType(e.target.value));
  };

  const handleDailyPrice = (e) => {
    let currentVal = e.target.value;
    if (/^0/.test(currentVal)) {
      currentVal.replace(/^0/, '');
      setNumberCheckDailySpacing('The value should be greater than 0');
      setFormErrors({ ...formErrors, dailySpacingValidation: '' });
    } else {
      if (e.target.value === '' || re.test(e.target.value)) {
        dispatch(setBudgetSpacingDailyPrice(e.target.value));
        setNumberCheckDailySpacing('');
      } else {
        setNumberCheckDailySpacing('Enter a monetary value');
        setFormErrors({ ...formErrors, dailySpacingValidation: '' });
      }
    }
  };

  const handleFrequencyVal = (e) => {
    let currentVal = e.target.value;
    if (/^0/.test(currentVal)) {
      currentVal.replace(/^0/, '');
      setFeqErr('The value should be greater than 0');
    } else {
      if (e.target.value === '' || re.test(e.target.value)) {
        dispatch(setFrequencyVal(e.target.value));
      } else {
        setFeqErr('Enter a monetary value');
      }
    }
  };

  const handleQualityVal = (e) => {
    dispatch(setQuality(e.target.value));
  };

  const handlePublicInventory = (val) => {
    let values = multiSelectVal(val);
    dispatch(setPublicInvetory(values));
  };

  const handleDealInventory = (val) => {
    let values = multiSelectVal(val);
    dispatch(setDealsAndInventoryPackages(values));
  };

  const handleGroups = (val) => {
    let values = multiSelectVal(val);
    dispatch(setGroups(values));
  };

  const handleSecondOptionBudgetType = (e) => {
    dispatch(setBudgetSpacingSecondOptionType(e.target.value));
  };

  const hanldeAutomatedBiddingOptionsOne = (e) => {
    dispatch(setAutomatedBiddingOptionOneVal(e.target.value));
  };

  const hanldeAutomatedBiddingOptionsTwo = (e) => {
    dispatch(setAutomatedBiddingOptionTwoVal(e.target.value));
  };

  const hanldeAutomatedBiddingExceedAvg = (e) => {
    dispatch(setAutomatedBiddingExceedAvg(e.target.checked));
  };

  // Budget Code
  const hanldeAutomatedBiddingExceedAmount = (e) => {
    let currentVal = e.target.value;
    if (/^0/.test(currentVal)) {
      currentVal.replace(/^0/, '');
      setAutomatedBiddingExceedAmountError(
        'The value should be greater than 0',
      );
    } else {
      if (e.target.value === '' || re.test(e.target.value)) {
        dispatch(setAutomatedBiddingExceedAmount(e.target.value));
      } else {
        setAutomatedBiddingExceedAmountError('Enter a monetary value');
      }
    }
  };

  //fssssssssssss
  //  code
  // code

  const hanldeAutomatedBiddingPrioritizeDeal = (e) => {
    dispatch(setAutomatedBiddingPrioritizeDeal(e.target.checked));
  };

  //       const value = event.target.value;
  //       dispatch(setLineItemName(value));

  const handleToggleAutomateBidding = (e) => {
    // setFixedBid(false);
    // setAutomateBidding(true);
    // setAutomateBidding(e?.target?.checked);
    // dispatch(setLineItem(value));
    dispatch(setAutomatedBiddingType(e?.target?.value));
    dispatch(setAutomatedBiddingOptionTwoVal(''));
    dispatch(setAutomatedBiddingOptionOneVal(''));
    if (typeof formErrors.automated_bidding_option_one_value !== 'undefined') {
      formErrors.automated_bidding_option_one_value = '';
      formErrors.automated_bidding_option_two_value = '';
    }
  };

  const handleFixedBidVal = (e) => {
    let currentVal = e.target.value;
    if (/^0/.test(currentVal)) {
      currentVal.replace(/^0/, '');
      setNumberCheckFixedBid('Enter a value');
    } else {
      if (e.target.value === '' || re.test(e.target.value)) {
        dispatch(setFixedBiddingPrice(e.target.value));
      } else {
        setNumberCheckFixedBid('Enter a monetary value');
      }
    }
  };

  const handleCreativeOptimizationVal = (e) => {
    dispatch(setCreativeOptimizationVal(e.target.value));
  };

  const hanldeAutomatedBiddingPrioritizeTargetCPA = (e) => {
    let currentVal = e.target.value;
    if (/^0/.test(currentVal)) {
      currentVal.replace(/^0/, '');
      setAutomatedBiddingPrioritizeTargetCPAError(
        'The value should be greater than 0',
      );
    } else {
      if (e.target.value === '' || re.test(e.target.value)) {
        dispatch(setAutomatedBiddingPrioritizeTargetCpm(e.target.value));
      } else {
        setAutomatedBiddingPrioritizeTargetCPAError('Enter a monetary value');
      }
    }
  };

  const handleSideTreeDataAndUpdate = async () => {
    let response;
    if (query.get('campaign_id')) {
      //New create
      if (query.get('campaign_id')) {
        response = await getApi(
          `${baseUrlStg}/api/Campaign/GetCampaignWithInsertionOrderAndLineItemByID?campaignID=${query.get(
            'campaign_id',
          )}`,
          { accessToken: '1234' },
        );
      }
      try {
        if (response?.response?.length > 0) {
          setCampaignTreeData(response?.response?.[0]);
          setShowTreeMenu(true);
        }
      } catch (err) {
        console.log(err);
      }
    } else if (params['id'] != undefined) {
      // Update or Edit case
      let response = await getApi(
        `${baseUrlStg}/api/LineItem/getByLineItemsID?lineItemID=${params['id']}`,
        { accessToken: '1234' },
      );

      let campaignid = await response?.response?.insertion_order?.campaign_id;
      setCampaignId(campaignid);
      //Prefilled form data start
      if (response?.status) {
        setDisplayLoader(false);
        dispatch(preFillValues(response?.response));
        setLineCampaignID(response?.response?.insertion_order['campaign_id']);
        setgetModerationStatus(response?.response['moderation_status']);
        let target = {
          target: {
            name: 'flight_selected_type',
            value: response?.response?.flightdate_type,
          },
        };
        dispatch(setFlightType(target));
        setOptimizationTypeStatus(
          response?.response?.insertion_order?.optimization_type?.replace(
            '&',
            'and',
          ),
        );
        setOptimizationActionTypeStatus(
          response?.response?.insertion_order?.optimization_action,
        );
        setTaxonomyCampaignId(
          response?.response?.insertion_order?.taxonomy_campaign_id,
        );

        // set the images
        setUpdatedCreative(
          (response?.response?.creatives).map((data) => {
            if (!data.mst_media.med_thumbnail_path?.includes(`${baseUrlStg}`)) {
              data.mst_media.med_thumbnail_path =
                `${baseUrlStg}/` + data.mst_media.med_thumbnail_path;
            }

            return {
              ...data,
              imgName: data.mst_media.med_File_Name,
              mediaPath: data.mst_media.med_thumbnail_path,
              mediaThumbnail: data.mst_media.med_thumbnail_path,
              mediaId: data.mst_media.med_id,
              landingurlId:
                data.taxonomy_media_campaign_ad_cid
                  .taxonomy_media_campaign_ad_cid_id,
              landingUrl: data.taxonomy_media_campaign_ad_cid.landing_url,
              fileType: data.mst_media.med_type || 'image',
              status: 0,
            };
          }),
        );
        // Create Line Item page url in Multiple case.
        const redirectUrl =
          response?.response?.insertion_order?.optimization_action == ''
            ? `/dv-360/create-line-item/new?insertion_order_id=${response?.response?.insertion_order?.insertion_order_id}&campaign_id=${response?.response?.insertion_order?.campaign_id}&optimization=${response?.response?.insertion_order?.optimization_type}&cID=${response?.response?.insertion_order?.taxonomy_campaign_id}&flag=multiple`
            : `/dv-360/create-line-item/new?insertion_order_id=${response?.response?.insertion_order?.insertion_order_id}&campaign_id=${response?.response?.insertion_order?.campaign_id}&optimization=${response?.response?.insertion_order?.optimization_type},${response?.response?.insertion_order?.optimization_action}&cID=${response?.response?.insertion_order?.taxonomy_campaign_id}&flag=multiple`;

        setMultipleSelectParam(redirectUrl);
      }
      //Prefilled form data end

      // setInsertionOrderIdFromAPI(response?.response?.insertion_order?.insertion_order_id);
      // setOptimizationTypeFromAPI(response?.response?.insertion_order?.optimization_type);
      let responsee = await getApi(
        `${baseUrlStg}/api/Campaign/GetCampaignWithInsertionOrderAndLineItemByID?campaignID=${campaignid}`,
        { accessToken: '1234' },
      );
      try {
        if (responsee?.response?.length > 0) {
          setCampaignTreeData(responsee?.response?.[0]);
          setShowTreeMenu(true);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setCampaignTreeData({});
      setShowTreeMenu(true);
    }
  };

  useEffect(() => {
    handleSideTreeDataAndUpdate();
    setShowTreeMenu(false);
    setIsUpdate(params['id']);
  }, [query.get('campaign_id'), params['id'], UpdateTreeOnsaveDraft]);

  const handleClose = () => {
    navigate('/dv-360/campaigns-listing');
  };

  const handleUpdatePost = async (payload) => {
    header = {
      accessToken: 'Test',
    };

    const response = await putApi(
      `${baseUrlStg}/api/LineItem/updateLineItem`,
      '',
      payload,
      header,
    );

    if (response?.status == 200) {
      if (EnabledApproval) {
        setApprovalPublisherModelShowStatus(true);
      } else {
        setShowModal(true);
      }
      setDisplayLoader(false);
    }
  };

  const handleCreatePost = async (payload) => {
    let response = await postApi(
      `${baseUrlStg}/api/LineItem/createLineItem`,
      payload,
    );

    try {
      if (response?.status == 200 && getQueryParam?.flag == 'multiple') {
        if (EnabledApproval) {
          setApprovalPublisherModelShowStatus(true);
          setCreateStatus(true);
          setCreateID(response?.data?.lineItemId);
        } else {
          setDisplayLoader(false);
          handleShow(true);
          navigate(`/dv-360/create-line-item/${response?.lineItemId}`);
        }
      } else {
        if (EnabledApproval) {
          setApprovalPublisherModelShowStatus(true);
          setCreateStatus(true);
          setCreateID(response?.data?.lineItemId);
        } else {
          setDisplayLoader(false);
          handleShow(true);
        }
      }
    } catch (error) {
      console.log('errr', error);
    }
  };

  const isUpdateTrue = typeof isUpdate != 'undefined' && isUpdate != '';

  let creatives = {
    // creative_id: 0,
    line_item_id: 0,
    taxonomy_media_campaign_ad_cid_id: 1,
    med_id: 3,
    status: 1,
    created_at: moment().toDate(),
  };

  const mappedCreatives = images.map((data, index) => {
    return {
      ...creatives,
      creative_id: isUpdateTrue ? data.creative_id : 0,
      line_item_id: isUpdateTrue ? data.line_item_id : 0,
      med_id: data.id || data.mediaId,
      taxonomy_media_campaign_ad_cid_id: data.landingurlId,
    };
  });

  const imagesId = images.map((image) => image.creative_id);
  const updatedCreativeId = updatedCreative.map((image) => image.creative_id);
  const notInImages = updatedCreative.filter(
    (item) => !imagesId.includes(item.creative_id),
  );

  const mappedUpdatedCreatives = notInImages.map((data, index) => {
    return {
      ...creatives,
      creative_id: isUpdateTrue ? data.creative_id : 0,
      line_item_id: isUpdateTrue ? data.line_item_id : 0,
      med_id: data.id || data.mediaId,
      taxonomy_media_campaign_ad_cid_id: data.landingurlId,
      status: 0,
    };
  });
  const handleRedirection = () => {
    navigate(`/dv-360/campaigns-listing`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate());

    setTimeout(() => {
      if (Object.keys(validate()).length == 0) {
        let budget_pacing_type_val;
        let budget_pacing_frequency_custom;
        let budget_pacing_frequency_spread_custom;
        if (DvCreateLineItemData?.budget_spacing_type != '') {
          budget_pacing_type_val = DvCreateLineItemData?.budget_spacing_type;
        } else if (
          DvCreateLineItemData?.budget_spacing_second_option_type ==
            'Automatically adjust budget' ||
          DvCreateLineItemData?.budget_spacing_second_option_type ==
            'custom_set_second_option'
        ) {
          budget_pacing_type_val =
            DvCreateLineItemData?.budget_spacing_second_option_type;
        } else {
          budget_pacing_type_val = '';
        }

        // Check Based on the query params and Edit case
        if (
          getQueryParam?.optimization?.[0] ==
            'Automate bid and budget at insertion order level' ||
          getOptimizationTypeStatus ==
            'Automate bid and budget at insertion order level'
        ) {
          budget_pacing_frequency_custom = 'Flight';
          budget_pacing_frequency_spread_custom = 'ASAP';
        } else {
          budget_pacing_frequency_custom =
            DvCreateLineItemData?.budget_spacing_first_type;
          budget_pacing_frequency_spread_custom =
            DvCreateLineItemData?.budget_spacing_second_type;
        }
        // else if(params['id']!= undefined){
        //   if (
        //     optimizationTypeFromAPI ==
        //     'Automate bid and budget at insertion order level'
        //   ) {
        //     budget_pacing_frequency_custom = 'Flight';
        //     budget_pacing_frequency_spread_custom = 'ASAP';
        //   } else {
        //     budget_pacing_frequency_custom =
        //       DvCreateLineItemData?.budget_spacing_first_type;
        //     budget_pacing_frequency_spread_custom =
        //       DvCreateLineItemData?.budget_spacing_second_type;
        //   }
        // }

        setDisplayLoader(true);
        if (isUpdateTrue) {
          const payload = {
            line_item_id: DvCreateLineItemData?.line_item_id,
            insertion_order_id: DvCreateLineItemData?.insertion_order_id,
            lineItem_type:
              DvCreateLineItemData?.selected_line_item || 'Display',
            line_item_name: DvCreateLineItemData?.line_item_name,
            flightdate_type:
              DvCreateLineItemData?.dv_flight_details?.flight_selected_type,
            custom_startdate: DvCreateLineItemData?.flight_start_date,
            custom_enddate: DvCreateLineItemData?.flight_end_date,
            quality: DvCreateLineItemData?.quality,
            frequency_cap_type: DvCreateLineItemData?.frequencey_type,
            frequency_limit:
              DvCreateLineItemData?.limit_frequencey_value == ''
                ? 0
                : DvCreateLineItemData?.limit_frequencey_value,
            frequency_limit_type:
              DvCreateLineItemData?.limit_frequencey_exposures_type,
            frequency_cap_limit_value:
              DvCreateLineItemData?.limit_frequencey_exposures_value == ''
                ? 0
                : DvCreateLineItemData?.limit_frequencey_exposures_value,
            bid_strategy: DvCreateLineItemData?.automated_bidding_type,
            automated_bid_type:
              DvCreateLineItemData?.automated_bidding_option_one_value,
            automated_bid_value:
              DvCreateLineItemData?.automated_bidding_option_two_value,
            is_exceed_cpm: DvCreateLineItemData?.automated_bidding_exceed_avg,
            cpM_value: DvCreateLineItemData?.automated_bidding_exceed_amount,
            priortize_deals:
              DvCreateLineItemData?.automated_bidding_prioritize_deal,
            automate_bidding_prioritize_target_cpm:
              DvCreateLineItemData?.automate_bidding_prioritize_target_cpm,
            fixed_bid_value: DvCreateLineItemData?.fixed_bid_val,
            budget_pacing_type: budget_pacing_type_val,
            creative_optimization: DvCreateLineItemData?.creative_optimization,
            budget_pacing_frequency: budget_pacing_frequency_custom,
            budget_pacing_frequency_spread:
              budget_pacing_frequency_spread_custom,
            budget_pacing_frequency_value:
              DvCreateLineItemData?.budget_spacing_daily_price,
            budget_pacing_fixed_budget_value:
              DvCreateLineItemData?.budget_spacing_type ==
              "Unlimited up to the insertion order's budget"
                ? 0
                : DvCreateLineItemData?.budget_spacing_amount,
            invsource_quality: DvCreateLineItemData?.quality,
            invsource_publicinventoryid: DvCreateLineItemData?.public_inventory,
            invsource_dealspackagesid:
              DvCreateLineItemData?.deals_and_inventory_packages,
            invsource_groupid: DvCreateLineItemData?.groups,
            optimized_target: DvCreateLineItemData?.optimization_type,
            // moderation_status: DvCreateLineItemData?.moderation_status,
            moderation_status: EnabledApproval
              ? 'draft'
              : DvCreateLineItemData?.moderation_status,
            rejection_reason: '',
            is_enabled: 1,
            created_by: getUserInfo?.Userid,
            status: DvCreateLineItemData?.status,
            created_at: moment().toDate(),
            creatives: [...mappedCreatives, ...mappedUpdatedCreatives],
          };
          handleUpdatePost(payload);
        } else {
          const payload = {
            line_item_id: 0,
            insertion_order_id: getInsertionOrderId,
            lineItem_type:
              DvCreateLineItemData?.selected_line_item || 'Display',
            line_item_name: DvCreateLineItemData?.line_item_name,
            flightdate_type:
              DvCreateLineItemData?.dv_flight_details?.flight_selected_type,
            custom_startdate: DvCreateLineItemData?.flight_start_date,
            custom_enddate: DvCreateLineItemData?.flight_end_date,
            quality: DvCreateLineItemData?.quality,
            frequency_cap_type: DvCreateLineItemData?.frequencey_type,
            frequency_limit:
              DvCreateLineItemData?.limit_frequencey_value == ''
                ? 0
                : DvCreateLineItemData?.limit_frequencey_value,
            frequency_limit_type:
              DvCreateLineItemData?.limit_frequencey_exposures_type,
            frequency_cap_limit_value:
              DvCreateLineItemData?.limit_frequencey_exposures_value == ''
                ? 0
                : DvCreateLineItemData?.limit_frequencey_exposures_value,
            bid_strategy: DvCreateLineItemData?.automated_bidding_type,
            automated_bid_type:
              DvCreateLineItemData?.automated_bidding_option_one_value,
            automated_bid_value:
              DvCreateLineItemData?.automated_bidding_option_two_value,
            is_exceed_cpm: DvCreateLineItemData?.automated_bidding_exceed_avg,
            cpM_value: DvCreateLineItemData?.automated_bidding_exceed_amount,
            priortize_deals:
              DvCreateLineItemData?.automated_bidding_prioritize_deal,
            automate_bidding_prioritize_target_cpm:
              DvCreateLineItemData?.automate_bidding_prioritize_target_cpm,
            fixed_bid_value: DvCreateLineItemData?.fixed_bid_val,
            budget_pacing_type: budget_pacing_type_val,
            creative_optimization: DvCreateLineItemData?.creative_optimization,
            budget_pacing_frequency: budget_pacing_frequency_custom,
            budget_pacing_frequency_spread:
              budget_pacing_frequency_spread_custom,
            budget_pacing_frequency_value:
              DvCreateLineItemData?.budget_spacing_daily_price,
            budget_pacing_fixed_budget_value:
              DvCreateLineItemData?.budget_spacing_type ==
              "Unlimited up to the insertion order's budget"
                ? 0
                : DvCreateLineItemData?.budget_spacing_amount,
            invsource_quality: DvCreateLineItemData?.quality,
            invsource_publicinventoryid: DvCreateLineItemData?.public_inventory,
            invsource_dealspackagesid:
              DvCreateLineItemData?.deals_and_inventory_packages,
            invsource_groupid: DvCreateLineItemData?.groups,
            optimized_target: DvCreateLineItemData?.optimization_type,
            moderation_status: EnabledApproval ? 'draft' : 'in_moderation',
            rejection_reason: '',
            is_enabled: 1,
            created_by: getUserInfo?.Userid,
            status: 1,
            created_at: moment().toDate(),
            creatives: mappedCreatives,
          };
          // console.log('payload', JSON.stringify(payload));
          handleCreatePost(payload);
          // const headers = {
          //   accessToken: 'Test',
          // };
          // axios
          //   .post(`${baseUrlStg}/api/LineItem/createLineItem`, payload, {
          //     headers: headers,
          //   })
          //   .then((response) => {
          //     if (
          //       response.data.status == 200 &&
          //       getQueryParam?.flag == 'multiple'
          //     ) {
          //       setDisplayLoader(false);
          //       navigate(
          //         `/dv-360/create-line-item/${response?.data?.lineItemId}`,
          //       );
          //     } else {
          //       setDisplayLoader(false);
          //       handleShow(true);
          //     }
          //   })
          //   .catch((error) => {
          //     console.log('errr', error);
          //   });
        }
      }
    }, 100);
  };

  const validate = (formData) => {
    const errors = {};
    //Get Values
    let frequencey_type = DvCreateLineItemData?.frequencey_type;
    // let budget_spacing_type =
    //   DvCreateLineItemData?.budget_spacing_second_option_type ==
    //     "Automatically adjust budget" || "custom_set_second_option"
    //     ? DvCreateLineItemData?.budget_spacing_second_option_type
    //     : DvCreateLineItemData?.budget_spacing_type;
    // let budget_spacing_type = DvCreateLineItemData?.budget_spacing_type;
    let lineItemName = DvCreateLineItemData?.line_item_name;
    let fixed_bid_val = DvCreateLineItemData?.fixed_bid_val;
    let quality = DvCreateLineItemData?.quality;
    let public_inventory = DvCreateLineItemData?.public_inventory;
    let deals_and_inventory_packages =
      DvCreateLineItemData?.deals_and_inventory_packages;
    let groups = DvCreateLineItemData?.groups;
    let automated_bidding_type = DvCreateLineItemData?.automated_bidding_type;
    let budget_spacing_first_type =
      DvCreateLineItemData?.budget_spacing_first_type;
    let budget_spacing_second_type =
      DvCreateLineItemData?.budget_spacing_second_type;
    let creative_optimization_type =
      DvCreateLineItemData?.creative_optimization;
    let budget_spacing_amount = DvCreateLineItemData?.budget_spacing_amount;
    // Conditions
    // code

    // code
    if (
      getQueryParam?.optimization?.[0] !=
        'Automate bid and budget at insertion order level' ||
      getOptimizationTypeStatus !=
        'Automate bid and budget at insertion order level'
    ) {
      // if (budget_spacing_first_type == '' || budget_spacing_second_type == '') {
      //   errors.budget_spacing_drop_down_check = 'Select one option';
      // }
      console.log(
        'fixed bid value -- numberCheckFixedBid: ',
        DvCreateLineItemData?.numberCheckFixedBid,
      );
      console.log(
        'fixed bid value -- automated_bidding_type: ',
        automated_bidding_type,
      );
      if (automated_bidding_type?.trim() == 'Fixed bid') {
        console.log('inside ');
        if (!fixed_bid_val) errors.numberCheckFixedBid = 'Enter a value';
      }
      if (
        automated_bidding_type == 'automated_bidding' &&
        DvCreateLineItemData?.automated_bidding_option_one_value == ''
      ) {
        errors.automated_bidding_option_one_value = 'Select a value';
      }
      if (
        automated_bidding_type == 'automated_bidding' &&
        DvCreateLineItemData?.automated_bidding_option_two_value == ''
      ) {
        errors.automated_bidding_option_two_value = 'Select a value';
      }
      // if (DvCreateLineItemData?.automated_bidding_type == '') {
      //   errors.bid_strategy_error = 'Please Choose atleast one';
      // }
      // if (budget_spacing_amount == '') {
      //   errors.numberCheckPacingAmount = 'Enter an amount';
      // }
    }

    //code

    // code

    if (frequencey_type == '') {
      errors.frequenceyTypeError = 'Please choose atleast one';
    }
    if (quality == '') {
      errors.qualityErr = 'Please select a value';
    }
    if (public_inventory == '') {
      errors.public_inventory = 'Please select a value';
    }
    if (deals_and_inventory_packages == '') {
      errors.deals_and_inventory_packages = lengthFieldCheck(
        deals_and_inventory_packages,
      );
    }
    if (groups == '') {
      errors.groups = lengthFieldCheck(groups);
    }

    if (lineItemName?.trim() == '') {
      errors.lineItemName = 'Line item name required';
    }

    if (creative_optimization_type == '') {
      errors.creative_optimization_type = 'Select one option';
    }
    if (images?.length > 0) {
      for (let i = 0; i < images?.length; i++) {
        if (!images[i]?.landingurlId) {
          errors.landingurl = 'Landing page URL required for all images.';
          break;
        }
      }
    }

    if (DvCreateLineItemData?.dv_flight_details?.flight_selected_type == '') {
      errors.flight_dates_error = 'Please choose atleast one';
    }

    if (
      (DvCreateLineItemData?.budget_spacing_type == 'custom_set' &&
        DvCreateLineItemData?.budget_spacing_amount == '0') ||
      DvCreateLineItemData?.budget_spacing_amount == 0
    ) {
      setNumberCheckDailySpacing('The value should be greater than 0');
    }
    if (
      DvCreateLineItemData?.pacing_first_type == 'Daily' &&
      DvCreateLineItemData?.budget_spacing_daily_price == 0
    ) {
      setNumberCheckDailySpacing('The value should be greater than 0');
    }

    if (
      DvCreateLineItemData?.budget_spacing_daily_price == '' &&
      DvCreateLineItemData?.budget_spacing_first_type == 'Daily'
    ) {
      errors.dailySpacingValidation = 'Enter a value';
    }

    if (
      DvCreateLineItemData?.automated_bidding_exceed_avg == true &&
      DvCreateLineItemData?.automated_bidding_exceed_amount == 0
    ) {
      setAutomatedBiddingExceedAmountError(
        'The value should be greater than 0',
      );
    }

    if (images.length == 0) {
      errors.assignCreatives = 'Add the creative';
    }
    console.log('errors', errors);
    return errors;
  };

  const getTaxonomyListsById = async (getCid) => {
    if (typeof getCid === 'undefined' || getCid === null) return;
    try {
      const response = await getApi(
        `${baseUrlStg}/api/Taxonomy/GetCidListByAddIdOrCampaignId?campaignId=${getCid}`,
      );
      let data = response?.map((item) => {
        return { label: item?.landingUrl, value: item?.cidId };
      });
      setLandingUrl(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setInsertionOrderId(query.get('insertion_order_id'));
    setQueryParam({
      ...getQueryParam,
      optimization: query.get('optimization')?.split(','),
      flag: query.get('flag'),
    });

    // Get Lists of the CIDs from the campaign id.
    let getCid =
      getTaxonomyCampaignId != '' ? getTaxonomyCampaignId : query.get('cID');
    if (getCid) {
      getTaxonomyListsById(getCid);
      // axios
      //   .get(
      //     `${baseUrlStg}/api/Taxonomy/GetCidListByAddIdOrCampaignId?campaignId=${getCid}`,
      //     {
      //       headers: {
      //         accept: 'text/plain',
      //       },
      //     },
      //   )
      //   .then((response) => {
      //     let data = response.data?.map((item) => {
      //       return { label: item?.landingUrl, value: item?.cidId };
      //     });
      //     setLandingUrl(data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
  }, [getTaxonomyCampaignId]);

  const handlegetAllInventorySources = async () => {
    try {
      let defualtPublicInventoryValues = '';
      const endPoint = `${baseUrlStg}/api/InventorySource/getAllInventorySources`;
      const response = await getApi(endPoint, { accessToken: '1234' });
      if (response?.status == 200) {
        // Filter Public Inventory
        const getPublicInventoryLists = response?.lstInventorySource?.filter(
          (v) => v.inventory_source_type == 'Public Inventory',
        );

        // Filter Deals and Inventory package
        const getDealsAndInventorypackangesLists =
          response?.lstInventorySource?.filter(
            (v) => v.inventory_source_type == 'Deals and Inventory Packages',
          );

        // Filter Groups
        const getGroupsLists = response?.lstInventorySource?.filter(
          (v) => v.inventory_source_type == 'Groups',
        );

        let getPublicInventoryListsData = getPublicInventoryLists?.map(
          (item) => {
            return {
              label: item?.inventory_source_value,
              value: item?.inventory_source_id,
            };
          },
        );

        let getDealsAndInventorypackangesListsData =
          getDealsAndInventorypackangesLists?.map((item) => {
            return {
              label: item?.inventory_source_value,
              value: item?.inventory_source_id,
            };
          });

        let getGroupsListsData = getGroupsLists?.map((item) => {
          return {
            label: item?.inventory_source_value,
            value: item?.inventory_source_id,
          };
        });

        setPublicInvetoryValues(getPublicInventoryListsData);
        setDealsAndInventoryPackagesValues(
          getDealsAndInventorypackangesListsData,
        );
        setGroupsValues(getGroupsListsData);

        // Set Default values to Public Inventory component.
        getPublicInventoryListsData.map(
          (v) => (defualtPublicInventoryValues += `${v?.value},`),
        );
        if (query.get('campaign_id')) {
          dispatch(
            setPublicInvetory(
              defualtPublicInventoryValues.replace(/,\s*$/, ''),
            ),
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handlegetAllInventorySources();
  }, []);

  //save as draft starts
  const handleSaveAsDraft = async () => {
    setFormErrors(validateOnSaveDraft());

    setTimeout(async () => {
      if (Object.keys(validateOnSaveDraft()).length == 0) {
        try {
          let budget_pacing_type_val;
          let budget_pacing_frequency_custom;
          let budget_pacing_frequency_spread_custom;
          if (DvCreateLineItemData?.budget_spacing_type != '') {
            budget_pacing_type_val = DvCreateLineItemData?.budget_spacing_type;
          } else if (
            DvCreateLineItemData?.budget_spacing_second_option_type ==
              'Automatically adjust budget' ||
            DvCreateLineItemData?.budget_spacing_second_option_type ==
              'custom_set_second_option'
          ) {
            budget_pacing_type_val =
              DvCreateLineItemData?.budget_spacing_second_option_type;
          } else {
            budget_pacing_type_val = '';
          }

          // Check Based on the query params and Edit case
          if (
            getQueryParam?.optimization?.[0] ==
              'Automate bid and budget at insertion order level' ||
            getOptimizationTypeStatus ==
              'Automate bid and budget at insertion order level'
          ) {
            budget_pacing_frequency_custom = 'Flight';
            budget_pacing_frequency_spread_custom = 'ASAP';
          } else {
            budget_pacing_frequency_custom =
              DvCreateLineItemData?.budget_spacing_first_type;
            budget_pacing_frequency_spread_custom =
              DvCreateLineItemData?.budget_spacing_second_type;
          }

          setDisplayLoader(true);
          const payload = {
            line_item_id: params['id'] || 0,
            insertion_order_id: isUpdateTrue
              ? DvCreateLineItemData?.insertion_order_id
              : getInsertionOrderId,
            lineItem_type:
              DvCreateLineItemData?.selected_line_item || 'Display',
            line_item_name: DvCreateLineItemData?.line_item_name,
            flightdate_type:
              DvCreateLineItemData?.dv_flight_details?.flight_selected_type,
            custom_startdate: DvCreateLineItemData?.flight_start_date,
            custom_enddate: DvCreateLineItemData?.flight_end_date,
            quality: DvCreateLineItemData?.quality,
            frequency_cap_type: DvCreateLineItemData?.frequencey_type,
            frequency_limit:
              DvCreateLineItemData?.limit_frequencey_value == ''
                ? 0
                : DvCreateLineItemData?.limit_frequencey_value,
            frequency_limit_type:
              DvCreateLineItemData?.limit_frequencey_exposures_type,
            frequency_cap_limit_value:
              DvCreateLineItemData?.limit_frequencey_exposures_value == ''
                ? 0
                : DvCreateLineItemData?.limit_frequencey_exposures_value,
            bid_strategy: DvCreateLineItemData?.automated_bidding_type,
            automated_bid_type:
              DvCreateLineItemData?.automated_bidding_option_one_value,
            automated_bid_value:
              DvCreateLineItemData?.automated_bidding_option_two_value,
            cpM_value: DvCreateLineItemData?.automated_bidding_exceed_amount,
            priortize_deals:
              DvCreateLineItemData?.automated_bidding_prioritize_deal,
            automate_bidding_prioritize_target_cpm:
              DvCreateLineItemData?.automate_bidding_prioritize_target_cpm,
            fixed_bid_value: DvCreateLineItemData?.fixed_bid_val,
            budget_pacing_type: budget_pacing_type_val,
            creative_optimization: DvCreateLineItemData?.creative_optimization,
            budget_pacing_frequency: budget_pacing_frequency_custom,
            budget_pacing_frequency_spread:
              budget_pacing_frequency_spread_custom,
            budget_pacing_frequency_value:
              DvCreateLineItemData?.budget_spacing_daily_price,
            budget_pacing_fixed_budget_value:
              DvCreateLineItemData?.budget_spacing_type ==
              "Unlimited up to the insertion order's budget"
                ? 0
                : DvCreateLineItemData?.budget_spacing_amount,
            invsource_quality: DvCreateLineItemData?.quality,
            invsource_publicinventoryid: DvCreateLineItemData?.public_inventory,
            invsource_dealspackagesid:
              DvCreateLineItemData?.deals_and_inventory_packages,
            invsource_groupid: DvCreateLineItemData?.groups,
            optimized_target: DvCreateLineItemData?.optimization_type,
            moderation_status: EnabledApproval ? 'draft' : 'in_moderation',
            rejection_reason: '',
            is_enabled: 0,
            created_by: getUserInfo?.Userid,
            status: 1,
            created_at: moment().toDate(),
            creatives: [...mappedCreatives, ...mappedUpdatedCreatives],
          };

          let response = await postApi(
            `${baseUrlStg}/api/LineItem/saveLineItemDraft`,
            payload,
            { accessToken: '2323' },
          );
          setDisplayLoader(false);
          if (response?.data?.status == 200) {
            // console.log("res",response)
            setShowModal(true);
            setUpdateTreeOnsaveDraft((prev) => !prev);
            navigate(`/dv-360/create-line-item/${response?.data?.lineItemId}`, {
              state: { isCreateFlow: dataFromRoute?.isCreateFlow },
            });
            // alert('success');lineItemId
          }
        } catch (err) {
          console.log('errr', err);
        }
      }
    }, 20);
  };

  const validateOnSaveDraft = () => {
    let errors = {};
    // if (!(DvInsertionOrderData?.campaign_id || getQueryParam?.campaign_id)) {
    //   errors.campaignId = 'Campaign Id required';
    // }
    if (!DvCreateLineItemData?.selected_line_item) {
      errors.selectedLineItemType = 'LineItemType type required';
    }
    if (!DvCreateLineItemData?.line_item_name?.trim()) {
      errors.lineItemName = 'line item name required';
    }
    //
    if (DvCreateLineItemData?.fixed_bid_val?.trim()) {
      errors.flexRadioDefault511 = 'Enter a value';
    }

    // if (!DvInsertionOrderData?.taxonomy_campaign_id) {
    //   errors.insertionOrderName = 'insertion order name required';
    // }

    // console.log('err', errors, DvInsertionOrderData?.campaign_id);
    return errors;
  };
  //save as draft ends

  // MediaWidget logic
  const [showMediaWidget, setShowMediaWidget] = useState(false);
  const handleCloseMediaWidget = (newImages) => {
    if (newImages && newImages.length > 0) {
      setImages(() => [...newImages]);
    }
    setShowMediaWidget(false);
  };
  const openMediaWidget = () => {
    setShowMediaWidget(true);
  };

  const handleRedirectionToNextPage = (cID) => {
    navigate(
      `/dv-360/create-insertion-order/new?campaign_id=${cID}&flag=multiple`,
    );
  };

  const handleRedirectionToNextPageOfLineItem = (insertionOrder) => {
    const returnUrl = lineItemUrlFormat(insertionOrder);
    navigate(returnUrl);
    window.location.reload(true);
  };

  useEffect(() => {
    setInsertionOrderId(query.get('insertion_order_id'));
    handleSideTreeDataAndUpdate();
  }, [query.get('insertion_order_id')]);

  useEffect(() => {
    if (typeof params['id'] == 'undefined') {
      dispatch(resetValues());
    }
  }, [location]);

  const handleApprovalPublisher = () => {
    if (getPublisherInfo?.length == 0) {
      setPublisherError('Please Choose atleast one option');
    } else {
      setApprovalCoinfirmationPopupShowStatus(true);
      setApprovalPublisherModelShowStatus(false);
      setPublisherError('');
    }
  };

  const handleApprovalPublisherRequest = async () => {
    try {
      let campId = isUpdateTrue ? getCampaignId : query.get('campaign_id');
      let payload = {
        platform: 'DV360',
        userId: getUserInfo?.Userid,
        publisherId: getPublisherInfo?.value,
        campaignId: campId,
        link: `${window.location.origin}/dv-360/campaignsView/${campId}`,
      };

      setDisplayLoader(true);
      setApprovalCoinfirmationPopupShowStatus(false);

      let response = await postApi(
        `${baseUrlStg}/api/CampaignApproval/SendToPublisherApproval`,
        payload,
      );

      if (response?.status) {
        if (isPublisher) {
          setDisplayLoader(true);
          setApprovalCoinfirmationPopupShowStatus(false);
          let campaignId = isUpdateTrue
            ? getCampaignId
            : query.get('campaign_id');
          handleAPICall('DV360', campaignId, getPublisherInfo?.value).then(
            (res) => {
              if (res == 200) {
                setThankYouApprovalPopupShowStatus(true);
              }
            },
          );
        } else {
          setThankYouApprovalPopupShowStatus(true);
          setDisplayLoader(false);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleSetPublisherId = (e) => {
    if (e == null) {
      setPublisherInfo([]);
      setIsPublisher(false);
    } else {
      setPublisherInfo(e);
      e?.value == getUserInfo?.Userid && setIsPublisher(true);
    }
  };

  const handleCloseModel = () => {
    if (getCreateStatus) {
      navigate(`/dv-360/create-line-item/${getCreateId}`);
    }
    setApprovalPublisherModelShowStatus(false);
    setApprovalCoinfirmationPopupShowStatus(false);
    setShowModal(false);
    setDisplayLoader(false);
    setPublisherInfo([]);
    setIsPublisher(false);
  };

  const redirectToDashboard = () => {
    navigate('/dv-360/campaigns-listing');
  };

  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box padding-bottom-extra media-section-left'>
            {/* header starts here */}
            <Header
              saveHandler={handleSaveAsDraft}
              redirectToDashboard={redirectToDashboard}
            />
            {/* header ends here */}
            <section className='side-menu-form d-flex'>
              {/* sidetreemenu starts */}
              {dataFromRoute?.isCreateFlow ? (
                <SideTreeMenuForCreate
                  campaignTreeData={campaignTreeData}
                  isCreateMode={true}
                  IdAndType={{
                    id: params['id'],
                    type: 'lineItem',
                  }}
                  showTreeMenu={showTreeMenu}
                  pageType={'lineItem'}
                  AtdStatus={campaignTreeData?.moderation_status}
                  Dv360Status={campaignTreeData?.moderation_status || 'NA'}
                />
              ) : (
                <SideTreeMenuForCreate
                  campaignTreeData={campaignTreeData}
                  isCreateMode={false}
                  IdAndType={{
                    id: params['id'],
                    type: 'lineItem',
                  }}
                  handleRedirectionToNextPage={handleRedirectionToNextPage}
                  handleRedirectionToNextPageOfLineItem={
                    handleRedirectionToNextPageOfLineItem
                  }
                  flagStatus={getQueryParam?.flag}
                  getInsertionOrderId={getInsertionOrderId}
                  showTreeMenu={showTreeMenu}
                  pageType={'lineItem'}
                  AtdStatus={campaignTreeData?.moderation_status}
                  Dv360Status={campaignTreeData?.moderation_status || 'NA'}
                />
              )}
              {/* sidetree menu ends */}
              <article className='side-right-form-wrap side-right-pad view-edit-tab-remove'>
                {/* <FormHeader /> */}
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  {/* Error display div start */}
                  {isUpdate && <DvErrorDisplay id={lineCampaignID} />}
                  {/* Error display div end */}
                  <div
                    className='tab-pane fade show active'
                    id='edit'
                    role='tabpanel'
                    aria-labelledby='edit-tab'
                  >
                    <div className='side-inner-form'>
                      <article className='side-right-from'>
                        {/* create compaign */}

                        <Card>
                          <CardHeader>
                            <h5 className='d-flex align-items-center'>
                              Line Item Type
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont pt-3 pb-3'>
                                  <div
                                    className='action-btn-both row'
                                    onChange={(e) => {
                                      onChangeHandler(e);
                                    }}
                                  >
                                    {' '}
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value='Display'
                                        id='flexRadioDefault1'
                                        onClick={() =>
                                          handleLineItemSelect('Display')
                                        }
                                        checked={
                                          DvCreateLineItemData?.selected_line_item ==
                                            'Display' ||
                                          (getQueryParam?.flag == 'multiple' &&
                                            true)
                                        }
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault1'
                                      >
                                        <i className='icon-display'></i>
                                        Display
                                        {/* <i className="icon-info"></i> */}
                                      </label>
                                    </div>
                                    {/* code */}
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='campaignGoal'
                                        value='video'
                                        id='flexRadioDefault2'
                                        onClick={() =>
                                          handleLineItemSelect('video')
                                        }
                                        checked={
                                          DvCreateLineItemData?.selected_line_item ==
                                            'video' && true
                                        }
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault2'
                                      >
                                        <i className='icon-video-one'></i>
                                        Video
                                      </label>
                                    </div>
                                    <div className='form-check-box col-12'>
                                      {getQueryParam?.optimization?.[0] ==
                                        'Control bid and budget at the line item level' &&
                                      getQueryParam?.optimization.length ==
                                        1 ? (
                                        <>
                                          <AudioComponent
                                            handleLineItemSelect={
                                              handleLineItemSelect
                                            }
                                            selected_line_item={
                                              DvCreateLineItemData?.selected_line_item
                                            }
                                          />
                                        </>
                                      ) : (
                                        getOptimizationTypeStatus ==
                                          'Control bid and budget at the line item level' &&
                                        getOptimizationActionTypeStatus !=
                                          'Automatically optimize your budget allocation' && (
                                          <AudioComponent
                                            handleLineItemSelect={
                                              handleLineItemSelect
                                            }
                                            selected_line_item={
                                              DvCreateLineItemData?.selected_line_item
                                            }
                                          />
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Line item name*</h5>
                            </div>
                            <div className='me-auto card-switch-rigth'>
                              <div className='form-panel select-with-side-label'>
                                <div className='form-item'></div>
                              </div>
                            </div>
                          </div>
                          <div className='card-body'>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item col-12'>
                                  <div className='label-icon'>
                                    <label>Line item name*</label>
                                  </div>
                                  <div className='input-group'>
                                    <input
                                      type='text'
                                      id='lineItemName'
                                      placeholder='Enter line item name'
                                      pattern='^[A-Za-z0-9\s]+$'
                                      value={
                                        DvCreateLineItemData?.line_item_name
                                      }
                                      onChange={handleLineItemNameChange}
                                    />
                                  </div>
                                  <span className='error'>
                                    {formErrors?.lineItemName}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>

                        <Card>
                          <CardHeader>
                            <h5>Inventory source</h5>
                          </CardHeader>
                          <AdvancedCardHeader>
                            <div className='card-lt-pane'>
                              <p>
                                New insertion orders and line items in this
                                campaign will inherit these settings.
                              </p>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div className='card-lt-pane'>
                              <h6>Quality</h6>
                              <SimpleSelectBox
                                name='quality'
                                placeholder='Select...'
                                options={[
                                  'Authorized Direct Sellers',
                                  'Authorized Direct Sellers And Resellers',
                                  'Authorized and Non-Participating Publishers',
                                ]}
                                onChangeHandler={handleQualityVal}
                                value={DvCreateLineItemData?.quality}
                              ></SimpleSelectBox>
                              <span className='error'>
                                {formErrors?.qualityErr}
                              </span>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div className='card-lt-pane'>
                              <h6>Public Inventory</h6>
                              <ReactSelect
                                isMulti={true}
                                options={getPublicInventoryValues}
                                value={prefillSelect(
                                  DvCreateLineItemData?.public_inventory,
                                  getPublicInventoryValues,
                                )}
                                onChange={handlePublicInventory}
                              />
                              <span className='error'>
                                {formErrors?.public_inventory}
                              </span>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div className='card-lt-pane'>
                              <h6>Deals and inventory packages.</h6>
                              <ReactSelect
                                isMulti={true}
                                options={getDealsAndInventoryPackagesValues}
                                value={prefillSelect(
                                  DvCreateLineItemData?.deals_and_inventory_packages,
                                  getDealsAndInventoryPackagesValues,
                                )}
                                onChange={handleDealInventory}
                              />

                              <span className='error'>
                                {formErrors?.deals_and_inventory_packages}
                              </span>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div className='card-lt-pane'>
                              <h6>Groups</h6>
                              <ReactSelect
                                isMulti={true}
                                options={getGroupsValues}
                                value={prefillSelect(
                                  DvCreateLineItemData?.groups,
                                  getGroupsValues,
                                )}
                                onChange={handleGroups}
                              />
                              <span className='error'>
                                {formErrors?.groups}
                              </span>
                            </div>
                          </AdvancedCardHeader>
                        </Card>
                      </article>
                    </div>
                  </div>
                  {/*  Targetting Start */}
                  {/* <Card>
                    <CardHeader>
                      <h5>Targetting</h5>
                      <p>
                        New insertion orders and line items in this campaign
                        will inherit these settings.
                      </p>
                    </CardHeader>
                    <CardBody>
                      <ReactSelect placeholder='Select'></ReactSelect>
                    </CardBody>
                    <AdvancedCardHeader>
                      <div className='card-lt-pane'>
                        <h6>Demographics</h6>
                        <div className='dv-header-icon'>
                          <div className='dv-icon-outer d-flex'>
                            <div className='dv-data-icon'>
                              <div className='dv-inner-icon d-flex'>
                                <CustomReadOnlyInput
                                  value={
                                    DvCreateLineItemData?.dv_targetting
                                      ?.demographics
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AdvancedCardHeader>
                    <AdvancedCardHeader>
                      <div className='card-lt-pane'>
                        <h6>Geography</h6>
                        <div className='dv-header-icon'>
                          <div className='dv-icon-outer d-flex'>
                            <div className='dv-icon'>
                              <i className='icon-check-mark' />
                            </div>
                            <div className='dv-data-icon'>
                              <p>Target the following regions</p>
                              <div className='dv-inner-icon d-flex'>
                                <CustomReadOnlyInput
                                  value={
                                    DvCreateLineItemData?.dv_targetting
                                      ?.geography?.region_inclusions
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='dv-header-icon'>
                          <div className='dv-icon-outer d-flex'>
                            <div className='dv-icon'>
                              <i className='icon-check-mark' />
                            </div>
                            <div className='dv-data-icon'>
                              <p>Target the following regions</p>
                              <div className='dv-inner-icon d-flex'>
                                <CustomReadOnlyInput
                                  value={
                                    DvCreateLineItemData?.dv_targetting
                                      ?.geography?.region_exclusions
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AdvancedCardHeader>
                    <AdvancedCardHeader>
                      <div className='card-lt-pane'>
                        <h6>Language</h6>
                        <div className='dv-header-icon'>
                          <div className='dv-icon-outer d-flex'>
                            <div className='dv-data-icon'>
                              <div className='dv-inner-icon d-flex'>
                                <p className='dv-text-g'>All languages </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AdvancedCardHeader>
                    <AdvancedCardHeader>
                      <div className='card-lt-pane'>
                        <h6>Brand safety</h6>
                        <div className='dv-header-icon'>
                          <div className='dv-icon-outer d-flex'>
                            <div className='dv-data-icon'>
                              <div className='dv-inner-icon d-flex'>
                                <CustomReadOnlyInput
                                  value={
                                    DvCreateLineItemData?.dv_targetting
                                      ?.brand_safety
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AdvancedCardHeader>
                  </Card> */}
                  {/*  Targetting End */}
                  <Card>
                    <div className='card-header-top'>
                      <div className='card-lt-pane'>
                        <h5>Optimized targeting</h5>

                        <div className='action-btn-both action-btn-both-check row'>
                          <div className='form-check-box'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='inlineCheckbox1'
                              defaultValue='option1'
                              onChange={(e) => handleOptimizedTargeting(e)}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='inlineCheckbox1'
                            >
                              Use optimized targeting
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='info-icon-body'>
                        <div className='more-info-icon'>
                          <i className='icon-bulb' />
                        </div>
                        <div className='more-info-text'>
                          <p>
                            Optimized targeting may help you reach your
                            campaign's goals by finding new people beyond your
                            selected audience list.
                          </p>
                          <p>
                            For this line item to be eligible to use optimized
                            targeting, use audience list targeting and turn on
                            automated bidding.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card>
                    <div className='card-header-top d-flex'>
                      <div className='card-lt-pane'>
                        <h5>Flight dates*</h5>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='card-form form-panel'>
                        <div className='row align-items-center'>
                          <div className='action-btn-cont'>
                            <div className='action-btn-both action-btn-no-icon row'>
                              <div className='col-12 px-0'>
                                <div className='form-check-box'>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    name='flight_selected_type'
                                    id='flexRadioDefault50'
                                    defaultValue='Use same dates as insertion order'
                                    onChange={(e) => handleFlightDates(e)}
                                    checked={
                                      DvCreateLineItemData?.dv_flight_details
                                        ?.flight_selected_type ==
                                      'Use same dates as insertion order'
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor='flexRadioDefault50'
                                  >
                                    Use same dates as insertion order
                                  </label>
                                </div>
                              </div>
                              <div className='col-12 px-0'>
                                <div className='form-check-box'>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    name='flight_selected_type'
                                    id='flexRadioDefault51'
                                    defaultValue='Custom dates'
                                    onChange={(e) => handleFlightDates(e)}
                                    checked={
                                      DvCreateLineItemData?.dv_flight_details
                                        ?.flight_selected_type == 'Custom dates'
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor='flexRadioDefault51'
                                  >
                                    Custom dates
                                  </label>
                                </div>
                                {DvCreateLineItemData?.dv_flight_details
                                  ?.flight_selected_type == 'Custom dates' && (
                                  <div className='dv-from-check-field'>
                                    <div className='row align-items-center'>
                                      <div className='form-item input-group-label col-6'>
                                        <div className='label-icon'>
                                          <label>Start date*</label>
                                        </div>
                                        <div className='input-group'>
                                          <span className='input-group-text rs-text'>
                                            <i className='icon-calendar' />
                                          </span>
                                          <ReactDatePicker
                                            selected={
                                              DvCreateLineItemData?.flight_start_date &&
                                              new Date(
                                                DvCreateLineItemData?.flight_start_date,
                                              )
                                            }
                                            onChange={(e) =>
                                              startDateHandler(e)
                                            }
                                            dateFormat='dd/MM/yyyy'
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText='DD/MM/YYYY'
                                            name='flight_start_date'
                                            minDate={moment().toDate()}
                                          />
                                        </div>
                                      </div>
                                      <div className='form-item input-group-label col-6'>
                                        <div className='label-icon'>
                                          <label>End date*</label>
                                        </div>
                                        <div className='input-group time'>
                                          <span className='input-group-text rs-text'>
                                            <i className='icon-calendar' />
                                          </span>
                                          <ReactDatePicker
                                            selected={
                                              DvCreateLineItemData?.flight_end_date &&
                                              new Date(
                                                DvCreateLineItemData?.flight_end_date,
                                              )
                                            }
                                            onChange={(e) => endDateHandler(e)}
                                            dateFormat='dd/MM/yyyy'
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText='DD/MM/YYYY'
                                            name='flight_end_date'
                                            minDate={
                                              DvCreateLineItemData?.flight_end_date &&
                                              new Date(
                                                DvCreateLineItemData?.flight_end_date,
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <span
                                className='error-message'
                                style={{ color: 'red' }}
                              >
                                {formErrors?.flight_dates_error}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card>
                    <div className='card-header-top d-flex'>
                      <div className='card-lt-pane'>
                        <h5>Budget</h5>
                        <p>
                          Budget and pacing depend on both insertion order and
                          line item settings.
                        </p>
                      </div>
                    </div>

                    <div className='card-header-top'>
                      <div className='card-lt-pane'>
                        <h6>Budget & pacing *</h6>
                      </div>
                    </div>
                    {/* If 'Automate bid and budget at insertion order level' value selected at Insertion level then set default value start */}
                    {getQueryParam?.optimization?.[0] ==
                    'Automate bid and budget at insertion order level' ? (
                      <AutomateAndBudgetCase />
                    ) : (
                      getOptimizationTypeStatus ==
                        'Automate bid and budget at insertion order level' && (
                        <AutomateAndBudgetCase />
                      )
                    )}
                    {/* If 'Automate bid and budget at insertion order level' value selected at Insertion level then set default value end */}
                    {/* First options start */}
                    {getQueryParam?.optimization?.[0] ==
                      'Control bid and budget at the line item level' &&
                    getQueryParam?.optimization.length == 1 ? (
                      <ControlBidAndBudgetCase
                        handleBudgetPacingType={handleBudgetPacingType}
                        budget_spacing_type={
                          DvCreateLineItemData?.budget_spacing_type
                        }
                        handleBudgetPacingAmount={handleBudgetPacingAmount}
                        budget_spacing_amount={
                          DvCreateLineItemData?.budget_spacing_amount
                        }
                        // prashant chavan code
                        // numberCheckPacingAmount={numberCheckPacingAmount}

                        budget_spacing_type_error={
                          formErrors?.budget_spacing_type
                        }
                        commonOptions={commonOptions}
                        handleBudgetSpacingFirstType={
                          handleBudgetSpacingFirstType
                        }
                        budget_spacing_first_type={
                          DvCreateLineItemData?.budget_spacing_first_type
                        }
                        budget_spacing_second_type={
                          DvCreateLineItemData?.budget_spacing_second_type
                        }
                        handleBudgetSpacingSecondType={
                          handleBudgetSpacingSecondType
                        }
                        // code suraj
                        numberCheckPacingAmount={
                          formErrors.numberCheckPacingAmount
                        }
                        // code
                        budget_spacing_drop_down_check={
                          formErrors.budget_spacing_drop_down_check
                        }
                        budgetSpacingUnlimitedUpToFlightOptions={
                          budgetSpacingUnlimitedUpToFlightOptions
                        }
                        budgetSpacingUnlimitedUpToDailyOptions={
                          budgetSpacingUnlimitedUpToDailyOptions
                        }
                        budgetSpacingUnlimitedUpToFlightNumericOptions={
                          budgetSpacingUnlimitedUpToFlightNumericOptions
                        }
                        budgetSpacingUnlimitedUpToDailyNumericOptions={
                          budgetSpacingUnlimitedUpToDailyNumericOptions
                        }
                        handleDailyPrice={handleDailyPrice}
                        budget_spacing_daily_price={
                          DvCreateLineItemData?.budget_spacing_daily_price
                        }
                        numberCheckDailySpacing={numberCheckDailySpacing}
                        dailySpacingValidation={
                          formErrors?.dailySpacingValidation
                        }
                      />
                    ) : (
                      getOptimizationTypeStatus ==
                        'Control bid and budget at the line item level' &&
                      getOptimizationActionTypeStatus !=
                        'Automatically optimize your budget allocation' && (
                        <ControlBidAndBudgetCase
                          handleBudgetPacingType={handleBudgetPacingType}
                          budget_spacing_type={
                            DvCreateLineItemData?.budget_spacing_type
                          }
                          handleBudgetPacingAmount={handleBudgetPacingAmount}
                          budget_spacing_amount={
                            DvCreateLineItemData?.budget_spacing_amount
                          }
                          numberCheckPacingAmount={numberCheckPacingAmount}
                          budget_spacing_type_error={
                            formErrors?.budget_spacing_type
                          }
                          commonOptions={commonOptions}
                          handleBudgetSpacingFirstType={
                            handleBudgetSpacingFirstType
                          }
                          budget_spacing_first_type={
                            DvCreateLineItemData?.budget_spacing_first_type
                          }
                          budget_spacing_second_type={
                            DvCreateLineItemData?.budget_spacing_second_type
                          }
                          handleBudgetSpacingSecondType={
                            handleBudgetSpacingSecondType
                          }
                          budget_spacing_drop_down_check={
                            formErrors.budget_spacing_drop_down_check
                          }
                          budgetSpacingUnlimitedUpToFlightOptions={
                            budgetSpacingUnlimitedUpToFlightOptions
                          }
                          budgetSpacingUnlimitedUpToDailyOptions={
                            budgetSpacingUnlimitedUpToDailyOptions
                          }
                          budgetSpacingUnlimitedUpToFlightNumericOptions={
                            budgetSpacingUnlimitedUpToFlightNumericOptions
                          }
                          budgetSpacingUnlimitedUpToDailyNumericOptions={
                            budgetSpacingUnlimitedUpToDailyNumericOptions
                          }
                          handleDailyPrice={handleDailyPrice}
                          budget_spacing_daily_price={
                            DvCreateLineItemData?.budget_spacing_daily_price
                          }
                          numberCheckDailySpacing={numberCheckDailySpacing}
                          dailySpacingValidation={
                            formErrors?.dailySpacingValidation
                          }
                        />
                      )
                    )}

                    {/* First options end */}

                    {/* For the Second option if (Automatically optimize your budget allocation) selected at Insertion level */}
                    {/* Option 2 Start */}
                    {getQueryParam?.optimization?.[0] ==
                      'Control bid and budget at the line item level' &&
                    getQueryParam?.optimization?.[1] ==
                      'Automatically optimize your budget allocation' &&
                    getQueryParam?.optimization?.length == 2 ? (
                      <ControlBidAndBudgetAndAutomaticallyOptimize
                        handleSecondOptionBudgetType={
                          handleSecondOptionBudgetType
                        }
                        budget_spacing_type={
                          DvCreateLineItemData?.budget_spacing_type
                        }
                        budget_spacing_second_option_type={
                          DvCreateLineItemData?.budget_spacing_second_option_type
                        }
                        handleBudgetPacingType={handleBudgetPacingType}
                        handleBudgetPacingAmount={handleBudgetPacingAmount}
                        budget_spacing_amount={
                          DvCreateLineItemData?.budget_spacing_amount
                        }
                        numberCheckPacingAmount={numberCheckPacingAmount}
                        commonOptionWithDaily={commonOptionWithDaily}
                        commonOptions={commonOptions}
                        handleBudgetSpacingFirstType={
                          handleBudgetSpacingFirstType
                        }
                        budget_spacing_first_type={
                          DvCreateLineItemData?.budget_spacing_first_type
                        }
                        budget_spacing_drop_down_check={
                          formErrors.budget_spacing_drop_down_check
                        }
                        budget_spacing_second_type={
                          DvCreateLineItemData?.budget_spacing_second_type
                        }
                        handleBudgetSpacingSecondType={
                          handleBudgetSpacingSecondType
                        }
                        handleDailyPrice={handleDailyPrice}
                        numberCheckDailySpacing={numberCheckDailySpacing}
                        budget_spacing_daily_price={
                          DvCreateLineItemData?.budget_spacing_daily_price
                        }
                        budgetSpacingAutomatedAdjustFlightOptions={
                          budgetSpacingAutomatedAdjustFlightOptions
                        }
                        budgetSpacingAutomatedAdjustDailyOptions={
                          budgetSpacingAutomatedAdjustDailyOptions
                        }
                        budgetSpacingAutomatedAdjustFlightNumericOptions={
                          budgetSpacingAutomatedAdjustFlightNumericOptions
                        }
                        budgetSpacingAutomatedAdjustDailyNumericOptions={
                          budgetSpacingAutomatedAdjustDailyNumericOptions
                        }
                      />
                    ) : (
                      getOptimizationTypeStatus ==
                        'Control bid and budget at the line item level' &&
                      getOptimizationActionTypeStatus ==
                        'Automatically optimize your budget allocation' && (
                        <ControlBidAndBudgetAndAutomaticallyOptimize
                          handleSecondOptionBudgetType={
                            handleSecondOptionBudgetType
                          }
                          budget_spacing_type={
                            DvCreateLineItemData?.budget_spacing_type
                          }
                          budget_spacing_second_option_type={
                            DvCreateLineItemData?.budget_spacing_second_option_type
                          }
                          handleBudgetPacingType={handleBudgetPacingType}
                          handleBudgetPacingAmount={handleBudgetPacingAmount}
                          budget_spacing_amount={
                            DvCreateLineItemData?.budget_spacing_amount
                          }
                          numberCheckPacingAmount={numberCheckPacingAmount}
                          commonOptionWithDaily={commonOptionWithDaily}
                          commonOptions={commonOptions}
                          handleBudgetSpacingFirstType={
                            handleBudgetSpacingFirstType
                          }
                          budget_spacing_first_type={
                            DvCreateLineItemData?.budget_spacing_first_type
                          }
                          budget_spacing_drop_down_check={
                            formErrors.budget_spacing_drop_down_check
                          }
                          budget_spacing_second_type={
                            DvCreateLineItemData?.budget_spacing_second_type
                          }
                          handleBudgetSpacingSecondType={
                            handleBudgetSpacingSecondType
                          }
                          handleDailyPrice={handleDailyPrice}
                          numberCheckDailySpacing={numberCheckDailySpacing}
                          budget_spacing_daily_price={
                            DvCreateLineItemData?.budget_spacing_daily_price
                          }
                          budgetSpacingAutomatedAdjustFlightOptions={
                            budgetSpacingAutomatedAdjustFlightOptions
                          }
                          budgetSpacingAutomatedAdjustDailyOptions={
                            budgetSpacingAutomatedAdjustDailyOptions
                          }
                          budgetSpacingAutomatedAdjustFlightNumericOptions={
                            budgetSpacingAutomatedAdjustFlightNumericOptions
                          }
                          budgetSpacingAutomatedAdjustDailyNumericOptions={
                            budgetSpacingAutomatedAdjustDailyNumericOptions
                          }
                        />
                      )
                    )}

                    {/* Option 2 End */}
                    <div className='card-header-top'>
                      <div className='card-lt-pane'>
                        <h6>Bid strategy*</h6>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='card-form form-panel'>
                        <div className='row align-items-center'>
                          <div className='action-btn-cont'>
                            <div className='action-btn-both action-btn-no-icon row'>
                              <BidAndStrategy
                                optimizationQueryTypeFirstPosition={
                                  typeof params['id'] != 'undefined'
                                    ? getOptimizationTypeStatus
                                    : getQueryParam?.optimization?.[0]
                                }
                                handleToggleAutomateBidding={
                                  handleToggleAutomateBidding
                                }
                                automated_bidding_type={
                                  DvCreateLineItemData?.automated_bidding_type
                                }
                                selected_line_item={
                                  DvCreateLineItemData?.selected_line_item
                                }
                                automateBiddingOptiWithThreeValDisplay={
                                  automateBiddingOptiWithThreeValDisplay
                                }
                                automateBiddingOptiWithFiveValVideo={
                                  automateBiddingOptiWithFiveValVideo
                                }
                                automateBiddingOptiWithOneValAudio={
                                  automateBiddingOptiWithOneValAudio
                                }
                                automated_bidding_option_one_value={
                                  DvCreateLineItemData?.automated_bidding_option_one_value
                                }
                                hanldeAutomatedBiddingOptionsOne={
                                  hanldeAutomatedBiddingOptionsOne
                                }
                                automated_bidding_option_one_value_error={
                                  formErrors.automated_bidding_option_one_value
                                }
                                automateBiddingSecondOptiWithOneValAudio={
                                  automateBiddingSecondOptiWithOneValAudio
                                }
                                automateBiddingOptionsTwoCPC={
                                  automateBiddingOptionsTwoCPC
                                }
                                automateBiddingOptionsTwo={
                                  automateBiddingOptionsTwo
                                }
                                automated_bidding_option_two_value={
                                  DvCreateLineItemData?.automated_bidding_option_two_value
                                }
                                hanldeAutomatedBiddingOptionsTwo={
                                  hanldeAutomatedBiddingOptionsTwo
                                }
                                hanldeAutomatedBiddingPrioritizeTargetCPA={
                                  hanldeAutomatedBiddingPrioritizeTargetCPA
                                }
                                automate_bidding_prioritize_target_cpm={
                                  DvCreateLineItemData?.automate_bidding_prioritize_target_cpm
                                }
                                getAutomatedBiddingPrioritizeTargetCPAError={
                                  getAutomatedBiddingPrioritizeTargetCPAError
                                }
                                hanldeAutomatedBiddingExceedAvg={
                                  hanldeAutomatedBiddingExceedAvg
                                }
                                automated_bidding_exceed_avg={
                                  DvCreateLineItemData?.automated_bidding_exceed_avg
                                }
                                hanldeAutomatedBiddingExceedAmount={
                                  hanldeAutomatedBiddingExceedAmount
                                }
                                automated_bidding_exceed_amount={
                                  DvCreateLineItemData?.automated_bidding_exceed_amount
                                }
                                getAutomatedBiddingExceedAmountError={
                                  getAutomatedBiddingExceedAmountError
                                }
                                hanldeAutomatedBiddingPrioritizeDeal={
                                  hanldeAutomatedBiddingPrioritizeDeal
                                }
                                automated_bidding_prioritize_deal={
                                  DvCreateLineItemData?.automated_bidding_prioritize_deal
                                }
                                handleFixedBidVal={handleFixedBidVal}
                                fixed_bid_val={
                                  DvCreateLineItemData?.fixed_bid_val
                                }
                                numberCheckFixedBid={numberCheckFixedBid}
                                bid_strategy_error={
                                  formErrors?.bid_strategy_error
                                }
                                automated_bidding_option_two_value_error={
                                  formErrors.automated_bidding_option_two_value
                                }
                                numberCheckFixedBidError={
                                  formErrors.numberCheckFixedBid
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='card-header-top'>
                        <div className='card-lt-pane'>
                          <h6 className='d-flex align-items-center'>
                            Frequency cap*
                          </h6>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='card-form form-panel card-form-days dv-card-form-days'>
                          <div className='row align-items-center'>
                            <div className='action-btn-cont'>
                              <div className='action-btn-both action-btn-no-icon row'>
                                <div className='col-12 d-flex px-0'>
                                  <div className='form-check-box'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='no limit'
                                      id='flexRadioDefault232'
                                      defaultValue='no limit'
                                      onChange={(e) => handleLimitFrequencey(e)}
                                      checked={
                                        DvCreateLineItemData?.frequencey_type ==
                                        'no limit'
                                          ? true
                                          : false
                                      }
                                    />
                                    <label
                                      className='form-check-label'
                                      htmlFor='flexRadioDefault232'
                                    >
                                      No limit
                                    </label>
                                  </div>
                                </div>
                                <div className='col-12 d-flex px-0'>
                                  <div className='form-check-box'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name='limit_frequencey'
                                      id='flexRadioDefault333'
                                      defaultValue='limit_frequencey'
                                      onChange={(e) => handleLimitFrequencey(e)}
                                      checked={
                                        DvCreateLineItemData?.frequencey_type ==
                                        'limit_frequencey'
                                          ? true
                                          : false
                                      }
                                    />
                                    <label
                                      className='form-check-label'
                                      htmlFor='flexRadioDefault333'
                                    >
                                      Limit frequency to
                                    </label>
                                  </div>
                                  <div className='form-item d-flex align-items-center me-3'>
                                    <input
                                      type='email'
                                      id='email'
                                      name='frequencey_val'
                                      placeholder='Enter a value'
                                      onChange={(e) => {
                                        handleFrequencyVal(e);
                                      }}
                                      value={
                                        DvCreateLineItemData?.limit_frequencey_value
                                      }
                                    />
                                    <label>exposures per </label>
                                    <ErrorDisplay val={getFeqErr} />
                                  </div>
                                  {DvCreateLineItemData?.limit_frequencey_exposures_type ==
                                    'Months' && (
                                    <div className='form-item d-flex align-items-center me-3'>
                                      <SimpleSelectBox
                                        name='Frequency limit exposure months'
                                        placeholder='Select'
                                        options={['1', '2']}
                                        onChangeHandler={handlesExposuresValue}
                                        value={
                                          DvCreateLineItemData?.limit_frequencey_exposures_value
                                        }
                                      ></SimpleSelectBox>
                                    </div>
                                  )}
                                  {DvCreateLineItemData?.limit_frequencey_exposures_type ==
                                    'Weeks' && (
                                    <div className='form-item d-flex align-items-center me-3'>
                                      <SimpleSelectBox
                                        name='Frequency limit exposure weeks'
                                        placeholder='Select'
                                        options={['1', '2', '3', '4']}
                                        onChangeHandler={handlesExposuresValue}
                                        value={
                                          DvCreateLineItemData?.limit_frequencey_exposures_value
                                        }
                                      ></SimpleSelectBox>
                                    </div>
                                  )}
                                  {DvCreateLineItemData?.limit_frequencey_exposures_type ==
                                    'Days' && (
                                    <div className='form-item d-flex align-items-center me-3'>
                                      <SimpleSelectBox
                                        name='Frequency limit exposure days'
                                        placeholder='Select'
                                        options={['1', '2', '3', '4', '5', '6']}
                                        onChangeHandler={handlesExposuresValue}
                                        value={
                                          DvCreateLineItemData?.limit_frequencey_exposures_value
                                        }
                                      ></SimpleSelectBox>
                                    </div>
                                  )}
                                  {DvCreateLineItemData?.limit_frequencey_exposures_type ==
                                    'Hours' && (
                                    <div className='form-item d-flex align-items-center me-3'>
                                      <SimpleSelectBox
                                        name='Frequency limit exposure hours'
                                        placeholder='Select'
                                        options={[...Array(24)].map(
                                          (item, index) => index?.toString(),
                                        )}
                                        onChangeHandler={handlesExposuresValue}
                                        value={
                                          DvCreateLineItemData?.limit_frequencey_exposures_value
                                        }
                                      ></SimpleSelectBox>
                                    </div>
                                  )}
                                  {DvCreateLineItemData?.limit_frequencey_exposures_type ==
                                    'Minutes' && (
                                    <div className='form-item d-flex align-items-center me-3'>
                                      <SimpleSelectBox
                                        name='Frequency limit exposure minutes'
                                        placeholder='Select'
                                        options={[...Array(59)].map(
                                          (item, index) => index?.toString(),
                                        )}
                                        onChangeHandler={handlesExposuresValue}
                                        value={
                                          DvCreateLineItemData?.limit_frequencey_exposures_value
                                        }
                                      ></SimpleSelectBox>
                                    </div>
                                  )}
                                  <div className='form-item d-flex align-items-center'>
                                    <SimpleSelectBox
                                      name='Frequency limit exposure'
                                      placeholder='Select'
                                      options={[
                                        'Lifetime of this campaign',
                                        'Months',
                                        'Weeks',
                                        'Days',
                                        'Hours',
                                        'Minutes',
                                      ]}
                                      onChangeHandler={handleExposureVal}
                                      value={
                                        DvCreateLineItemData?.limit_frequencey_exposures_type
                                      }
                                    ></SimpleSelectBox>
                                    <ErrorDisplay
                                      val={formErrors?.frequencyLimitExposure}
                                    />
                                  </div>
                                </div>
                                <span
                                  className='error-message'
                                  style={{ color: 'red' }}
                                >
                                  {formErrors?.frequenceyTypeError}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card-header-top d-flex'>
                      <div className='card-lt-pane'>
                        <h5>Line Item Creatives*</h5>
                      </div>
                      <div className='me-auto card-switch-rigth'>
                        <div className='form-panel select-with-side-label'>
                          <div className='form-item'>
                            <div className='label-icon'>
                              <label>Creative optimization</label>
                              <span
                                className='label-information tooltips'
                                tooltip='Cascading Style Sheets'
                                tooltip-position='top'
                              >
                                {' '}
                                {/* <i className='icon-info' /> */}
                              </span>
                            </div>
                            <div className='select-box'>
                              <SimpleSelectBox
                                name='creative_optimization'
                                placeholder='Select'
                                options={creativeOptimization}
                                onChangeHandler={handleCreativeOptimizationVal}
                                value={
                                  DvCreateLineItemData?.creative_optimization
                                }
                              ></SimpleSelectBox>
                              <ErrorDisplay
                                val={formErrors?.creative_optimization_type}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CardBody>
                      <div className='hide-show'>
                        <div className='carosuel-accordion-full'>
                          <section className='accordion-primary-cont d-flex'>
                            <article className='accordion-data-cont'>
                              <Accordion defaultActiveKey='0'>
                                {[...images].map((item, index) => {
                                  return (
                                    <Accordion.Item
                                      eventKey={index}
                                      key={index}
                                    >
                                      <Accordion.Header>
                                        <div className='accordion-data-icon'>
                                          <i className='icon-double-menu' />
                                        </div>
                                        <div className='accordion-data-img'>
                                          {renderMedia(item)}
                                        </div>
                                        <div className='accordion-data'>
                                          <h4>
                                            {item?.imgName || item?.mediaName}
                                          </h4>
                                          {/* <p>Dimensions: 1600 x 400</p> */}
                                        </div>
                                      </Accordion.Header>

                                      <Accordion.Body>
                                        <div className='accordion-body-inner'>
                                          <div className='slideshow-box d-flex'>
                                            <div className='slideshow-imgbox d-flex align-items-center'>
                                              {renderMedia(item)}
                                            </div>
                                            <div className='slideshow-cont'>
                                              <div className='slideshow-cont-top'>
                                                <h3>
                                                  {item?.imgName ||
                                                    item?.mediaName}
                                                </h3>
                                                <p>
                                                  {/* Dimensions: 1600 x 400 */}
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          <CardHeader>
                                            <h6>Landing page URL</h6>
                                          </CardHeader>

                                          <CardBody>
                                            <ReactSelect
                                              options={landingUrl}
                                              onChange={(e) => {
                                                let file = images?.[index];
                                                setImages((prev) => [
                                                  ...prev.slice(0, index),
                                                  {
                                                    ...file,
                                                    landingurlId: e?.value,
                                                    landingUrl: e?.label,
                                                  },
                                                  ...prev.slice(index + 1),
                                                ]);
                                              }}
                                              value={[
                                                {
                                                  value: item?.landingurlId,
                                                  label: item?.landingUrl,
                                                },
                                              ]}
                                              closeMenuOnSelect={true}
                                            />
                                          </CardBody>
                                          <div
                                            className='d-flex justify-content-end'
                                            style={{ marginTop: '13px' }}
                                          >
                                            <button
                                              type='button'
                                              className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                              onClick={() =>
                                                setImages((prev) => [
                                                  ...prev.slice(0, index),
                                                  ...prev.slice(index + 1),
                                                ])
                                              }
                                            >
                                              <i className='icon-delete' />
                                              Remove
                                            </button>
                                          </div>
                                        </div>

                                        {/* </div> */}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  );
                                })}

                                {/* </div> */}
                              </Accordion>
                            </article>
                          </section>
                        </div>
                      </div>

                      <div className='media-btn'>
                        <div className='file-error'>
                          <div
                            className='btn-icon btn-file-icon'
                            onClick={openMediaWidget}
                          >
                            <i className='icon-plus-circle' />
                            <label htmlFor='imgFileIDVib'>
                              Assign creatives
                              <ErrorDisplay
                                val={formErrors?.assignCreatives}
                              ></ErrorDisplay>
                            </label>
                          </div>
                          <span className='error' id='emailError'>
                            {formErrors?.landingurl}
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </article>
              {/* view starts */}
              <div
                className='tab-pane fade'
                id='review'
                role='tabpanel'
                aria-labelledby='review-tab'
              >
                <div className='rv-details-cont'>
                  <div className='card-primary-repet'>
                    <div className='card-primary'>
                      <div className='card-header-top d-flex'>
                        <div className='card-lt-pane'>
                          <h5>Campaign name</h5>
                          <p>New Awareness Campaign</p>
                          <p>ID: 23857805967620728</p>
                        </div>
                      </div>
                      <div className='card-header-top d-flex'>
                        <div className='card-lt-pane'>
                          <h5>Special ad categories</h5>
                          <p>No categories declared</p>
                        </div>
                      </div>
                      <div className='card-header-top d-flex'>
                        <div className='card-lt-pane'>
                          <h5>Buying type</h5>
                          <p>Awareness</p>
                        </div>
                      </div>
                      <div className='card-header-top d-flex'>
                        <div className='card-lt-pane'>
                          <h5>Ad sets</h5>
                          <p className='add-data'>Please add: Ad sets</p>
                        </div>
                      </div>
                      <div className='card-header-top d-flex'>
                        <div className='card-lt-pane'>
                          <h5>Format</h5>
                          <p>Image and Video</p>
                        </div>
                        <div className='card-rt-pane d-flex me-auto align-items-center'>
                          <figure className='prev-icon'>
                            <img src={atdplaceholder} />
                          </figure>
                          <button
                            type='button'
                            className='btn-icon btn-icon-primary btn-bedcrumb-act me-0'
                          >
                            Preview
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* view ends */}
              <div
                className='tab-pane fade'
                id='history'
                role='tabpanel'
                aria-labelledby='history-tab'
              ></div>
            </section>
          </div>
        </div>
      </div>
      <CommonModal
        show={show}
        handleClose={handleClose}
        copy={'Your DV360 Campaign has been successfully created.'}
      />
      <CommonModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        copy={'LineItem has been successfully saved as Draft.'}
      />
      {isUpdate && (
        <CommonModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          handleRedirection={() => handleRedirection()}
          copy={'LineItem has been successfully saved.'}
          isUpdatedStatus={isUpdate}
        />
      )}
      <MediaWidget
        show={showMediaWidget}
        handleClose={handleCloseMediaWidget}
        setImages={setImages}
        platformType={'DV360'}
        selectedTab={'Display'}
        images={images}
      />
      {displayLoader && <Loader />}
      {getApprovalPublisherModelShowStatus && (
        <SelectApprovalPublisher
          type={'dv360'}
          handleApprovalPublisher={handleApprovalPublisher}
          handleSetPublisherId={handleSetPublisherId}
          getPublisherInfo={getPublisherInfo}
          getPublisherError={getPublisherError}
          handleCloseModel={handleCloseModel}
        />
      )}
      {getApprovalCoinfirmationPopupShowStatus && (
        <ApprovalConfirmationPopup
          type={'dv360'}
          handleApprovalPublisherRequest={handleApprovalPublisherRequest}
          handleCloseModel={handleCloseModel}
          isPublisher={isPublisher}
        />
      )}
      {getThankYouApprovalPopupShowStatus && (
        <ThankYouApprovalPopup
          handleRedirect={handleRedirection}
          approved={isPublisher ? 'approved' : ''}
        />
      )}
      <Footer
        nextBtnlabel={isUpdateTrue ? 'Save' : 'Save'}
        handleSubmit={handleSubmit}
        getAtdStatus={getModerationStatus}
        closePath={'/dv-360/campaigns-listing'}
      ></Footer>
    </div>
  );
}
export default LineItem;
