import React from 'react';

const commonBudget = (props) => {
  return (
    <>
      <div className='label-icon'>
        <label>
          Budget<sup></sup>
        </label>
      </div>
      <div className='input-group'>
        <span className='input-group-text rs-text'>
          <i className='icon-rupee'></i>
        </span>
        <input
          type='text'
          id={props.id}
          placeholder=''
          onChange={props.handler}
          value={props.value}
        />
        <span className='input-group-text inr-text rs-text'>
          {props?.getGoalTypeVal == 'Viewability %' ||
          props?.getGoalTypeVal == 'Impression click through Rate (CTR)' ||
          props?.getGoalTypeVal == 'Click conversion rate (CVR)' ||
          props?.getGoalTypeVal == 'Impression conversion Rate (CVR)' ||
          props?.getGoalTypeVal == 'Completion Rate (Audio)' ||
          props?.getGoalTypeVal == 'Completion Rate (Video)'
            ? '%'
            : 'INR'}
        </span>
        <span className='error'>
          {props?.dailySpacingValidation?.length > 0
            ? props?.dailySpacingValidation
            : props.err}
        </span>
      </div>
    </>
  );
};

export default commonBudget;
