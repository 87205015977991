import { useSelector } from 'react-redux';
import CardBody from '../common/CardBody';
import { useEffect, useState } from 'react';

const ChooseCreateAdType = (props) => {
  const state = useSelector((state) => state);
  const getSideTreeInfo = state?.sideTreeGoogleReducer?.SideTreeGoogle;
  const [productFeeds, setProductFeeds] = useState(false);

  useEffect(() => {
    setProductFeeds(
      getSideTreeInfo?.campaignSetting?.productFeeds == 0 ? false : true,
    );
  }, [getSideTreeInfo]);
  return (
    <>
      <CardBody>
        <div className='card-form form-panel'>
          <div className='row align-items-center'>
            <div className='action-btn-cont pt-3 pb-3'>
              <div className='action-btn-both action-btn-icon-tag row'>
                <div className='form-check-box col-12'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='Image'
                    value='Image'
                    id='flexRadioDefault1'
                    onChange={props?.handleSetSelectedType}
                    checked={props?.selectedType == 'Image' ? true : false}
                    disabled={props?.disabledStatus}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault1'
                  >
                    <div className='label-multitext'>
                      {productFeeds
                        ? 'Image and products ad'
                        : 'Single image ad'}
                    </div>
                    <p>
                      {productFeeds
                        ? 'Show ads using your image and product assets'
                        : 'Show ads with a single image'}
                    </p>
                  </label>
                </div>
                <div className='form-check-box col-12'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='Video'
                    value='Video'
                    id='flexRadioDefault2'
                    onChange={props?.handleSetSelectedType}
                    checked={props?.selectedType == 'Video' ? true : false}
                    disabled={props?.disabledStatus}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault2'
                  >
                    <div className='label-multitext'>
                      {productFeeds ? 'Video and products ad' : 'Video ad'}{' '}
                    </div>
                    <p>
                      {productFeeds
                        ? 'Show ads using your video and product assets'
                        : 'Show ads with a single video'}
                    </p>
                  </label>
                </div>
                {productFeeds && (
                  <div className='form-check-box col-12'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='Carousel'
                      value={productFeeds ? 'Products' : 'Carousel'}
                      id='flexRadioDefault2'
                      onChange={props?.handleSetSelectedType}
                      checked={
                        props?.selectedType == 'Carousel' ||
                        props?.selectedType == 'Products'
                          ? true
                          : false
                      }
                      disabled={props?.disabledStatus}
                    />

                    <label
                      className='form-check-label'
                      htmlFor='flexRadioDefault2'
                    >
                      <div className='label-multitext'>Carousel image ad</div>
                      <p>Show ads with multiple images in a carousel</p>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </>
  );
};

export default ChooseCreateAdType;
