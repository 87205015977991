// import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import '../../assets/css/style.css';
import Sidebar from '../../components/common/Sidebar';
// import FormHeader from '../components/common/FormHeader';
import Card from '../../components/common/Card';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';
import SimpleSelectBox from '../../components/common/SimpleSelectBox';
import Footer from '../../components/common/Footer';
import atdplaceholder from '../../assets/images/atd-placeholder.jpg';
import { useEffect, useState } from 'react';
import AdvancedCardHeader from '../../components/common/AdvancedCardHeader';
import ReactDatePicker from 'react-datepicker';
import searchImg from '../../assets/images/search.svg';
import displayImg from '../../assets/images/display.svg';
import ReactSelect from '../../components/common/ReactSelect';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  googleObjectiveList,
  googleCampaignTypeList,
} from '../../state/googleCreateCampaign/CreateCampaignReducer';

import {
  adScheduleTime,
  addDeviceModel,
  addOperatingSystem,
  addParam,
  addSchedule,
  addSelectedDeviceModelFinalChildVal,
  addSelectedDeviceModelSubChildVal,
  addSelectedDeviceModelVal,
  addSelectedNetworks,
  addSelectedOperatingSystemFinalChildVal,
  addSelectedOperatingSystemSubChildVal,
  addSelectedOperatingSystemVal,
  getCampaignDataById,
  getMasterApi,
  removeSchedule,
  resetForm,
  setAdLocation,
  setAdRotation,
  setAppName,
  setAppNameForApp,
  setAppPlatform,
  setAppPlatformForApp,
  setAudienceSeg,
  setBidHigherCheck,
  setBidNewCusCheck,
  setBrandRestricted,
  setCPCTarget,
  setCampType,
  setCampaignBudget,
  setCampaignGoalCheck,
  setCampaignList,
  setCampaignName,
  setCampaignObjectiveAndType,
  setCampaignPriority,
  setCampaignURL,
  setCampaignURLOnPerformance,
  setCampaignURLTemplate,
  setCustomParam,
  setDataFeedType,
  setDemandGenBudgetAmount,
  setEndDate,
  setFinalUrlCheck,
  setGoogleBiddingFocus,
  setInventoryFilter,
  setIsDataFeed,
  setLanguagee,
  setLocationType,
  setMerchantCenterAccount,
  setNetworkId,
  setOptimizeCampaignCheck,
  setPayForType,
  setPhoneCalls,
  setProductFeedStatus,
  setProductMerchentId,
  setSelecteDays,
  setSelectedCampaignGoal,
  setSelectedConversionGoal,
  setSelectedDemandGenCostPerActionVal,
  setSelectedDemandGenLangEnableDesableStatus,
  setSelectedDeviceModel,
  setSelectedDeviceType,
  setSelectedFinalDeviceModel,
  setSelectedLangauges,
  setSelectedOperatingSystem,
  setSpecificTargetingDevices,
  setStartDate,
  setTargetCpacost,
  setTargetCpicost,
  setTargetCpprcost,
  setTextAssetCheck,
  setTrackingTemplatesList,
  setViewableCPM,
  setWaysToGetConversions,
  setWebsiteVisits,
  setWebsiteurlDisplay,
  setcpaCheckBox,
  setcpcCheckBox,
  setroasCheckBox,
} from '../../state/googleCreateCampaign/CreateCampaignActions';
import { getApi, postApi, putApi } from '../../utils/Apis';
import { formatDateToIST, multiSelectVal } from '../../utils/validations';
import {
  EnabledApproval,
  baseUrlStg,
  createInsertionOrderUrl,
  googleV1Url,
} from '../../utils/constants';
import Loader from '../../components/common/Loader';
import BiddingForDisaplyFlow from '../../components/common/BiddingForDisaplyFlow';
import CampaignGoal from '../../components/googleCampaign/CampaignGoal';
import CommonInfoComponent from '../../components/common/CommonInfoComponent';
import CommonAmountComponent from '../../components/common/CommonAmountComponent';
import BudgetAndDates from '../../components/googleCampaign/BudgetAndDates';
import LocationAndLanguage from '../../components/googleCampaign/LocationAndLanguage';
import CommonSmallDescription from '../../components/common/CommonSmallDescription';
import Devices from '../../components/googleCampaign/Devices';
import CampaignTypes from '../../components/google/CampaignTypes';
import LocationGroups from '../../components/google/LocationGroups';
import { GoogleAdOptions } from './GoogleAdConstant';
import BiddingForApp from '../../components/common/BiddingForApp';
import CommonModal from '../../components/common/commonModal';
import SideTreeMenuView from './Components/SideTreeMenuView';
import ThankYouApprovalPopup from '../../components/common/ThankYouApprovalPopup';
import ApprovalConfirmationPopup from '../../components/common/ApprovalConfirmationPopup';
import SelectApprovalPublisher from '../../components/common/SelectApprovalPublisher';
import { ApproveReject } from '../../components/ApproveReject/ApproveReject';
import { blockInvalidChar, handleFocus, handleWheel } from '../../utils/utils';

function CreateGoogleAdCampaign() {
  // create google campaign form data
  const [formErrors, setFormErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [biddingFocusOptions, setBiddingFocusOptions] = useState('search');
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUrl = window.location.href;
  const localStorageKey = 'newGoogleCampaignFlag';

  // to get the id of query
  const { id } = useParams();
  let re = /^[0-9\b]+$/;
  let getCurrentPageStatus = typeof id != 'undefined' ? true : false;

  let bidOptionsForSearch = [
    {
      value: 'Conversions',
      label: 'Conversions',
    },
    {
      value: 'Conversions value',
      label: 'Conversions value',
    },
    { value: 'Clicks', label: 'Clicks' },
    {
      value: 'Impression share',
      label: 'Impression share',
    },
    {
      value: 'Manual CPC',
      label: 'Manual CPC',
    },
  ];
  let bidOptionsForPerformance = [
    {
      value: 'Conversions',
      label: 'Conversions',
    },
    {
      value: 'Conversions value',
      label: 'Conversions value',
    },
  ];
  let bidOptionsForShopping = [
    {
      value: 'Manual CPC',
      label: 'Manual CPC',
    },
    {
      value: 'Target ROAS',
      label: 'Target ROAS',
    },
    { value: 'Clicks', label: 'Clicks' },
  ];
  //CONSTANTS
  const saveAsDraft = 'Save As Draft';
  const [isDraft, setIsDraft] = useState(false);
  const [campaignId, setCampaignId] = useState(false);
  //states to manage hide and show of fields based on campaign type and objective type - starts
  const [showWaysToReachGoal, setShowWaysToReachGoal] = useState(false);
  const [showMerchentCenter, setShowMerchentCenter] = useState(false);
  const [showFeedShopping, setShowFeedShopping] = useState(false);
  const [showNetworks, setShowNetworks] = useState(false);
  const [showBudget, setShowBudget] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const [showCustomerAcquisition, setShowCustomerAcquisition] = useState(false);
  const [showAutomaticallyCreatedAssets, setShowAutomaticallyCreatedAssets] =
    useState(false);
  const [showAudienceSegments, setShowAudienceSegments] = useState(false);
  const [showBroadMatchKeywords, setShowBroadMatchKeywords] = useState(false);
  const [showAdRotation, setShowAdRotation] = useState(false);
  const [showBrandRestricted, setShowBrandRestricted] = useState(false);
  const [showSiteLinks, setShowSiteLinks] = useState(false);
  const [showLeadForm, setShowLeadForm] = useState(false);
  const [showCampaignUrl, setShowCampaignUrl] = useState(true);
  const [showVideoEnhancement, setShowVideoEnhancement] = useState(false);
  const [showProductFeed, setShowProductFeed] = useState(false);
  const [showNetworkForVideo, setShowNetworkForVideo] = useState(false);
  const [showBudgetWithType, setshowBudgetWithType] = useState(false);
  const [showLeadFormInGoals, setShowLeadFormInGoals] = useState(false);
  const [showWebsiteUrlForDisplayFlow, setShowWebsiteUrlForDisplayFlow] =
    useState(false);
  const [showDynamicAdsForDisplayFlow, setShowDynamicAdsForDisplayFlow] =
    useState(false);
  const [showConversionsForDisplayFlow, setShowConversionsForDisplayFlow] =
    useState(false);
  const [showBidding, setShowBidding] = useState(false);
  const [showBiddingForDisplay, setShowBiddingForDisplay] = useState(false);
  const [hideOtherGoalsThanWebsite, setHideOtherGoalsThanWebsite] =
    useState(false);
  const [showLocationGroups, setShowLocationGroups] = useState(false);
  const [disableObjective, setDisableObjective] = useState(false);
  const [disableCampaignType, setDisableCampaignType] = useState(false);

  //states to manage hide and show of fields based on campaign type and objective type - ends

  const [showCampaignSubtypeForShopping, setShowCampaignSubtypeForShopping] =
    useState(false);
  const [showDataFeedApp, setShowDataFeedApp] = useState(false);
  const [showCampaignSubtypeForApp, setShowCampaignSubtypeForApp] =
    useState(false);
  const [showAppPlatformForApp, setShowAppPlatformForApp] = useState(false);
  const [showBiddingForApp, setShowBiddingForApp] = useState(false);
  const [showInventoryFilter, setShowInventoryFilter] = useState(false);
  const [showLocalProducts, setShowLocalProducts] = useState(false);
  const [showsearchNetwork, setShowsearchNetwork] = useState(false);
  const [showAdSchedule, setShowAdSchedule] = useState(true);
  const [showCampaignPriority, setShowCampaignPriority] = useState(false);
  const [showAllDevices, setAllShowDevices] = useState(false);
  const [showLocation, setShowLocation] = useState(true);
  const [campSubTypeShopping, setCampSubTypeShopping] = useState(
    'Performance_Max_Campaign',
  );
  const [campSubTypeApp, setCampSubTypeApp] = useState('App_Installs');

  //States added by Prashant start

  const [showCampaignGoalDemandGen, setShowCampaignGoalDemandGen] =
    useState(false);
  const [showTargetCostPerAction, setShowTargetCostPerAction] = useState(false);
  const [showBudgetAndDates, setShowBudgetAndDates] = useState(false);
  const [showLocationAndLanguage, setShowLocationAndLanguage] = useState(false);
  const [showSingleStartDateEndDate, setShowSingleStartDateEndDate] =
    useState(false);
  const [showDeviceWidget, setShowDeviceWidget] = useState(false);
  const [getCurrentSelectedCampaignType, setCurrentSelectedCampaignType] =
    useState('');
  //States added by Prashant end
  const [showModal, setShowModal] = useState(false);
  const [getCampaignBudgetError, setCampaignBudgetError] = useState('');
  //campaign type and objective type based condition check to hide and show fileds
  const [
    getApprovalPublisherModelShowStatus,
    setApprovalPublisherModelShowStatus,
  ] = useState(false);
  const [
    getApprovalCoinfirmationPopupShowStatus,
    setApprovalCoinfirmationPopupShowStatus,
  ] = useState(false);
  const [
    getThankYouApprovalPopupShowStatus,
    setThankYouApprovalPopupShowStatus,
  ] = useState(false);
  const [getPublisherInfo, setPublisherInfo] = useState([]);
  const [getPublisherError, setPublisherError] = useState('');

  const state = useSelector((state) => state);
  const googleCreateCampaignData = state?.googleCreateCampaign;
  const getUserInfo = state?.loginReducer?.userToken;
  const [isPublisher, setIsPublisher] = useState(false);
  const { handleAPICall } = ApproveReject();

  const onChangeHandler = (e) => {
    if (!googleCreateCampaignData?.campaign_name) {
      scrollToError('campaign_name');
      setFormErrors({
        campaign_name: 'First Please Select the Campaign Name',
      });
    } else {
      dispatch(setCampaignObjectiveAndType(e));
      setFormErrors({
        campaign_name: '',
      });
    }
  };
  const conversionTrackingHandler = (e) => {
    // dispatch(setConversionTracking(e));
  };

  const websiteVisitsHandler = (e) => {
    if (e?.target.type == 'checkbox') {
      dispatch(setCampaignGoalCheck(e));
    } else {
      dispatch(setWebsiteVisits(e));
    }
  };

  const phoneCallsHandler = (e) => {
    if (e?.target?.type == 'checkbox') {
      dispatch(setCampaignGoalCheck(e));
    } else {
      dispatch(setPhoneCalls(e));
    }
  };
  const locationHandler = (e) => {
    dispatch(setLocationType(e));
  };
  const handleRedirect = () => {
    window.open(createInsertionOrderUrl, '_blank');
  };
  const storeVisitsHandler = (e) => {
    dispatch(setCampaignGoalCheck(e));
  };
  const optimizeCampaignHandler = (e) => {
    dispatch(setOptimizeCampaignCheck(e));
  };
  const bidHigherForNewCustomersHandler = (e) => {
    dispatch(setBidHigherCheck(e));
  };
  const bidOnlyForNewCustomersHandler = (e) => {
    dispatch(setBidNewCusCheck(e));
  };
  const textAssetsHandler = (e) => {
    dispatch(setTextAssetCheck(e));
  };
  const finalUrlHandler = (e) => {
    dispatch(setFinalUrlCheck(e));
  };

  const appTypeHandler = (e, goalName) => {
    if (e?.target?.type == 'radio') {
      dispatch(setAppPlatform(e));
    } else {
      dispatch(setCampaignGoalCheck(e));
    }
  };
  const appTypeHandlerForApp = (e, goalName) => {
    if (e?.target?.type == 'radio') {
      dispatch(setAppPlatformForApp(e));
    }
  };

  const appNameHandler = (e) => {
    dispatch(setAppName(e));
  };
  const appHandler = (e) => {
    dispatch(setAppNameForApp(e));
  };

  const campaignNameHandler = (e) => {
    // alert("hi")
    setFormErrors({
      campaign_name: '',
    });

    dispatch(setCampaignName(e));

    handleSetTrackingTemplateList(e);
  };
  const merchantCenterAccountHandler = (e) => {
    dispatch(setMerchantCenterAccount(e));
  };
  const handleSetTrackingTemplateList = (e) => {
    dispatch(setTrackingTemplatesList(e));
  };
  const googleBiddingHandler = (e) => {
    dispatch(setGoogleBiddingFocus({ data: e, name: 'bidStrategy' }));
  };
  const waysToGetConversionsHandler = (e) => {
    dispatch(setWaysToGetConversions({ data: e, name: '' }));
  };
  const payForTypeHandler = (e) => {
    dispatch(setPayForType({ data: e, name: '' }));
  };

  const cpaCheckBoxHandler = (e) => {
    dispatch(setcpaCheckBox(e));
  };
  const roasCheckBoxHandler = (e) => {
    dispatch(setroasCheckBox(e));
  };
  const cpcCheckBoxHandler = (e) => {
    dispatch(setcpcCheckBox(e));
  };
  const cpaCostHandler = (e) => {
    dispatch(setTargetCpacost(e));
  };

  const cpcHandler = (e) => {
    dispatch(setCPCTarget(e));
  };
  const cpiCostHandler = (e) => {
    dispatch(setTargetCpicost(e));
  };
  const cpprCostHandler = (e) => {
    dispatch(setTargetCpprcost(e));
  };
  const viewableCPMHandler = (e) => {
    dispatch(setViewableCPM(e));
  };

  const adLocationHandler = (e) => {
    dispatch(setAdLocation(e));
  };

  const handleNetworkId = (e) => {
    dispatch(setNetworkId(e));
  };

  const startDateHandler = (e) => {
    dispatch(
      setStartDate({
        date: e,
        name: 'startDate',
      }),
    );
  };
  const endDateHandler = (e) => {
    dispatch(
      setEndDate({
        date: e,
        name: 'endDate',
      }),
    );
  };

  const handleAddSchedule = (e) => {
    dispatch(addSchedule());
  };

  const handleSelectDays = (e, index) => {
    dispatch(setSelecteDays(e, index));
  };

  const handleAdScheduleTime = (time, index, timeType) => {
    dispatch(adScheduleTime(time, index, timeType));
  };

  const handleRemoveAdSchedule = (index) => {
    dispatch(removeSchedule(index));
  };

  const handleCampURL = (e) => {
    dispatch(setCampaignURL(e));
  };
  const handleCampURLTemplate = (e) => {
    dispatch(setCampaignURLTemplate(e));
  };

  const handleChangeCampBudget = (e) => {
    dispatch(setCampaignBudget(e));
  };

  const handleAddParam = () => {
    dispatch(addParam());
  };

  const handleCusParam = (e, index, type) => {
    dispatch(setCustomParam(e, index, type));
  };
  const brandRestricedHandler = (e) => {
    // console.log("state",e)
    // let values = multiSelectVal(e);
    dispatch(setBrandRestricted(e));
  };

  const handleWebsiteUrlForDisplay = (e) => {
    dispatch(setWebsiteurlDisplay(e?.target?.value));
  };
  const campaignSubtypeHandler = (e) => {
    let type = e?.target?.value;
    if (type == 'Performance_Max_Campaign') {
      setCampSubTypeShopping('Performance_Max_Campaign');
    } else if (type == 'Standard_Shopping_Campaign') {
      setCampSubTypeShopping('Standard_Shopping_Campaign');
    }
    dispatch(setCampType(type));
    // dispatch(resetForm());
  };
  const campaignSubtypeAppHandler = (e) => {
    // alert("ji")
    let type = e?.target?.value;
    if (type == 'App_Installs') {
      setCampSubTypeApp('App_Installs');
    } else if (type == 'App_Engagement') {
      setCampSubTypeApp('App_Engagement');
    } else if (type == 'App_Pre_Registration') {
      setCampSubTypeApp('App_Pre_Registration');
    }
    dispatch(setCampType(type));
  };
  const inventoryFilterHandler = (e) => {
    let type = e?.target?.value;
    dispatch(setInventoryFilter(type));
  };

  function scrollToError(firstErrorKey) {
    try {
      const findAndScroll = () => {
        const errorElement = document.getElementById(`error-${firstErrorKey}`);
        if (errorElement) {
          const offset = 10;
          errorElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
            offsetTop: offset,
          });
        } else {
          console.log(`Error element not found in the DOM`, errorElement);
        }
      };

      // Assuming some asynchronous operation (e.g., data fetching) before finding the element
      // Replace this with your actual asynchronous operation
      setTimeout(() => {
        findAndScroll();
      }, 500); // Adjust the timeout based on your specific use case
    } catch (error) {
      console.log('Error:', error);
    }
  }
  const validate = () => {
    const errors = {};

    let isWebsiteVisitsChecked =
      googleCreateCampaignData?.google_Goalinfo?.isWebsiteVisit;
    let websiteURL =
      googleCreateCampaignData?.google_Goalinfo?.websiteUrl?.trim();

    if (isWebsiteVisitsChecked) {
      let regex = /^(https?:\/\/)?(www\.)?[^\s/$.?#]+\.[^\s]*$/i;
      if (websiteURL == '')
        errors.websiteVisits = 'Please enter the website URL';
      else if (!regex.test(websiteURL)) {
        errors.websiteVisits = 'Please enter the valid website URL';
      }
    }

    let websiteURLDisplay =
      googleCreateCampaignData?.websiteUrlForDisplay?.trim();
    if (showWebsiteUrlForDisplayFlow) {
      let regex = /^(https?:\/\/)?(www\.)?[^\s/$.?#]+\.[^\s]*$/i;
      if (!regex.test(websiteURLDisplay)) {
        errors.websiteVisitsDisplay = 'Please enter the valid website URL';
      }

      if (!websiteURLDisplay) {
        // errors.websiteVisitsDisplay = '';
      }
    }

    let isPhoneCallsChecked =
      googleCreateCampaignData?.google_Goalinfo?.isPhoneCalls;
    let phoneNumber =
      googleCreateCampaignData?.google_Goalinfo?.phoneNumber?.trim();

    if (isPhoneCallsChecked) {
      let regex = new RegExp(/^(0|91)?[6-9][0-9]{9}$/);
      if (phoneNumber == '') {
        errors.phoneCalls = 'Please enter the phone number';
      } else if (regex.test(phoneNumber) != true) {
        errors.phoneCalls = 'Please enter the valid phone number';
      }
    }

    let startDate =
      googleCreateCampaignData?.google_Campaignsettings?.startDate;

    if (!startDate) {
      errors.startDate = 'Please enter the start date';
    }
    let endDate = googleCreateCampaignData?.google_Campaignsettings?.endDate;

    if (!endDate) {
      errors.endDate = 'Please enter the end date';
    }
    if (!googleCreateCampaignData?.campaign_name) {
      errors.campaign_name = 'Please enter the Campaign Name';
    }
    let bid_focus = googleCreateCampaignData?.google_Binding?.bidStrategy;
    if (showBidding || showBiddingForDisplay || showBiddingForApp) {
      if (bid_focus == '' || bid_focus == undefined) {
        errors.bidding_focus = 'Please select the bidding focus';
      }
    }
    if (showCampaignUrl) {
      let trackingTemplate =
        googleCreateCampaignData?.google_Campaign_Url_Data?.tracking_templates;
      if (trackingTemplate == undefined || trackingTemplate == '') {
        errors.trackingTemplate = 'Please select the tracking template';
      }
    }

    let TargetSpendCheckbox =
      googleCreateCampaignData?.google_Binding?.setTargetCostPerAction;
    let bidFocus = googleCreateCampaignData?.google_Binding?.bidStrategy;
    let targetCpa,
      targetRoas,
      maximumCpcBidLimitValue,
      impressionShareToTarget,
      adLocation;
    targetCpa = googleCreateCampaignData?.google_Binding?.targetCpa;
    targetRoas = googleCreateCampaignData?.google_Binding?.targetRoas;
    maximumCpcBidLimitValue =
      googleCreateCampaignData?.google_Binding?.maximumCpcBidLimitValue;
    impressionShareToTarget =
      googleCreateCampaignData?.google_Binding?.impressionShareToTarget;
    adLocation = googleCreateCampaignData?.google_Binding?.adLocation;
    if (TargetSpendCheckbox && getCurrentSelectedCampaignType != 'Demand Gen') {
      if (bidFocus == 'Conversions') {
        if (!targetCpa) errors.targetSpend = 'Field required';
        if (targetCpa <= 0) {
          errors.targetSpend = 'Field can not be zero or less than zero';
        }
      } else if (bidFocus == 'Conversions value') {
        if (!targetRoas) errors.targetSpend = 'Field required';
        if (targetRoas <= 0) {
          errors.targetSpend = 'Field can not be zero or less than zero';
        }
      } else if (bidFocus == 'Clicks') {
        if (!maximumCpcBidLimitValue) errors.targetSpend = 'Field required';
        if (maximumCpcBidLimitValue <= 0) {
          errors.targetSpend = 'Field can not be zero or less than zero';
        }
      }
    }
    if (bidFocus == 'Impression share') {
      if (impressionShareToTarget <= 0 || impressionShareToTarget > 100) {
        errors.bid_all_fields =
          'Field(s) can not be zero or less than zero or greater than 100(Impression share)';
      }
      if (maximumCpcBidLimitValue <= 0) {
        errors.bid_all_fields = 'Field can not be zero or less than zero';
      }
      if (!(maximumCpcBidLimitValue && impressionShareToTarget && adLocation)) {
        errors.bid_all_fields = 'All fields are required';
      }
    }

    let budget =
      googleCreateCampaignData?.google_Campaignsettings?.campaignBudget;
    if (budget < 1 && showBudget) {
      errors.budget = 'Field can not be less than zero or empty';
    }

    if (showCustomerAcquisition) {
      let optimize_camp =
        googleCreateCampaignData?.google_Binding?.customerAcquisition
          ?.optimizeCampaignAcquiringNewCustomers || false;
      let bidHigherForNewCustomers =
        googleCreateCampaignData?.google_Binding?.customerAcquisition
          ?.bidHigherForNewCustomers;
      let bidForNewCustomersOnly =
        googleCreateCampaignData?.google_Binding?.customerAcquisition
          ?.bidForNewCustomersOnly;
      if (optimize_camp) {
        if (!(bidHigherForNewCustomers || bidForNewCustomersOnly)) {
          errors.optimizeCamp = 'Please select any option';
        }
      }
    }
    if (!googleCreateCampaignData.campaign_type_id) {
      errors.campaignType = 'Please select campaign type';
    }
    if (googleCreateCampaignData?.google_Binding?.bidStrategy == 'Manual CPC') {
      if (!googleCreateCampaignData?.google_Binding?.maximumCpcBidLimitValue) {
        errors.targetSpend = 'Please Enter Value';
      }
    }
    console.log('create Error Please check', errors);
    // setFormErrors(errors);
    return errors;
  };

  const formOrder = [
    'campaign_name',
    'campaignType',
    'startDate',
    'endDate',
    'websiteVisitsDisplay',
    'bidding_focus',
    'trackingTemplate',
    'budget',
  ];
  const handleNext = async (e) => {
    let isSaveAsDraft = e.target.innerText === saveAsDraft;
    e.preventDefault();
    const errors = validate();
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      const firstErrorKey =
        formOrder.find((field) => errors[field]) || Object.keys(errors)[0];

      if (firstErrorKey) {
        scrollToError(firstErrorKey);
      } else {
        console.log('No error element found in the DOM', firstErrorKey);
      }
    } else {
      setLoader(true);

      try {
        let response;
        let payload;
        if (params['id']) {
          payload = {
            googleCampaignid: googleCreateCampaignData?.googleCampaignid,
            objectiveId: googleCreateCampaignData?.objective_id,
            campaignTypeId: googleCreateCampaignData?.campaign_type_id,
            campaignName: googleCreateCampaignData?.campaign_name,
            istargetspendEnabled: false,
            isactive: true,
            createdOn: new Date(),
            createdBy: getUserInfo?.Userid,
            webPageUrl: googleCreateCampaignData?.websiteUrlForDisplay,
            merchantCenterAccount:
              googleCreateCampaignData?.Merchent_center_account,
            campaignSubType: googleCreateCampaignData?.campaignSubType,
            // campaignSubType: '',
            campaignLocationType:
              googleCreateCampaignData?.campaignLocationType || '',
            locationGroupId: '',
            businessProfileManagerAccount: '',
            feedType: '',
            feedByType: '',
            feedByTypeValue: '',
            mobileAppPlatform:
              googleCreateCampaignData?.mobileAppPlatformForApp,
            lookupApp: googleCreateCampaignData?.lookupApp,
            marketingObjectiveForAppEngagement: '',
            goalTypeIds: [],
            campaignGoalReachType: googleCreateCampaignData?.google_Goalinfo,
            campaignBidding: {
              ...googleCreateCampaignData?.google_Binding,

              mostImportantActions: [
                'customers/8665555113/conversionActions/6726273528',
              ],
            },
            campaignSetting: {
              isSearchNetwork:
                googleCreateCampaignData?.google_Campaignsettings?.network_id?.includes(
                  'Search',
                ),
              isDisplayNetwork:
                googleCreateCampaignData?.google_Campaignsettings?.network_id?.includes(
                  'Display',
                ),
              location: 'India',
              locationTartgetId: '',
              targetedLocations: [],
              language: [
                multiSelectVal(
                  googleCreateCampaignData?.google_Campaignsettings
                    ?.languageSelected,
                ),
              ],
              audienceSegmentsId: [
                multiSelectVal(
                  googleCreateCampaignData?.google_Campaignsettings
                    ?.audience_segments_id,
                ),
              ],
              audienceTartgetId: '',
              // status: 'string',
              broadMatchKeywords: true,
              adRotationValue:
                googleCreateCampaignData?.google_Campaignsettings
                  ?.add_rotation_value,
              startDate: moment(
                formatDateToIST(
                  googleCreateCampaignData?.google_Campaignsettings?.startDate,
                ),
              ).format('YYYY-MM-DD'),
              endDate: googleCreateCampaignData?.google_Campaignsettings
                ?.endDate
                ? moment(
                    formatDateToIST(
                      googleCreateCampaignData?.google_Campaignsettings
                        ?.endDate,
                    ),
                  ).format('YYYY-MM-DD')
                : null,

              adSchedules:
                googleCreateCampaignData?.google_Campaignsettings?.schedule.map(
                  (item) => {
                    let startTime = item?.scheduleStartTime;
                    let endTime = item?.scheduleEndTime;
                    if (!startTime) {
                      return {
                        ...item,
                        scheduleStartTime: '00:00',
                        scheduleEndTime: '00:00',
                      };
                    }
                    startTime = moment(formatDateToIST(startTime)).format(
                      'HH:mm',
                    );

                    if (!endTime) {
                      return {
                        ...item,
                        scheduleStartTime: startTime,
                        scheduleEndTime: '00:00',
                      };
                    }
                    endTime = moment(formatDateToIST(endTime)).format('HH:mm');
                    return {
                      ...item,
                      scheduleStartTime: startTime,
                      scheduleEndTime: endTime,
                    };
                  },
                ),
              urlOption: {
                trackingTemplates:
                  googleCreateCampaignData?.google_Campaign_Url_Data
                    ?.tracking_templates,
                suffix:
                  googleCreateCampaignData?.google_Campaign_Url_Data?.suffix,
                created_on: new Date(),
                created_by: 23,
              },
              // brandRestricted: ["1"],
              brandRestricted: [
                multiSelectVal(
                  googleCreateCampaignData?.google_Campaignsettings
                    ?.brand_restricted,
                ),
              ],
              campaignBudget:
                googleCreateCampaignData?.google_Campaignsettings
                  ?.campaignBudget || 0,
              //new data
              automaticallyCreatedAssetText:
                googleCreateCampaignData?.automaticallyCreatedAssetText,
              automaticallyCreatedAssetFinalUrl:
                googleCreateCampaignData?.automaticallyCreatedAssetFinalUrl,
              inventoryFilter: '',
              localProducts: false,
              campaignPriority: googleCreateCampaignData?.campaign_priority,
              deviceId: googleCreateCampaignData?.deviceId || [],
              productFeeds: googleCreateCampaignData?.productFeeds,
              productFeedSelectedAccount:
                googleCreateCampaignData?.productFeedSelectedAccount,
              campaignGoal: googleCreateCampaignData?.campaignGoal || '',
              conversionGoalId:
                googleCreateCampaignData?.conversionGoalId || '',
              targetCostPerClick:
                googleCreateCampaignData?.targetCostPerClick || 0,
              budgetType: 'daily',
              campaignLevelLocationLanguage:
                googleCreateCampaignData?.campaignLevelLocationLanguage ||
                false,
              DeviceType: googleCreateCampaignData?.DeviceType || '',
              networkId: googleCreateCampaignData?.networkId || ['all'],
              operatingSystemId:
                googleCreateCampaignData?.operatingSystemId || ['all'],
              dynamicadId: 0,
              viewThroughConversions: true,
              contentLabel: googleCreateCampaignData?.contentLabel,
              contentType: googleCreateCampaignData?.contentType,
              senstiveContent: googleCreateCampaignData?.senstiveContent,
              taxonomyCampaignId:
                googleCreateCampaignData?.taxonomy_campaign_id,
              automaticallyCreatedAssetExcludedUrls: '',
              locationExcludeType: '',
              isDataFeed: googleCreateCampaignData?.isDataFeedForApp,
              dataFeedType: googleCreateCampaignData?.DataFeedTypeForApp,
              merchantCenterAccount: '',
              specificTargetingDevices:
                googleCreateCampaignData?.specificTargetingDevices || [],
            },
            saveAsDraft: isSaveAsDraft,
            atdStatus:
              EnabledApproval && getCurrentPageStatus
                ? 'draft'
                : EnabledApproval
                  ? 'draft'
                  : 'In Moderation',
            updatedOn: typeof id != 'undefined' ? new Date() : new Date(),
          };
          response = await putApi(
            `${googleV1Url}/updateGoogleCampaign`,
            '',
            payload,
            { accessToken: '2323' },
          );
          if (response?.data?.status == 200) {
            if (EnabledApproval) {
              setApprovalPublisherModelShowStatus(true);
              setShowModal(false);
            } else {
              setLoader(false);
            }

            if (isSaveAsDraft) {
              setShowModal(true);
            }
          } else {
            setLoader(false);
          }
        } else {
          payload = {
            objectiveId: googleCreateCampaignData?.objective_id,
            campaignTypeId: googleCreateCampaignData?.campaign_type_id,
            campaignName: googleCreateCampaignData?.campaign_name,
            istargetspendEnabled: false,
            isactive: true,
            createdOn: new Date(),
            createdBy: getUserInfo?.Userid,
            webPageUrl: googleCreateCampaignData?.websiteUrlForDisplay,
            merchantCenterAccount:
              googleCreateCampaignData?.Merchent_center_account,
            campaignSubType: googleCreateCampaignData?.campaignSubType,
            // campaignSubType: '',
            campaignLocationType:
              googleCreateCampaignData?.campaignLocationType || '',
            locationGroupId: '',
            businessProfileManagerAccount: '',
            feedType: '',
            feedByType: '',
            feedByTypeValue: '',
            mobileAppPlatform:
              googleCreateCampaignData?.mobileAppPlatformForApp,
            lookupApp: googleCreateCampaignData?.lookupApp,
            marketingObjectiveForAppEngagement: '',
            goalTypeIds: [],
            campaignGoalReachType: googleCreateCampaignData?.google_Goalinfo,
            campaignBidding: {
              ...googleCreateCampaignData?.google_Binding,

              mostImportantActions: [
                'customers/8665555113/conversionActions/6726273528',
              ],
            },
            campaignSetting: {
              isSearchNetwork:
                googleCreateCampaignData?.google_Campaignsettings?.network_id?.includes(
                  'Search',
                ),
              isDisplayNetwork:
                googleCreateCampaignData?.google_Campaignsettings?.network_id?.includes(
                  'Display',
                ),
              location: 'India',
              locationTartgetId: '',
              targetedLocations: [],

              language: [
                multiSelectVal(
                  googleCreateCampaignData?.google_Campaignsettings
                    ?.languageSelected,
                ),
              ],

              audienceSegmentsId: [
                multiSelectVal(
                  googleCreateCampaignData?.google_Campaignsettings
                    ?.audience_segments_id,
                ),
              ],
              audienceTartgetId: '',

              broadMatchKeywords: true,
              adRotationValue:
                googleCreateCampaignData?.google_Campaignsettings
                  ?.add_rotation_value,
              startDate: moment(
                formatDateToIST(
                  googleCreateCampaignData?.google_Campaignsettings?.startDate,
                ),
              ).format('YYYY-MM-DD'),
              endDate: googleCreateCampaignData?.google_Campaignsettings
                ?.endDate
                ? moment(
                    formatDateToIST(
                      googleCreateCampaignData?.google_Campaignsettings
                        ?.endDate,
                    ),
                  ).format('YYYY-MM-DD')
                : null,

              adSchedules:
                googleCreateCampaignData?.google_Campaignsettings?.schedule.map(
                  (item) => {
                    let startTime = item?.scheduleStartTime;
                    let endTime = item?.scheduleEndTime;
                    if (!startTime) {
                      return {
                        ...item,
                        scheduleStartTime: '00:00',
                        scheduleEndTime: '00:00',
                      };
                    }
                    startTime = moment(formatDateToIST(startTime)).format(
                      'HH:mm',
                    );

                    if (!endTime) {
                      return {
                        ...item,
                        scheduleStartTime: startTime,
                        scheduleEndTime: '00:00',
                      };
                    }
                    endTime = moment(formatDateToIST(endTime)).format('HH:mm');
                    return {
                      ...item,
                      scheduleStartTime: startTime,
                      scheduleEndTime: endTime,
                    };
                  },
                ),
              urlOption: {
                trackingTemplates:
                  googleCreateCampaignData?.google_Campaign_Url_Data
                    ?.tracking_templates,
                suffix:
                  googleCreateCampaignData?.google_Campaign_Url_Data?.suffix,
                created_on: new Date(),
                created_by: 23,
              },

              brandRestricted: [
                multiSelectVal(
                  googleCreateCampaignData?.google_Campaignsettings
                    ?.brand_restricted,
                ),
              ],
              campaignBudget:
                googleCreateCampaignData?.google_Campaignsettings
                  ?.campaignBudget || 0,
              //new data
              automaticallyCreatedAssetText:
                googleCreateCampaignData?.automaticallyCreatedAssetText,
              automaticallyCreatedAssetFinalUrl:
                googleCreateCampaignData?.automaticallyCreatedAssetFinalUrl,
              inventoryFilter: '',
              localProducts: false,
              campaignPriority: googleCreateCampaignData?.campaign_priority,
              deviceId: googleCreateCampaignData?.deviceId || [],
              productFeeds: googleCreateCampaignData?.productFeeds,
              productFeedSelectedAccount:
                googleCreateCampaignData?.productFeedSelectedAccount,
              campaignGoal: googleCreateCampaignData?.campaignGoal || '',
              conversionGoalId:
                googleCreateCampaignData?.conversionGoalId || '',
              targetCostPerClick:
                googleCreateCampaignData?.targetCostPerClick || 0,
              budgetType: 'daily',
              campaignLevelLocationLanguage:
                googleCreateCampaignData?.campaignLevelLocationLanguage ||
                false,
              DeviceType: googleCreateCampaignData?.DeviceType || '',
              networkId: googleCreateCampaignData?.networkId || ['all'],
              operatingSystemId:
                googleCreateCampaignData?.operatingSystemId || ['all'],
              dynamicadId: 0,
              viewThroughConversions: true,
              contentLabel: googleCreateCampaignData?.contentLabel,
              contentType: googleCreateCampaignData?.contentType,
              senstiveContent: googleCreateCampaignData?.senstiveContent,
              taxonomyCampaignId:
                googleCreateCampaignData?.taxonomy_campaign_id,
              automaticallyCreatedAssetExcludedUrls: '',
              locationExcludeType: '',
              isDataFeed: googleCreateCampaignData?.isDataFeedForApp,
              dataFeedType: googleCreateCampaignData?.DataFeedTypeForApp,
              merchantCenterAccount: '',
              specificTargetingDevices:
                googleCreateCampaignData?.specificTargetingDevices || [],
            },
            saveAsDraft: isSaveAsDraft,
            atdStatus:
              EnabledApproval && getCurrentPageStatus
                ? 'draft'
                : EnabledApproval
                  ? 'draft'
                  : 'In Moderation',
          };

          response = await postApi(
            `${googleV1Url}/createGoogleCampaign`,
            payload,
            { accessToken: '2323' },
          );
        }

        if (response.status === 200) {
          setLoader(false);
          if (!isSaveAsDraft) {
            setShowModal(true);
          }
          setCampaignId(response?.data?.campaignID);

          if (!params['id']) {
            let campaignID = response?.data?.campaignID;
            let redirectPath = googleCreateCampaignData?.productFeeds
              ? `${GoogleAdOptions.AdGroups.newGoogleAdGroup}?campaign_id=${campaignID}&objective_id=${googleCreateCampaignData?.objective_id}&campaign_type_id=${googleCreateCampaignData?.campaign_type_id}&taxonomyCapmId=${googleCreateCampaignData?.taxonomy_campaign_id}&productFeedsStatus=${googleCreateCampaignData?.productFeeds}`
              : `${
                  GoogleAdOptions.AdGroups.newGoogleAdGroup
                }?campaign_id=${campaignID}&objective_id=${
                  googleCreateCampaignData?.objective_id
                }&campaign_type_id=${
                  googleCreateCampaignData?.campaign_type_id
                }&taxonomyCapmId=${
                  googleCreateCampaignData?.taxonomy_campaign_id
                }&cpcValue=${
                  googleCreateCampaignData?.google_Binding?.bidStrategy ==
                  'Manual CPC'
                }&productFeedsStatus=${
                  googleCreateCampaignData?.DataFeedTypeForApp ==
                  'merchent_center_ad_feed'
                }&campaign_subtype=${
                  googleCreateCampaignData?.campaignSubType
                }`;
            if (!isSaveAsDraft) {
              navigate(redirectPath);
              dispatch(resetForm());
            } else {
              setIsDraft(true);
              showModal(false);
            }
          }
        } else {
          setLoader(false);
        }
      } catch (error) {
        console.error('API error:', error);
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    if (currentUrl.includes(GoogleAdOptions.Campaigns.editUrl)) {
      setIsDraft(false);
    }
  }, [currentUrl]);

  //update campaign starts

  //Functions added by Prashant
  const handleSelectOperatingSystem = (e, index) => {
    dispatch(setSelectedOperatingSystem(e?.value));
    dispatch(addSelectedOperatingSystemVal(e, index));
  };

  const handleSelectSectionOptionVal = (e, index) => {
    dispatch(addSelectedOperatingSystemSubChildVal(e, index));
  };

  const handleSelectedOSFinalOptions = (e, index) => {
    dispatch(addSelectedOperatingSystemFinalChildVal(e, index));
  };

  const handleAddOperatingSystem = (e) => {
    dispatch(addOperatingSystem());
  };

  // Device model functions

  const handleSelectDeviceModel = (e, index) => {
    // First drop down functionality
    dispatch(setSelectedDeviceModel(e?.value));
    dispatch(addSelectedDeviceModelVal(e, index));
  };

  const handleFinalDevice = (e, index) => {
    // Second drop down functionality
    dispatch(setSelectedFinalDeviceModel(e?.value));
    dispatch(addSelectedDeviceModelSubChildVal(e, index));
  };

  const handleSelectedDeviceModelFinalOptions = (e, index) => {
    //third drop down value store
    dispatch(addSelectedDeviceModelFinalChildVal(e, index));
  };

  const handleAddDeviceModel = (e) => {
    dispatch(addDeviceModel());
  };

  const handleSetCampaignGoalVal = (e) => {
    dispatch(setSelectedCampaignGoal(e?.value));
  };

  const handleSetConversionGoal = (e) => {
    dispatch(setSelectedConversionGoal(e?.value));
  };

  const handleSetAmountVal = (e) => {
    dispatch(setSelectedDemandGenCostPerActionVal(e?.target?.value));
  };

  const handleLangEnableDesableVal = (e) => {
    dispatch(setSelectedDemandGenLangEnableDesableStatus(e?.target?.checked));
  };

  const handleLanguageSelectVal = (e) => {
    dispatch(setSelectedLangauges(e));
  };
  const handleLanguage = (e) => {
    dispatch(setLanguagee(e));
  };
  const handleAudienceSeg = (e) => {
    dispatch(setAudienceSeg(e));
  };

  const handleRedirection = () => {
    navigate(GoogleAdOptions.Campaigns.url);
    setShowModal(false);
  };

  const handleDeviceTye = (e) => {
    dispatch(setSelectedDeviceType(e?.target?.value));
  };
  const dataFeedForApp = (e) => {
    dispatch(setIsDataFeed(e?.target?.checked));
    // (alert(e?.target?.checked));
  };
  const FeedTypeHandler = (e) => {
    dispatch(setDataFeedType(e?.target?.value));
    // (alert(e?.target?.checked));
  };

  const handleBudgetAndDateVal = (e) => {
    let currentVal = e.target.value;
    if (/^0/.test(currentVal)) {
      currentVal.replace(/^0/, '');
      setCampaignBudgetError('The value should be greater than 0');
    } else {
      if (e?.target?.value === '' || re?.test(e?.target?.value)) {
        dispatch(setDemandGenBudgetAmount(e?.target?.value));
      } else {
        setCampaignBudgetError('Enter a monetary value');
      }
    }
  };

  const handleProductFeedStatus = (e) => {
    dispatch(setProductFeedStatus(e?.target?.checked));
  };

  const handleSelectMerchentAccount = (e) => {
    dispatch(setProductMerchentId(e?.value));
  };

  const handleNetworksVal = (v) => {
    dispatch(addSelectedNetworks(v));
  };
  const adRotationHandler = (v) => {
    dispatch(setAdRotation(v));
  };
  const handleSetSpecificTargeting = (e) => {
    let newArray = [];
    e?.map((item) => {
      newArray?.push(item?.value);
    });
    dispatch(setSpecificTargetingDevices(newArray));
  };

  // useEffects
  useEffect(() => {
    switch (`${googleCreateCampaignData?.objective_id}`) {
      case `${googleObjectiveList?.Sales?.objective_id}`: //sales
        {
          switch (`${googleCreateCampaignData?.campaign_type_id}`) {
            case `${googleCampaignTypeList?.Search?.campaign_type_id}`: //search
              {
                setShowAutomaticallyCreatedAssets(false);
                setShowLanguages(true);
                setShowNetworks(true);
                setShowCustomerAcquisition(false);
                setShowWaysToReachGoal(false);
                setShowMerchentCenter(false);
                setShowAudienceSegments(true);
                setShowBroadMatchKeywords(false);
                setShowBrandRestricted(false);
                setShowAdRotation(false);
                setShowSiteLinks(false);
                setShowLeadForm(false);
                setShowCampaignUrl(true);
                setShowVideoEnhancement(false);
                setShowProductFeed(false);
                setShowNetworkForVideo(false);
                setshowBudgetWithType(false);
                setShowBudget(true);
                setShowLeadFormInGoals(false);
                setShowWebsiteUrlForDisplayFlow(false);
                setShowDynamicAdsForDisplayFlow(false);
                setShowConversionsForDisplayFlow(false);
                setShowBidding(true);
                setShowBiddingForDisplay(false);
                setHideOtherGoalsThanWebsite(false);
                setBiddingFocusOptions('search');
                setShowCampaignGoalDemandGen(false);
                setShowTargetCostPerAction(false);
                setShowBudgetAndDates(false);
                setShowLocationAndLanguage(false);
                setShowDeviceWidget(false);
                setShowLocation(true);
                setShowLocalProducts(false);
                setShowCampaignSubtypeForShopping(false);
                setShowInventoryFilter(false);
                setShowCampaignPriority(false);
                setAllShowDevices(false);
                setShowsearchNetwork(false);
                setShowFeedShopping(false);
                setShowLocationGroups(false);
                setShowCampaignSubtypeForApp(false);
                setShowAppPlatformForApp(false);
                setShowDataFeedApp(false);
                setShowBiddingForApp(false);
                setShowSingleStartDateEndDate(true);
              }
              break;
            case `${googleCampaignTypeList?.['Performance Max']?.campaign_type_id}`: //performance max
              {
                setShowAutomaticallyCreatedAssets(false);
                setShowLanguages(true);
                setShowNetworks(false);
                setShowCustomerAcquisition(false);
                setShowWaysToReachGoal(false);
                setShowMerchentCenter(true);
                setShowAudienceSegments(false);
                setShowBroadMatchKeywords(false);
                setShowBrandRestricted(false);
                setShowAdRotation(false);
                setShowSiteLinks(false);
                setShowLeadForm(false);
                setShowCampaignUrl(true);
                setShowVideoEnhancement(false);
                setShowProductFeed(false);
                setShowNetworkForVideo(false);
                setshowBudgetWithType(false);
                setShowBudget(true);
                setShowLeadFormInGoals(false);
                setShowWebsiteUrlForDisplayFlow(false);
                setShowDynamicAdsForDisplayFlow(false);
                setShowConversionsForDisplayFlow(false);
                setShowBidding(true);
                setShowBiddingForDisplay(false);
                setShowCampaignGoalDemandGen(false);
                setShowTargetCostPerAction(false);
                setShowBudgetAndDates(false);
                setShowLocationAndLanguage(false);
                setShowDeviceWidget(false);
                setBiddingFocusOptions('performance');
                setShowLocation(true);
                setShowLocalProducts(false);
                setShowCampaignSubtypeForShopping(false);
                setShowInventoryFilter(false);
                setShowCampaignPriority(false);
                setAllShowDevices(false);
                setShowsearchNetwork(false);
                setShowFeedShopping(false);
                setShowLocationGroups(false);
                setShowCampaignSubtypeForApp(false);
                setShowAppPlatformForApp(false);
                setShowDataFeedApp(false);
                setShowBiddingForApp(false);
                setShowSingleStartDateEndDate(true);
              }
              break;
            case `${googleCampaignTypeList?.['Demand Gen']?.campaign_type_id}`: //Demand Gen
              {
                setShowProductFeed(true);
                setShowCampaignGoalDemandGen(false);
                setShowTargetCostPerAction(true);
                setShowBudgetAndDates(true);
                setShowLocationAndLanguage(true);
                setShowProductFeed(true);
                setShowDeviceWidget(false); //Hided bcz backend team didn't get the insert option.
                setShowWaysToReachGoal(false);
                setShowBidding(false);
                setShowBiddingForDisplay(false);
                setShowBudget(false);
                setShowMerchentCenter(false);
                setShowWaysToReachGoal(false);
                setShowLanguages(false);
                setShowAudienceSegments(false);
                setShowAdRotation(false);
                setShowSingleStartDateEndDate(false);
                setHideOtherGoalsThanWebsite(false);
                setBiddingFocusOptions('search');
                setShowWebsiteUrlForDisplayFlow(false);
                setShowDynamicAdsForDisplayFlow(false);
                setShowConversionsForDisplayFlow(false);
                setShowCustomerAcquisition(false);
                setShowSiteLinks(false);
                setShowBroadMatchKeywords(false);
                setShowAutomaticallyCreatedAssets(false);
                setShowLeadForm(false);
                setShowBrandRestricted(false);
                setShowNetworks(false);
                setShowCampaignUrl(true);
                setshowBudgetWithType(false);
                setShowVideoEnhancement(false);
                setShowNetworkForVideo(false);
                setShowLocalProducts(false);
                setShowCampaignSubtypeForShopping(false);
                setShowInventoryFilter(false);
                setShowCampaignPriority(false);
                setAllShowDevices(false);
                setShowsearchNetwork(false);
                setShowFeedShopping(false);
                setShowLocationGroups(false);
                setShowCampaignSubtypeForApp(false);
                setShowAppPlatformForApp(false);
                setShowDataFeedApp(false);
                setShowBiddingForApp(false);
                setShowLocation(false);
              }
              break;
            case `${googleCampaignTypeList?.['Shopping']?.campaign_type_id}`: //shopping
              {
                {
                  setShowCampaignSubtypeForShopping(true);
                  if (campSubTypeShopping == 'Performance_Max_Campaign') {
                    //shopping - performance max
                    setShowAutomaticallyCreatedAssets(false);
                    setShowLanguages(true);
                    setShowNetworks(false);
                    setShowCustomerAcquisition(false);
                    setShowWaysToReachGoal(false);
                    setShowMerchentCenter(true);
                    setShowAudienceSegments(false);
                    setShowBroadMatchKeywords(false);
                    setShowBrandRestricted(false);
                    setShowAdRotation(false);
                    setShowSiteLinks(false);
                    setShowLeadForm(false);
                    setShowCampaignUrl(true);
                    setShowVideoEnhancement(false);
                    setShowProductFeed(false);
                    setShowNetworkForVideo(false);
                    setshowBudgetWithType(false);
                    setShowBudget(true);
                    setShowLeadFormInGoals(false);
                    setShowWebsiteUrlForDisplayFlow(false);
                    setShowDynamicAdsForDisplayFlow(false);
                    setShowConversionsForDisplayFlow(false);
                    setShowBidding(true);
                    setShowBiddingForDisplay(false);
                    setShowCampaignGoalDemandGen(false);
                    setShowTargetCostPerAction(false);
                    setShowBudgetAndDates(false);
                    setShowLocationAndLanguage(false);
                    setShowDeviceWidget(false);
                    setBiddingFocusOptions('performance');
                    setShowLocation(true);
                    setShowLocalProducts(false);
                    setShowInventoryFilter(false);
                    setShowCampaignPriority(false);
                    setAllShowDevices(false);
                    setShowsearchNetwork(false);
                    setShowFeedShopping(false);
                    setShowLocationGroups(false);
                    setShowCampaignSubtypeForApp(false);
                    setShowAppPlatformForApp(false);
                    setShowDataFeedApp(false);
                    setShowBiddingForApp(false);
                    setShowSingleStartDateEndDate(true);
                  } else {
                    setShowAutomaticallyCreatedAssets(false); //shopping - standard shopping
                    setShowLanguages(false);
                    setShowNetworks(false);
                    setShowCustomerAcquisition(false);
                    setShowWaysToReachGoal(false);
                    setShowMerchentCenter(true);
                    setShowAudienceSegments(false);
                    setShowBroadMatchKeywords(false);
                    setShowBrandRestricted(false);
                    setShowAdRotation(false);
                    setShowSiteLinks(false);
                    setShowLeadForm(false);
                    setShowCampaignUrl(true);
                    setShowVideoEnhancement(false);
                    setShowProductFeed(false);
                    setShowNetworkForVideo(false);
                    setshowBudgetWithType(false);
                    setShowBudget(true);
                    setShowLeadFormInGoals(false);
                    setShowWebsiteUrlForDisplayFlow(false);
                    setShowDynamicAdsForDisplayFlow(false);
                    setShowConversionsForDisplayFlow(false);
                    setShowBidding(true);
                    setShowBiddingForDisplay(false);
                    setShowCampaignGoalDemandGen(false);
                    setShowTargetCostPerAction(false);
                    setShowBudgetAndDates(false);
                    setShowLocationAndLanguage(false);
                    setShowDeviceWidget(false);
                    setBiddingFocusOptions('Shopping');
                    setShowLocation(true);
                    setShowLocalProducts(false);
                    setShowInventoryFilter(false);
                    setShowCampaignPriority(true);
                    setAllShowDevices(true);
                    setShowsearchNetwork(true);
                    setShowAdSchedule(false);
                    setShowFeedShopping(false);
                    setShowLocationGroups(false);
                    setShowCampaignSubtypeForApp(false);
                    setShowAppPlatformForApp(false);
                    setShowDataFeedApp(false);
                    setShowBiddingForApp(false);
                    setShowSingleStartDateEndDate(true);
                  }
                }
              }
              break;
            default:
          }
        }
        break;
      case `${googleObjectiveList?.Leads?.objective_id}`: //Leads
        {
          switch (`${googleCreateCampaignData?.campaign_type_id}`) {
            case `${googleCampaignTypeList?.Search?.campaign_type_id}`: //search
              {
                setShowAutomaticallyCreatedAssets(false);
                setShowLanguages(true);
                setShowNetworks(true);
                setShowCustomerAcquisition(false);
                setShowWaysToReachGoal(false);
                setShowMerchentCenter(false);
                setShowAudienceSegments(true);
                setShowBroadMatchKeywords(false);
                setShowBrandRestricted(false);
                setShowAdRotation(false);
                setShowSiteLinks(false);
                setShowLeadForm(false);
                setShowCampaignUrl(true);
                setShowVideoEnhancement(false);
                setShowProductFeed(false);
                setShowNetworkForVideo(false);
                setshowBudgetWithType(false);
                setShowBudget(true);
                setShowLeadFormInGoals(true);
                setShowWebsiteUrlForDisplayFlow(false);
                setShowDynamicAdsForDisplayFlow(false);
                setShowConversionsForDisplayFlow(false);
                setShowBidding(true);
                setShowBiddingForDisplay(false);
                setHideOtherGoalsThanWebsite(false);
                setBiddingFocusOptions('search');
                setShowCampaignGoalDemandGen(false);
                setShowTargetCostPerAction(false);
                setShowBudgetAndDates(false);
                setShowLocationAndLanguage(false);
                setShowDeviceWidget(false);
                setShowLocation(true);
                setShowLocalProducts(false);
                setShowCampaignSubtypeForShopping(false);
                setShowInventoryFilter(false);
                setShowCampaignPriority(false);
                setAllShowDevices(false);
                setShowsearchNetwork(false);
                setShowFeedShopping(false);
                setShowLocationGroups(false);
                setShowCampaignSubtypeForApp(false);
                setShowAppPlatformForApp(false);
                setShowDataFeedApp(false);
                setShowBiddingForApp(false);
                setShowSingleStartDateEndDate(true);
              }
              break;
            case `${googleCampaignTypeList?.Video?.campaign_type_id}`: //video
              {
                setShowAutomaticallyCreatedAssets(false);
                setShowLanguages(true);
                setShowWaysToReachGoal(false);
                setShowNetworks(false);
                setShowAudienceSegments(false);
                setShowBroadMatchKeywords(false);
                setShowBrandRestricted(false);
                setShowAdRotation(false);
                setShowSiteLinks(true);
                setShowLeadForm(true);
                setShowMerchentCenter(false);
                setShowCustomerAcquisition(false);
                setShowCampaignUrl(false);
                setShowVideoEnhancement(true);
                setShowProductFeed(true);
                setShowNetworkForVideo(true);
                setshowBudgetWithType(true);
                setShowBudget(false);
                setShowLeadFormInGoals(false);
                setShowWebsiteUrlForDisplayFlow(false);
                setShowDynamicAdsForDisplayFlow(false);
                setShowConversionsForDisplayFlow(false);
                setShowBidding(true);
                setShowBiddingForDisplay(false);
                setShowProductFeed(false);
                setShowCampaignGoalDemandGen(false);
                setShowTargetCostPerAction(false);
                setShowBudgetAndDates(false);
                setShowLocationAndLanguage(false);
                setShowDeviceWidget(false);
                setShowLocation(true);
                setShowLocalProducts(false);
                setShowCampaignSubtypeForShopping(false);
                setShowInventoryFilter(false);
                setShowCampaignPriority(false);
                setAllShowDevices(false);
                setShowsearchNetwork(false);
                setShowFeedShopping(false);
                setShowLocationGroups(false);
                setShowCampaignSubtypeForApp(false);
                setShowAppPlatformForApp(false);
                setShowDataFeedApp(false);
                setShowBiddingForApp(false);
                setShowSingleStartDateEndDate(true);
              }
              break;

            case `${googleCampaignTypeList?.Display?.campaign_type_id}`: //display
              {
                setShowAutomaticallyCreatedAssets(false);
                setShowLanguages(true);
                setShowWaysToReachGoal(false);
                setShowNetworks(false);
                setShowAudienceSegments(false);
                setShowBroadMatchKeywords(false);
                setShowBrandRestricted(false);
                setShowAdRotation(false);
                setShowSiteLinks(false);
                setShowLeadForm(false);
                setShowMerchentCenter(false);
                setShowCustomerAcquisition(false);
                setShowCampaignUrl(true);
                setShowVideoEnhancement(false);
                setShowProductFeed(false);
                setShowNetworkForVideo(false);
                setshowBudgetWithType(false);
                setShowBudget(true);
                setShowLeadFormInGoals(false);
                setShowWebsiteUrlForDisplayFlow(false);
                setShowDynamicAdsForDisplayFlow(false);
                setShowConversionsForDisplayFlow(false);
                setShowBidding(false);
                setShowBiddingForDisplay(true);
                setHideOtherGoalsThanWebsite(false);
                setBiddingFocusOptions('search');
                setShowCampaignGoalDemandGen(false);
                setShowTargetCostPerAction(false);
                setShowBudgetAndDates(false);
                setShowLocationAndLanguage(false);
                setShowDeviceWidget(false); //Hided bcz backend team didn't get the insert option.
                setShowLocation(true);
                setShowLocalProducts(false);
                setShowCampaignSubtypeForShopping(false);
                setShowInventoryFilter(false);
                setShowCampaignPriority(false);
                setAllShowDevices(false);
                setShowsearchNetwork(false);
                setShowFeedShopping(false);
                setShowLocationGroups(false);
                setShowCampaignSubtypeForApp(false);
                setShowAppPlatformForApp(false);
                setShowDataFeedApp(false);
                setShowBiddingForApp(false);
                setShowSingleStartDateEndDate(true);
              }
              break;
            default:
          }
        }
        break;
      case `${googleObjectiveList?.['Website Traffic']?.objective_id}`: //website traffic
        {
          switch (`${googleCreateCampaignData?.campaign_type_id}`) {
            case `${googleCampaignTypeList?.Search?.campaign_type_id}`: //search
              {
                setShowAutomaticallyCreatedAssets(false);
                setShowLanguages(true);
                setShowNetworks(true);
                setShowCustomerAcquisition(false);
                setShowWaysToReachGoal(false);
                setShowMerchentCenter(false);
                setShowAudienceSegments(true);
                setShowBroadMatchKeywords(false);
                setShowBrandRestricted(false);
                setShowAdRotation(false);
                setShowSiteLinks(false);
                setShowLeadForm(false);
                setShowCampaignUrl(true);
                setShowVideoEnhancement(false);
                setShowProductFeed(false);
                setShowNetworkForVideo(false);
                setshowBudgetWithType(false);
                setShowBudget(true);
                setShowLeadFormInGoals(true);
                setShowWebsiteUrlForDisplayFlow(false);
                setShowDynamicAdsForDisplayFlow(false);
                setShowConversionsForDisplayFlow(false);
                setShowBidding(true);
                setShowBiddingForDisplay(false);
                setHideOtherGoalsThanWebsite(true);
                setBiddingFocusOptions('search');
                setShowDeviceWidget(false);
                setShowLocation(true);
                setShowLocalProducts(false);
                setShowCampaignSubtypeForShopping(false);
                setShowInventoryFilter(false);
                setShowCampaignPriority(false);
                setAllShowDevices(false);
                setShowsearchNetwork(false);
                setShowFeedShopping(false);
                setShowLocationGroups(false);
                setShowCampaignSubtypeForApp(false);
                setShowAppPlatformForApp(false);
                setShowDataFeedApp(false);
                setShowBiddingForApp(false);
                setShowCampaignGoalDemandGen(false);
                setShowSingleStartDateEndDate(true);
                setShowTargetCostPerAction(false);
                setShowBudgetAndDates(false);
                setShowLocationAndLanguage(false);
              }
              break;

            case `${googleCampaignTypeList?.Display?.campaign_type_id}`: //display
              {
                setShowAutomaticallyCreatedAssets(false);
                setShowLanguages(true);
                setShowWaysToReachGoal(false);
                setShowNetworks(false);
                setShowAudienceSegments(false);
                setShowBroadMatchKeywords(false);
                setShowBrandRestricted(false);
                setShowAdRotation(false);
                setShowSiteLinks(false);
                setShowLeadForm(false);
                setShowMerchentCenter(false);
                setShowCustomerAcquisition(false);
                setShowCampaignUrl(true);
                setShowVideoEnhancement(false);
                setShowProductFeed(false);
                setShowNetworkForVideo(false);
                setshowBudgetWithType(false);
                setShowBudget(true);
                setShowLeadFormInGoals(false);
                setShowWebsiteUrlForDisplayFlow(false);
                setShowDynamicAdsForDisplayFlow(false);
                setShowConversionsForDisplayFlow(false);
                setShowBidding(false);
                setShowBiddingForDisplay(true);
                setHideOtherGoalsThanWebsite(false);
                setBiddingFocusOptions('search');
                setShowDeviceWidget(false); //Hided bcz backend team didn't get the insert option.
                setShowLocationGroups(false);
                setShowCampaignSubtypeForApp(false);
                setShowAppPlatformForApp(false);
                setShowDataFeedApp(false);
                setShowBiddingForApp(false);
                setShowCampaignGoalDemandGen(false);
                setShowSingleStartDateEndDate(true);
                setShowTargetCostPerAction(false);
                setShowBudgetAndDates(false);
                setShowLocationAndLanguage(false);
              }
              break;
            case `${googleCampaignTypeList?.['Demand Gen']?.campaign_type_id}`: //Demand Gen
              {
                setShowProductFeed(true);
                setShowCampaignGoalDemandGen(false);
                setShowTargetCostPerAction(true);
                setShowBudgetAndDates(true);
                setShowLocationAndLanguage(true);
                setShowProductFeed(true);
                setShowDeviceWidget(false); //Hided bcz backend team didn't get the insert option.
                setShowWaysToReachGoal(false);
                setShowBidding(false);
                setShowBiddingForDisplay(false);
                setShowBudget(false);
                setShowMerchentCenter(false);
                setShowWaysToReachGoal(false);
                setShowLanguages(false);
                setShowAudienceSegments(false);
                setShowAdRotation(false);
                setShowSingleStartDateEndDate(false);
                setHideOtherGoalsThanWebsite(false);
                setBiddingFocusOptions('search');
                setShowWebsiteUrlForDisplayFlow(false);
                setShowDynamicAdsForDisplayFlow(false);
                setShowConversionsForDisplayFlow(false);
                setShowCustomerAcquisition(false);
                setShowSiteLinks(false);
                setShowBroadMatchKeywords(false);
                setShowAutomaticallyCreatedAssets(false);
                setShowLeadForm(false);
                setShowBrandRestricted(false);
                setShowNetworks(false);
                setShowCampaignUrl(true);
                setshowBudgetWithType(false);
                setShowVideoEnhancement(false);
                setShowNetworkForVideo(false);
                setShowLocation(false);
                setShowLocalProducts(false);
                setShowCampaignSubtypeForShopping(false);
                setShowInventoryFilter(false);
                setShowCampaignPriority(false);
                setAllShowDevices(false);
                setShowsearchNetwork(false);
                setShowFeedShopping(false);
                setShowLocationGroups(false);
                setShowCampaignSubtypeForApp(false);
                setShowAppPlatformForApp(false);
                setShowDataFeedApp(false);
                setShowBiddingForApp(false);
              }
              break;
            default:
          }
        }
        break;
      case `${googleObjectiveList?.['Brand awareness and reach']?.objective_id}`: //Brand awareness and reach
        {
          switch (`${googleCreateCampaignData?.campaign_type_id}`) {
            case `${googleCampaignTypeList?.Display?.campaign_type_id}`: //display
              {
                setShowAutomaticallyCreatedAssets(false);
                setShowLanguages(true);
                setShowWaysToReachGoal(false);
                setShowNetworks(false);
                setShowAudienceSegments(false);
                setShowBroadMatchKeywords(false);
                setShowBrandRestricted(false);
                setShowAdRotation(false);
                setShowSiteLinks(false);
                setShowLeadForm(false);
                setShowMerchentCenter(false);
                setShowCustomerAcquisition(false);
                setShowCampaignUrl(true);
                setShowVideoEnhancement(false);
                setShowProductFeed(false);
                setShowNetworkForVideo(false);
                setshowBudgetWithType(false);
                setShowBudget(true);
                setShowLeadFormInGoals(false);
                setShowWebsiteUrlForDisplayFlow(false);
                setShowDynamicAdsForDisplayFlow(false);
                setShowConversionsForDisplayFlow(false);
                setShowBidding(false);
                setShowBiddingForDisplay(true);
                setHideOtherGoalsThanWebsite(false);
                setBiddingFocusOptions('search');
                setShowDeviceWidget(false); //Hided bcz backend team didn't get the insert option.
                setShowLocation(true);
                setShowLocalProducts(false);
                setShowCampaignSubtypeForShopping(false);
                setShowInventoryFilter(false);
                setShowCampaignPriority(false);
                setAllShowDevices(false);
                setShowsearchNetwork(false);
                setShowFeedShopping(false);
                setShowLocationGroups(false);
                setShowCampaignSubtypeForApp(false);
                setShowAppPlatformForApp(false);
                setShowDataFeedApp(false);
                setShowBiddingForApp(false);
                setShowCampaignGoalDemandGen(false);
                setShowSingleStartDateEndDate(true);
                setShowTargetCostPerAction(false);
                setShowBudgetAndDates(false);
                setShowLocationAndLanguage(false);
              }
              break;
            default:
          }
        }
        break;
      case `${googleObjectiveList?.['Local store visits and promotion']?.objective_id}`: //Local store visits and promotion
        {
          switch (`${googleCreateCampaignData?.campaign_type_id}`) {
            case `${googleCampaignTypeList?.['Performance Max']?.campaign_type_id}`: //Performance Max
              {
                setShowAutomaticallyCreatedAssets(false);
                setShowLanguages(true);
                setShowNetworks(false);
                setShowCustomerAcquisition(false);
                setShowWaysToReachGoal(false);
                setShowMerchentCenter(true);
                setShowAudienceSegments(false);
                setShowBroadMatchKeywords(false);
                setShowBrandRestricted(false);
                setShowAdRotation(false);
                setShowSiteLinks(false);
                setShowLeadForm(false);
                setShowCampaignUrl(true);
                setShowVideoEnhancement(false);
                setShowProductFeed(false);
                setShowNetworkForVideo(false);
                setshowBudgetWithType(false);
                setShowBudget(true);
                setShowLeadFormInGoals(false);
                setShowWebsiteUrlForDisplayFlow(false);
                setShowDynamicAdsForDisplayFlow(false);
                setShowConversionsForDisplayFlow(false);
                setShowBidding(true);
                setShowBiddingForDisplay(false);
                setShowCampaignGoalDemandGen(false);
                setShowTargetCostPerAction(false);
                setShowBudgetAndDates(false);
                setShowLocationAndLanguage(false);
                setShowDeviceWidget(false);
                setBiddingFocusOptions('performance');
                setShowLocationGroups(false);
                setShowCampaignSubtypeForApp(false);
                setShowAppPlatformForApp(false);
                setShowDataFeedApp(false);
                setShowBiddingForApp(false);
                setShowSingleStartDateEndDate(true);
                setShowLocation(true);
              }
              break;
            default:
          }
        }
        break;
      case `${googleObjectiveList?.['Product and brand consideration']?.objective_id}`: //Product and brand consideration
        {
          switch (`${googleCreateCampaignData?.campaign_type_id}`) {
            case `${googleCampaignTypeList?.['Demand Gen']?.campaign_type_id}`: //Demand Gen
              {
                setShowProductFeed(true);
                setShowCampaignGoalDemandGen(false);
                setShowTargetCostPerAction(true);
                setShowBudgetAndDates(true);
                setShowLocationAndLanguage(true);
                setShowProductFeed(true);
                setShowDeviceWidget(false); //Hided bcz backend team didn't get the insert option.
                setShowWaysToReachGoal(false);
                setShowBidding(false);
                setShowBiddingForDisplay(false);
                setShowBudget(false);
                setShowMerchentCenter(false);
                setShowWaysToReachGoal(false);
                setShowLanguages(false);
                setShowAudienceSegments(false);
                setShowAdRotation(false);
                setShowSingleStartDateEndDate(false);
                setHideOtherGoalsThanWebsite(false);
                setBiddingFocusOptions('search');
                setShowWebsiteUrlForDisplayFlow(false);
                setShowDynamicAdsForDisplayFlow(false);
                setShowConversionsForDisplayFlow(false);
                setShowCustomerAcquisition(false);
                setShowSiteLinks(false);
                setShowBroadMatchKeywords(false);
                setShowAutomaticallyCreatedAssets(false);
                setShowLeadForm(false);
                setShowBrandRestricted(false);
                setShowNetworks(false);
                setShowCampaignUrl(true);
                setshowBudgetWithType(false);
                setShowVideoEnhancement(false);
                setShowNetworkForVideo(false);
                setShowLocalProducts(false);
                setShowCampaignSubtypeForShopping(false);
                setShowInventoryFilter(false);
                setShowCampaignPriority(false);
                setAllShowDevices(false);
                setShowsearchNetwork(false);
                setShowFeedShopping(false);
                setShowLocationGroups(false);
                setShowCampaignSubtypeForApp(false);
                setShowAppPlatformForApp(false);
                setShowDataFeedApp(false);
                setShowBiddingForApp(false);
                setShowLocation(false);
              }
              break;
            default:
          }
        }
        break;
      case `${googleObjectiveList?.['App promotion']?.objective_id}`: //App promotion
        {
          switch (`${googleCreateCampaignData?.campaign_type_id}`) {
            case `${googleCampaignTypeList?.['App']?.campaign_type_id}`: //App
              {
                setShowProductFeed(false);
                setShowCampaignGoalDemandGen(false);
                setShowTargetCostPerAction(false);
                setShowBudgetAndDates(false);
                setShowLocationAndLanguage(false);
                setShowProductFeed(false);
                setShowDeviceWidget(false);
                setShowWaysToReachGoal(false);
                setShowBidding(false);
                setShowBiddingForDisplay(false);
                setShowBudget(true);
                setShowMerchentCenter(false);
                setShowWaysToReachGoal(false);
                setShowLanguages(true);
                setShowAudienceSegments(false);
                setShowAdRotation(false);
                setShowSingleStartDateEndDate(true);
                setHideOtherGoalsThanWebsite(false);
                setBiddingFocusOptions('search');
                setShowWebsiteUrlForDisplayFlow(false);
                setShowDynamicAdsForDisplayFlow(false);
                setShowConversionsForDisplayFlow(false);
                setShowCustomerAcquisition(false);
                setShowSiteLinks(false);
                setShowBroadMatchKeywords(false);
                setShowAutomaticallyCreatedAssets(false);
                setShowLeadForm(false);
                setShowBrandRestricted(false);
                setShowNetworks(false);
                setShowCampaignUrl(false);
                setshowBudgetWithType(false);
                setShowVideoEnhancement(false);
                setShowNetworkForVideo(false);
                setShowLocalProducts(false);
                setShowCampaignSubtypeForShopping(false);
                setShowInventoryFilter(false);
                setShowCampaignPriority(false);
                setAllShowDevices(false);
                setShowsearchNetwork(false);
                setShowFeedShopping(false);
                setShowLocationGroups(false);
                setShowAdSchedule(false);
                setShowCampaignSubtypeForApp(true);
                setShowBiddingForApp(true);
                setShowLocation(true);
                if (campSubTypeApp == 'App_Installs') {
                  setShowAppPlatformForApp(true);
                  setShowDataFeedApp(false);
                } else if (campSubTypeApp == 'App_Engagement') {
                  setShowAppPlatformForApp(true);
                  setShowDataFeedApp(false);
                } else if (campSubTypeApp == 'App_Pre_Registration') {
                  setShowAppPlatformForApp(true);
                  setShowDataFeedApp(false);
                }
              }
              break;
            default:
          }
        }
        break;
      default:
    }
  });

  useEffect(() => {
    // dispatch(resetForm());
    dispatch(setCampaignList());
    dispatch(getMasterApi());
  }, []);

  useEffect(() => {
    if (googleCreateCampaignData?.taxonomy_campaign_id) {
      dispatch(
        setTrackingTemplatesList({
          label: 'test',
          value: googleCreateCampaignData?.taxonomy_campaign_id,
        }),
      );
    }
  }, [googleCreateCampaignData?.taxonomy_campaign_id]);

  useEffect(() => {
    if (params['id']) {
      dispatch(getMasterApi());
      dispatch(getCampaignDataById(params['id']));
      setDisableObjective(true);
      setDisableCampaignType(true);
      localStorage.setItem(localStorageKey, 'false');
    }
  }, [params['id']]);
  useEffect(() => {
    setCampSubTypeShopping(googleCreateCampaignData?.campaignSubType);
  }, [googleCreateCampaignData?.campaignSubType]);

  useEffect(() => {
    if (googleCreateCampaignData?.devicesModelState?.length > 0) {
      googleCreateCampaignData?.devicesModelState?.map((val, index) => {
        if (typeof val?.operatingSystemName != 'undefined') {
          let obj = {
            label: val?.selectedDeviceModel,
            value: val?.selectedDeviceModel,
          };
          let secondObj = {
            label: val?.manufacturerName,
            value: val?.manufacturerName,
          };
          let thirdObj = {
            label: val?.name,
            value: val?.googleSalesDevicesId,
          };
          handleSelectDeviceModel(obj, index);
          handleFinalDevice(secondObj, index);
          handleSelectedDeviceModelFinalOptions(thirdObj, index);
        }
      });
    }
  }, [googleCreateCampaignData]);

  useEffect(() => {
    if (currentUrl.includes(GoogleAdOptions.Campaigns.newGoogleCampaign)) {
      dispatch(resetForm());
      localStorage.setItem(localStorageKey, 'true');
    }
  }, [currentUrl]);

  const handleCloseAndRedirectToEdit = (value) => {
    setIsDraft(false);
    navigate(`${GoogleAdOptions?.Campaigns.editUrl}/${campaignId}`);
    // showModal(false)
  };

  // Approval functionality start
  const handleApprovalPublisher = () => {
    if (getPublisherInfo?.length == 0) {
      setPublisherError('Please Choose atleast one option');
    } else {
      setApprovalCoinfirmationPopupShowStatus(true);
      setApprovalPublisherModelShowStatus(false);
      setPublisherError('');
      setIsDraft(false);
    }
  };

  const handleApprovalPublisherRequest = async () => {
    try {
      let payload = {
        platform: 'GOOGLE',
        userId: getUserInfo?.Userid,
        publisherId: getPublisherInfo?.value,
        campaignId: id,
        link: `${window.location.origin}/google-ad/campaignsView/${id}`,
      };
      // console.log('API', payload);
      setLoader(true);
      setApprovalCoinfirmationPopupShowStatus(false);
      let response = await postApi(
        `${baseUrlStg}/api/CampaignApproval/SendToPublisherApproval`,
        payload,
      );

      if (response?.status) {
        if (isPublisher) {
          setLoader(true);
          setApprovalCoinfirmationPopupShowStatus(false);
          let campaignId = id;
          handleAPICall('GOOGLE', campaignId, getPublisherInfo?.value).then(
            (res) => {
              if (res == 200) {
                setThankYouApprovalPopupShowStatus(true);
              }
            },
          );
        } else {
          setThankYouApprovalPopupShowStatus(true);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleSetPublisherId = (e) => {
    if (e == null) {
      setPublisherInfo([]);
      setIsPublisher(false);
    } else {
      setPublisherInfo(e);
      e?.value == getUserInfo?.Userid && setIsPublisher(true);
    }
  };

  const handleCloseModel = () => {
    setApprovalPublisherModelShowStatus(false);
    setLoader(false);
    setApprovalCoinfirmationPopupShowStatus(false);
    setPublisherInfo([]);
    setShowModal(false);
    setIsPublisher(false);
  };

  // check if the performance max the set the final Url is from getMaster of the selected Merchant Account

  // useEffect(() => {
  //   console.log('store', googleCreateCampaignData);
  // }, [googleCreateCampaignData]);

  useEffect(() => {
    if (googleCreateCampaignData?.Merchent_center_account) {
      const selectedMerchant =
        googleCreateCampaignData?.Merchent_center_account;
      const selectedObject =
        googleCreateCampaignData?.google_Campaignsettings?.merchentCenterAccountList?.find(
          (item) => item.value == selectedMerchant,
        );
      dispatch(
        setCampaignURLOnPerformance('suffix', selectedObject?.urlFinal || ''),
      );
    }
  }, [
    googleCreateCampaignData?.Merchent_center_account,
    googleCreateCampaignData?.google_Campaignsettings
      ?.merchentCenterAccountList,
  ]);

  // Approval functionality end
  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box padding-bottom-extra media-section-left'>
            {/* header code starts */}
            <header>
              <div className='top-header-bar top-header-secondary grey-top-bar top-nav-bedcrumb activetop'>
                <div className='top-right-bar'>
                  <div className='btn-box'>
                    <button
                      type='button'
                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                      onClick={handleNext}
                    >
                      {saveAsDraft}
                    </button>
                    <button
                      type='button'
                      className='primary-btn close-btn'
                      onClick={() => {
                        navigate('/google-ad/campaign-listing');
                      }}
                    >
                      <i className='icon-close'></i>
                    </button>
                  </div>
                </div>
              </div>
            </header>
            {/* header code ends */}
            <section className='side-menu-form d-flex'>
              {/* side tree menu code  starts*/}

              <SideTreeMenuView
                campaignId={id}
                IdAndType={{ id: id, type: GoogleAdOptions.Campaigns.name }}
              />

              {/* side tree menu code ends */}
              <article className='side-right-form-wrap side-right-pad view-edit-tab-remove'>
                {/* <FormHeader /> */}
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  <div
                    className='tab-pane fade show active'
                    id='edit'
                    role='tabpanel'
                    aria-labelledby='edit-tab'
                  >
                    <div className='side-inner-form'>
                      <article className='side-right-from'>
                        {/* Campaign Name starts here */}
                        <Card>
                          <AdvancedCardHeader>
                            <div className='card-lt-pane'>
                              <h5>Campaign Name *</h5>
                            </div>
                          </AdvancedCardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item col-9'>
                                  <div className='label-icon'>
                                    <label>Campaign Name *</label>
                                  </div>
                                  {/* <SimpleSelectBox placeholder="Select"/> */}
                                  {/* <div className="select-multiple-components"> */}
                                  <ReactSelect
                                    options={
                                      googleCreateCampaignData?.googleCampaignList
                                    }
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={campaignNameHandler}
                                    closeMenuOnSelect={true}
                                    value={[
                                      {
                                        label:
                                          googleCreateCampaignData?.campaign_name,
                                        value:
                                          googleCreateCampaignData?.taxonomy_campaign_id,
                                      },
                                    ]}
                                  />

                                  <span
                                    className='error'
                                    id='error-campaign_name'
                                  >
                                    {formErrors?.campaign_name}
                                  </span>
                                  {/* <small className="select-time">0 / 10</small> */}
                                </div>
                                <div class='col-3'>
                                  <button
                                    class='btn-icon btn-icon-primary'
                                    onClick={handleRedirect}
                                  >
                                    <i class='icon-plus'></i>
                                    Create Campaign
                                  </button>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        {/* Campaign Name ends here */}
                        {/* objevtive type starts here */}
                        <Card>
                          <CardHeader>
                            <h5 className='d-flex align-items-center'>
                              Choose your objective *
                              <span
                                className='label-information tooltips tool-bottom'
                                tooltip='Cascading Style Sheets'
                                tooltip-position='top'
                              >
                                {' '}
                                {/* <i className='icon-info'></i> */}
                              </span>
                            </h5>
                            <p>
                              Select an objective to tailor your experience to
                              the goals and settings that will work best for
                              your campaign.
                            </p>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont'>
                                  <div
                                    className='action-btn-both action-btn-icon-tag row'
                                    onChange={onChangeHandler}
                                  >
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='objective_id'
                                        id='flexRadioDefault501'
                                        checked={
                                          googleObjectiveList?.Sales
                                            ?.objective_id ==
                                          googleCreateCampaignData?.objective_id
                                        }
                                        value={
                                          googleObjectiveList?.Sales
                                            ?.objective_id
                                        }
                                        disabled={disableObjective}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault501'
                                      >
                                        <i className='icon-sales'></i>
                                        <div className='label-multitext'>
                                          Sales
                                          <p>
                                            Drive sales online,in app,by phone,
                                            or in store
                                          </p>
                                        </div>
                                      </label>
                                    </div>

                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='objective_id'
                                        id='flexRadioDefault502'
                                        checked={
                                          googleObjectiveList?.Leads
                                            ?.objective_id ==
                                          googleCreateCampaignData?.objective_id
                                        }
                                        value={
                                          googleObjectiveList?.Leads
                                            ?.objective_id
                                        }
                                        disabled={disableObjective}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault502'
                                      >
                                        <i className='icon-Group-92'></i>
                                        <div className='label-multitext'>
                                          Leads
                                          <p>
                                            Get leads and other conversions by
                                            encouraging customers to take action
                                          </p>
                                        </div>
                                      </label>
                                    </div>

                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='objective_id'
                                        id='flexRadioDefault503'
                                        value={
                                          googleObjectiveList?.[
                                            'Website Traffic'
                                          ]?.objective_id
                                        }
                                        checked={
                                          googleObjectiveList?.[
                                            'Website Traffic'
                                          ]?.objective_id ==
                                          googleCreateCampaignData?.objective_id
                                        }
                                        disabled={disableObjective}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault503'
                                      >
                                        <i className='icon-traffic'></i>
                                        <div className='label-multitext'>
                                          Website traffic
                                          <p>
                                            Get the right people to visit your
                                            website
                                          </p>
                                        </div>
                                      </label>
                                    </div>

                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='objective_id'
                                        id='flexRadioDefault504'
                                        checked={
                                          googleObjectiveList?.[
                                            'Product and brand consideration'
                                          ]?.objective_id ==
                                          googleCreateCampaignData?.objective_id
                                        }
                                        value={
                                          googleObjectiveList?.[
                                            'Product and brand consideration'
                                          ]?.objective_id
                                        }
                                        disabled={disableObjective}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault504'
                                      >
                                        <i className='icon-heart'></i>
                                        <div className='label-multitext'>
                                          Product and brand consideration
                                          <p>
                                            Encourage people to explore your
                                            products or services
                                          </p>
                                        </div>
                                      </label>
                                    </div>

                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='objective_id'
                                        id='flexRadioDefault505'
                                        value={
                                          googleObjectiveList?.[
                                            'Brand awareness and reach'
                                          ]?.objective_id
                                        }
                                        checked={
                                          googleObjectiveList?.[
                                            'Brand awareness and reach'
                                          ]?.objective_id ==
                                          googleCreateCampaignData?.objective_id
                                        }
                                        disabled={disableObjective}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault505'
                                      >
                                        <i className='icon-awareness'></i>
                                        <div className='label-multitext'>
                                          Brand awareness and reach
                                          <p>
                                            Reach a broad audience and build
                                            awareness
                                          </p>
                                        </div>
                                      </label>
                                    </div>

                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='objective_id'
                                        id='flexRadioDefault506'
                                        checked={
                                          googleObjectiveList?.['App promotion']
                                            ?.objective_id ==
                                          googleCreateCampaignData?.objective_id
                                        }
                                        value={
                                          googleObjectiveList?.['App promotion']
                                            ?.objective_id
                                        }
                                        disabled={disableObjective}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault506'
                                      >
                                        <i className='icon-engagement'></i>
                                        <div className='label-multitext'>
                                          App promotion
                                          <p>
                                            Get more installs,engament and
                                            pre-registration for your app
                                          </p>
                                        </div>
                                      </label>
                                    </div>

                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='objective_id'
                                        id='flexRadioDefault507'
                                        checked={
                                          googleObjectiveList?.[
                                            'Local store visits and promotion'
                                          ]?.objective_id ==
                                          googleCreateCampaignData?.objective_id
                                        }
                                        value={
                                          googleObjectiveList?.[
                                            'Local store visits and promotion'
                                          ]?.objective_id
                                        }
                                        disabled={disableObjective}
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault507'
                                      >
                                        <i className='icon-location-pin-svgrepo-com-4'></i>
                                        <div className='label-multitext'>
                                          Local store visits and promotions
                                          <p>
                                            Drive visits to local stores,
                                            including restaurants and
                                            dealerships
                                          </p>
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        {/* objevtive type ends here */}
                        {/* campaign type starts here */}
                        <CampaignTypes
                          onChangeHandler={onChangeHandler}
                          campaign_type_id={
                            googleCreateCampaignData?.campaign_type_id
                          }
                          objective_id={googleCreateCampaignData?.objective_id}
                          disableCampaignType={disableCampaignType}
                          formErrors={formErrors}
                        ></CampaignTypes>

                        {/* campaign type ends here */}
                        {/* campain subtype starts */}

                        {showCampaignSubtypeForShopping && (
                          <>
                            <Card>
                              <div className='card-header-top d-flex'>
                                <div className='card-lt-pane'>
                                  <h5>Campaign subtype</h5>
                                </div>
                              </div>
                              <div className='card-body'>
                                <div className='card-form form-panel'>
                                  <div className='row align-items-center'>
                                    <div className='action-btn-cont'>
                                      <div
                                        className='action-btn-both action-btn-no-icon row'
                                        onChange={(e) => {
                                          campaignSubtypeHandler(e);
                                        }}
                                      >
                                        <div className='col-12 px-0'>
                                          <div className='form-check-box'>
                                            <input
                                              className='form-check-input'
                                              type='radio'
                                              name='flexRadioDefaultasdc'
                                              id='flexRadioDefault50asdc'
                                              checked={
                                                campSubTypeShopping ==
                                                'Performance_Max_Campaign'
                                              }
                                              value='Performance_Max_Campaign'
                                              disabled={params['id']}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor='flexRadioDefault50asdc'
                                            >
                                              Performance Max campaign
                                            </label>
                                          </div>
                                        </div>
                                        <div className='col-12 px-0'>
                                          <div className='form-check-box'>
                                            <input
                                              className='form-check-input'
                                              type='radio'
                                              name='flexRadioDefaultasdc'
                                              id='flexRadioDefault51asdc'
                                              value='Standard_Shopping_Campaign'
                                              checked={
                                                campSubTypeShopping ==
                                                'Standard_Shopping_Campaign'
                                              }
                                              disabled={params['id']}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor='flexRadioDefault51asdc'
                                            >
                                              Standard Shopping campaign
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          </>
                        )}
                        {/* campain subtype ends */}
                        {/* campain subtype for app starts */}
                        {showCampaignSubtypeForApp && (
                          <>
                            <Card>
                              <div className='card-header-top d-flex'>
                                <div className='card-lt-pane'>
                                  <h5>Campaign subtype</h5>
                                </div>
                              </div>
                              <div className='card-body'>
                                <div className='card-form form-panel'>
                                  <div className='row align-items-center'>
                                    <div className='action-btn-cont'>
                                      <div
                                        className='action-btn-both action-btn-no-icon row'
                                        onChange={(e) => {
                                          campaignSubtypeAppHandler(e);
                                        }}
                                      >
                                        <div className='col-12 px-0'>
                                          <div className='form-check-box'>
                                            <input
                                              className='form-check-input'
                                              type='radio'
                                              name='flexRadioDefaultasdc'
                                              id='flexRadioDefault50asdc'
                                              // defaultChecked={campSubTypeApp == "App_Installs"}
                                              value='App_Installs'
                                              checked={
                                                googleCreateCampaignData?.campaignSubType ==
                                                'App_Installs'
                                              }
                                              disabled={params['id']}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor='flexRadioDefault50asdc'
                                            >
                                              App installs
                                              <p>
                                                Get new people to install your
                                                app
                                              </p>
                                            </label>
                                          </div>
                                        </div>
                                        <div className='col-12 px-0'>
                                          <div className='form-check-box'>
                                            <input
                                              className='form-check-input'
                                              type='radio'
                                              name='flexRadioDefaultasdc'
                                              id='flexRadioDefault51asdc'
                                              checked={
                                                googleCreateCampaignData?.campaignSubType ==
                                                'App_Engagement'
                                              }
                                              value='App_Engagement'
                                              disabled={params['id']}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor='flexRadioDefault51asdc'
                                            >
                                              App engagement
                                              <p>
                                                Get existing users to take
                                                actions in your app (Minimum 50K
                                                installs required)
                                              </p>
                                            </label>
                                          </div>
                                        </div>
                                        <div className='col-12 px-0'>
                                          <div className='form-check-box'>
                                            <input
                                              className='form-check-input'
                                              type='radio'
                                              name='flexRadioDefaultasdc'
                                              id='flexRadioDefault52asdc'
                                              checked={
                                                googleCreateCampaignData?.campaignSubType ==
                                                'App_Pre_Registration'
                                              }
                                              value='App_Pre_Registration'
                                              disabled={params['id']}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor='flexRadioDefault52asdc'
                                            >
                                              App pre-registration (Android
                                              only)
                                              <p>
                                                Get new users to pre-register
                                                for your app before launch
                                              </p>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          </>
                        )}
                        {/* campain subtype for app ends */}
                        {/* Select the ways you'd like to reach your goal Start Here */}
                        {showAppPlatformForApp && (
                          <>
                            <div className='card-primary-repet'>
                              <div className='card-primary'>
                                <div className='card-header-top d-flex'>
                                  <div className='card-lt-pane'>
                                    <h5>Select your mobile app's platform</h5>
                                  </div>
                                </div>
                                <div className='card-body'>
                                  <div className='card-form form-panel'>
                                    <div className='row align-items-center'>
                                      <div className='action-btn-cont'>
                                        <div className='action-btn-both action-btn-no-icon row'>
                                          <div className='col-12 px-0'>
                                            <div className='dv-from-check-field'>
                                              <div className='action-btn-cont'>
                                                <div
                                                  className='action-btn-both action-btn-no-icon row'
                                                  onChange={(e) => {
                                                    appTypeHandlerForApp(e);
                                                  }}
                                                >
                                                  <div className='col-12 px-0'>
                                                    <div className='form-check-box'>
                                                      <input
                                                        className='form-check-input'
                                                        type='radio'
                                                        name='flexRadioDefaultApp'
                                                        id='flexRadioDefault50'
                                                        defaultChecked={
                                                          googleCreateCampaignData
                                                            ?.google_Goalinfo
                                                            ?.mobileAppPlatform ==
                                                          'android'
                                                        }
                                                        value={'android'}
                                                        disabled={params['id']}
                                                      />
                                                      <label
                                                        className='form-check-label'
                                                        htmlFor='flexRadioDefault50'
                                                      >
                                                        Android
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div className='col-12 px-0'>
                                                    <div className='form-check-box'>
                                                      <input
                                                        className='form-check-input'
                                                        type='radio'
                                                        name='flexRadioDefaultApp'
                                                        id='flexRadioDefault51'
                                                        value={'ios'}
                                                        disabled={
                                                          params['id'] ||
                                                          campSubTypeApp ==
                                                            'App_Pre_Registration'
                                                        }
                                                        defaultChecked={
                                                          googleCreateCampaignData
                                                            ?.google_Goalinfo
                                                            ?.mobileAppPlatform ==
                                                          'ios'
                                                        }
                                                      />
                                                      <label
                                                        className='form-check-label'
                                                        htmlFor='flexRadioDefault51'
                                                      >
                                                        IOS
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <CardHeader>
                                                <div class='card-lt-pane'>
                                                  <h6>Look up your app</h6>
                                                </div>

                                                <div className='form-item'>
                                                  <div className='label-icon'>
                                                    <label>App</label>
                                                  </div>
                                                  {/* <div className="select-multiple-components"> */}
                                                  <ReactSelect
                                                    options={
                                                      googleCreateCampaignData?.appList
                                                    }
                                                    onChange={(e) =>
                                                      appHandler(e)
                                                    }
                                                    closeMenuOnSelect={true}
                                                    value={googleCreateCampaignData?.appList?.find(
                                                      (item) =>
                                                        item?.value ==
                                                        googleCreateCampaignData
                                                          ?.google_Goalinfo
                                                          ?.lookupApp,
                                                    )}
                                                  />
                                                  {/* </div> */}
                                                </div>
                                              </CardHeader>
                                              <CardHeader>
                                                <div class='card-lt-pane'>
                                                  <h6>Conversion tracking</h6>
                                                </div>

                                                <div className='form-item'>
                                                  <div className='label-icon'>
                                                    <label>
                                                      Conversion tracking
                                                    </label>
                                                  </div>
                                                  {/* <div className="select-multiple-components"> */}
                                                  <ReactSelect
                                                    options={[
                                                      {
                                                        label:
                                                          'customers/8665555113/conversionActions/6726273528',
                                                        value:
                                                          'customers/8665555113/conversionActions/6726273528',
                                                      },
                                                    ]}
                                                    onChange={
                                                      conversionTrackingHandler
                                                    }
                                                    closeMenuOnSelect={true}
                                                    value={[
                                                      {
                                                        label:
                                                          'customers/8665555113/conversionActions/6726273528',
                                                        value:
                                                          'customers/8665555113/conversionActions/6726273528',
                                                      },
                                                    ]}
                                                  />
                                                  {/* </div> */}
                                                </div>
                                              </CardHeader>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {/* Select the ways you'd like to reach your goal Ends Here */}
                        {/* inventory filter starts */}
                        {googleCreateCampaignData.objective_id &&
                          googleCreateCampaignData?.campaign_type_id && (
                            <>
                              {showInventoryFilter && (
                                <>
                                  <Card>
                                    <div className='card-header-top d-flex'>
                                      <div className='card-lt-pane'>
                                        <h5>Inventory Filter</h5>
                                      </div>
                                    </div>
                                    <div className='card-body'>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='action-btn-cont'>
                                            <div
                                              className='action-btn-both action-btn-no-icon row'
                                              onChange={(e) => {
                                                inventoryFilterHandler(e);
                                              }}
                                            >
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefaultFilter'
                                                    id='flexRadioDefault50zsx'
                                                    defaultChecked=''
                                                    value='no_filter'
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault50zsx'
                                                  >
                                                    No filter: Advertise all
                                                    products in the country of
                                                    sale (recommended)
                                                    {/* <p>Jul 18, 2023 - Aug 18, 2023</p> */}
                                                  </label>
                                                </div>
                                              </div>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefaultFilter'
                                                    id='flexRadioDefault51zsx'
                                                    value='filter'
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault51zsx'
                                                  >
                                                    Filter: Advertise only
                                                    products that match all of
                                                    your requirements
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                </>
                              )}
                              {/* inventory filter ends */}
                              {/* Local products starts */}
                              {showLocalProducts && (
                                <>
                                  <Card>
                                    <div className='card-header-top d-flex'>
                                      <div className='card-lt-pane'>
                                        <h5>Local products</h5>
                                      </div>
                                    </div>
                                    <div className='card-body'>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='action-btn-cont'>
                                            <div
                                              className='action-btn-both action-btn-no-icon row'
                                              onChange={(e) => {}}
                                            >
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    name='flexRadioDefaultLocal'
                                                    id='flexRadioDefault50Local'
                                                    defaultChecked=''
                                                    value='Performance_Max_Campaign'
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault50Local'
                                                  >
                                                    Turn on ads for products
                                                    sold in local stores
                                                    {/* <p>Jul 18, 2023 - Aug 18, 2023</p> */}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                </>
                              )}
                              {/* Local products ends */}
                              {/* Campaign priority Start Here */}
                              {showCampaignPriority && (
                                <>
                                  <div className='card-primary-repet'>
                                    <div className='card-primary'>
                                      <div className='card-header-top d-flex'>
                                        <div className='card-lt-pane'>
                                          <h5>Campaign priority</h5>
                                        </div>
                                      </div>
                                      <div className='card-body'>
                                        <div className='card-form form-panel'>
                                          <div className='row align-items-center'>
                                            <div className='action-btn-cont'>
                                              <div className='action-btn-both action-btn-no-icon row'>
                                                <div className='col-12 px-0'>
                                                  <div className='form-check-box'>
                                                    <input
                                                      className='form-check-input'
                                                      type='radio'
                                                      name='flexRadioDefault'
                                                      id='flexRadioDefault50priority'
                                                      onChange={(e) => {
                                                        dispatch(
                                                          setCampaignPriority(
                                                            'Low',
                                                          ),
                                                        );
                                                      }}
                                                      checked={
                                                        googleCreateCampaignData?.campaign_priority ==
                                                        'Low'
                                                      }
                                                    />
                                                    <label
                                                      className='form-check-label'
                                                      htmlFor='flexRadioDefault50priority'
                                                    >
                                                      Low (default) –
                                                      Recommended if you only
                                                      have one Shopping campaign
                                                      {/* <p>Jul 18, 2023 - Aug 18, 2023</p> */}
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className='col-12 px-0'>
                                                  <div className='form-check-box'>
                                                    <input
                                                      className='form-check-input'
                                                      type='radio'
                                                      name='flexRadioDefault'
                                                      id='flexRadioDefault52priority'
                                                      onChange={(e) => {
                                                        dispatch(
                                                          setCampaignPriority(
                                                            'Medium',
                                                          ),
                                                        );
                                                      }}
                                                      checked={
                                                        googleCreateCampaignData?.campaign_priority ==
                                                        'Medium'
                                                      }
                                                    />
                                                    <label
                                                      className='form-check-label'
                                                      htmlFor='flexRadioDefault52priority'
                                                    >
                                                      Medium
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className='col-12 px-0'>
                                                  <div className='form-check-box'>
                                                    <input
                                                      className='form-check-input'
                                                      type='radio'
                                                      name='flexRadioDefault'
                                                      id='flexRadioDefault51priority'
                                                      onChange={(e) => {
                                                        dispatch(
                                                          setCampaignPriority(
                                                            'High',
                                                          ),
                                                        );
                                                      }}
                                                      checked={
                                                        googleCreateCampaignData?.campaign_priority ==
                                                        'High'
                                                      }
                                                    />
                                                    <label
                                                      className='form-check-label'
                                                      htmlFor='flexRadioDefault51priority'
                                                    >
                                                      High
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {/* Campaign priority Ends Here */}
                              {/* devices starts */}
                              {showAllDevices && (
                                <Card>
                                  <CardHeader>
                                    <h5>Devices</h5>
                                    <p>
                                      Ads will show on all eligible devices by
                                      default
                                    </p>
                                  </CardHeader>
                                </Card>
                              )}

                              {/* devices ends */}
                              {/* websiteurl for display flow starts here */}
                              {showWebsiteUrlForDisplayFlow && (
                                <>
                                  <Card>
                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='form-item col-12'>
                                            <div className='label-icon'>
                                              <label>
                                                Your business's website (This is
                                                the web page people will go to
                                                after clicking your ad )
                                              </label>
                                            </div>
                                            <div className='input-group'>
                                              <input
                                                type='text'
                                                name='Ad group'
                                                value={
                                                  googleCreateCampaignData?.websiteUrlForDisplay
                                                }
                                                id='text'
                                                placeholder='Enter the Url'
                                                onChange={
                                                  handleWebsiteUrlForDisplay
                                                }
                                              />
                                            </div>
                                            <span
                                              className='error'
                                              id='error-websiteVisitsDisplay'
                                            >
                                              {formErrors?.websiteVisitsDisplay}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </>
                              )}
                              {/* websiteurl for display flow ends here */}
                              {/* Dynamic ads for display flow starts here */}
                              {showDynamicAdsForDisplayFlow && (
                                <>
                                  <div className='card-primary-repet'>
                                    <div className='card-primary'>
                                      <div className='card-header-top'>
                                        <div className='card-lt-pane'>
                                          <h5>Dynamic ads</h5>
                                          {/* <p>Define who you want to see your ads.</p> */}
                                          <div className='action-btn-both action-btn-both-check row'>
                                            <div className='form-check-box'>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                id='inlineCheckbox1'
                                                defaultValue='option1'
                                              />
                                              <label
                                                className='form-check-label'
                                                htmlFor='inlineCheckbox1'
                                              >
                                                Use dynamic ads feed for
                                                personalized ads
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='card-body'>
                                        <div className='card-form form-panel'>
                                          <div className='row align-items-center'>
                                            <div className='form-item col-12'>
                                              <div className='label-icon'>
                                                <label>Data feed</label>
                                              </div>
                                              <ReactSelect
                                                options={
                                                  googleCreateCampaignData
                                                    ?.google_Campaignsettings
                                                    ?.merchentCenterAccountList
                                                }
                                                isMulti={false}
                                                isSearchable={true}
                                                // onChange={campaignNameHandler}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {/* Dynamic ads for display flow ends here */}
                              {/* conversions for display flow starts */}
                              {showConversionsForDisplayFlow && (
                                <>
                                  <div className='card-primary-repet'>
                                    <div className='card-primary'>
                                      <div className='card-header-top'>
                                        <div className='card-lt-pane'>
                                          <h5>Conversions</h5>
                                          <p>
                                            Only available for manual vCPM and
                                            Target CPA bid strategies that are
                                            paying for viewable impressions
                                          </p>
                                          <div className='action-btn-both action-btn-both-check row'>
                                            <div className='form-check-box'>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                id='inlineCheckbox1vf'
                                                defaultValue='option1'
                                                disabled={true}
                                              />
                                              <label
                                                className='form-check-label'
                                                htmlFor='inlineCheckbox1vf'
                                              >
                                                Include view-through conversions
                                                in your "Conversions" and "All
                                                conversions" columns
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {/* conversions for display flow ends */}
                              {/* Select the ways you'd like to reach your goal starts here */}
                              {showWaysToReachGoal && (
                                <Card>
                                  <AdvancedCardHeader>
                                    <div className='card-lt-pane'>
                                      <h5>
                                        Select the ways you'd like to reach your
                                        goal
                                      </h5>
                                    </div>
                                  </AdvancedCardHeader>
                                  <CardBody>
                                    <div className='card-form form-panel'>
                                      <div className='row align-items-center'>
                                        <div className='action-btn-cont'>
                                          <div className='action-btn-both action-btn-no-icon row'>
                                            <div className='col-12 px-0'>
                                              <div className='form-check-box'>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  name='isWebsiteVisit'
                                                  id='flexRadioDefault560'
                                                  checked={
                                                    googleCreateCampaignData
                                                      ?.google_Goalinfo
                                                      ?.isWebsiteVisit
                                                  }
                                                  onChange={(e) => {
                                                    websiteVisitsHandler(e);
                                                  }}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor='flexRadioDefault560'
                                                >
                                                  Website visits
                                                </label>
                                              </div>
                                              {googleCreateCampaignData
                                                ?.google_Goalinfo
                                                ?.isWebsiteVisit ? (
                                                <div className='dv-from-check-field'>
                                                  <div className='form-item'>
                                                    <div className='label-icon'>
                                                      <label>
                                                        Your business website
                                                      </label>
                                                    </div>
                                                    <div className='input-group'>
                                                      <input
                                                        type='text'
                                                        id='text'
                                                        name='Website Visits'
                                                        placeholder='Type here'
                                                        value={
                                                          googleCreateCampaignData
                                                            ?.google_Goalinfo
                                                            ?.websiteUrl
                                                        }
                                                        onChange={
                                                          websiteVisitsHandler
                                                        }
                                                      />{' '}
                                                    </div>
                                                    {formErrors?.websiteVisits && (
                                                      <span
                                                        className='error ps-0'
                                                        id='error-websiteVisits'
                                                      >
                                                        {
                                                          formErrors?.websiteVisits
                                                        }
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              ) : null}
                                            </div>
                                            {!hideOtherGoalsThanWebsite && (
                                              <>
                                                <div className='col-12 px-0'>
                                                  <div className='form-check-box'>
                                                    <input
                                                      className='form-check-input'
                                                      type='checkbox'
                                                      name='isPhoneCalls'
                                                      checked={
                                                        googleCreateCampaignData
                                                          ?.google_Goalinfo
                                                          ?.isPhoneCalls
                                                      }
                                                      id='flexRadioDefault51'
                                                      onChange={(e) => {
                                                        phoneCallsHandler(e);
                                                      }}
                                                    />
                                                    <label
                                                      className='form-check-label'
                                                      htmlFor='flexRadioDefault51'
                                                    >
                                                      Phone calls
                                                    </label>
                                                  </div>
                                                  {googleCreateCampaignData
                                                    ?.google_Goalinfo
                                                    ?.isPhoneCalls ? (
                                                    <div className='dv-from-check-field'>
                                                      <div className='dv-check-box'>
                                                        <div className='row align-items-center'>
                                                          <div className='form-item col-6'>
                                                            <div className='label-icon'>
                                                              <label>
                                                                Country
                                                              </label>
                                                            </div>
                                                            <ReactSelect
                                                              placeholder='India'
                                                              isDisabled='true'
                                                            />
                                                          </div>
                                                          <div className='form-item col-6'>
                                                            <div className='label-icon'>
                                                              <label>
                                                                Phone Number
                                                              </label>
                                                            </div>
                                                            <div className='input-group'>
                                                              <input
                                                                type='number'
                                                                id='email'
                                                                name='Phone Calls'
                                                                value={
                                                                  googleCreateCampaignData
                                                                    ?.google_Goalinfo
                                                                    ?.phoneNumber
                                                                }
                                                                placeholder='Type Phone Number'
                                                                onChange={
                                                                  phoneCallsHandler
                                                                }
                                                                min='0'
                                                                onFocus={
                                                                  handleFocus
                                                                }
                                                              />
                                                            </div>
                                                            {formErrors?.phoneCalls && (
                                                              <span
                                                                className='error'
                                                                id='error-phoneCalls'
                                                              >
                                                                {
                                                                  formErrors?.phoneCalls
                                                                }
                                                              </span>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                                <div className='col-12 px-0'>
                                                  <div className='form-check-box'>
                                                    <input
                                                      className='form-check-input'
                                                      type='checkbox'
                                                      name='isStoreVisits'
                                                      id='flexRadioDefault50'
                                                      checked={
                                                        googleCreateCampaignData
                                                          ?.google_Goalinfo
                                                          ?.isStoreVisits
                                                      }
                                                      onChange={(e) => {
                                                        storeVisitsHandler(e);
                                                      }}
                                                    />
                                                    <label
                                                      className='form-check-label'
                                                      htmlFor='flexRadioDefault50'
                                                    >
                                                      Store visits
                                                      {googleCreateCampaignData
                                                        ?.google_Goalinfo
                                                        ?.isStoreVisits ? (
                                                        <p>
                                                          Enter location on the
                                                          next step
                                                        </p>
                                                      ) : null}
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className='col-12 px-0'>
                                                  <div className='form-check-box'>
                                                    <input
                                                      className='form-check-input'
                                                      type='checkbox'
                                                      name='isAppDownloads'
                                                      id='flexRadioDefault50q'
                                                      checked={
                                                        googleCreateCampaignData
                                                          ?.google_Goalinfo
                                                          ?.isAppDownloads
                                                      }
                                                      onChange={(e) => {
                                                        appTypeHandler(e);
                                                      }}
                                                    />
                                                    <label
                                                      className='form-check-label'
                                                      htmlFor='flexRadioDefault50q'
                                                    >
                                                      App downloads
                                                      <p>
                                                        Select your mobile app's
                                                        platform
                                                      </p>
                                                    </label>
                                                  </div>
                                                  {googleCreateCampaignData
                                                    ?.google_Goalinfo
                                                    ?.isAppDownloads ? (
                                                    <div className='dv-from-check-field'>
                                                      <div className='action-btn-cont'>
                                                        <div
                                                          className='action-btn-both action-btn-no-icon row'
                                                          onChange={(e) => {
                                                            appTypeHandler(e);
                                                          }}
                                                        >
                                                          <div className='col-12 px-0'>
                                                            <div className='form-check-box'>
                                                              <input
                                                                className='form-check-input'
                                                                type='radio'
                                                                name='App downloads'
                                                                id='flexRadioDefault580'
                                                                checked={
                                                                  googleCreateCampaignData
                                                                    ?.google_Goalinfo
                                                                    ?.mobileAppPlatform ==
                                                                  'android'
                                                                }
                                                                value={
                                                                  'android'
                                                                }
                                                              />
                                                              <label
                                                                className='form-check-label'
                                                                htmlFor='flexRadioDefault580'
                                                              >
                                                                Android
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <div className='col-12 px-0'>
                                                            <div className='form-check-box'>
                                                              <input
                                                                className='form-check-input'
                                                                type='radio'
                                                                checked={
                                                                  googleCreateCampaignData
                                                                    ?.google_Goalinfo
                                                                    ?.mobileAppPlatform ==
                                                                  'ios'
                                                                }
                                                                name='App downloads'
                                                                id='flexRadioDefault581'
                                                                value={'ios'}
                                                              />
                                                              <label
                                                                className='form-check-label'
                                                                htmlFor='flexRadioDefault581'
                                                              >
                                                                IOS
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <CardHeader>
                                                        <div class='card-lt-pane'>
                                                          <h6>
                                                            Look up your app
                                                          </h6>
                                                        </div>

                                                        <div className='form-item'>
                                                          <div className='label-icon'>
                                                            <label>
                                                              Your App
                                                            </label>
                                                          </div>

                                                          {googleCreateCampaignData
                                                            ?.google_Goalinfo
                                                            ?.mobileAppPlatform ==
                                                          'android' ? (
                                                            <ReactSelect
                                                              isSearchable={
                                                                true
                                                              }
                                                              options={
                                                                googleCreateCampaignData?.appList
                                                              }
                                                              // defaultValue={[
                                                              //   {
                                                              //     value:
                                                              //       googleCreateCampaignData
                                                              //         ?.google_Goalinfo
                                                              //         ?.lookupApp,
                                                              //     label:
                                                              //       googleCreateCampaignData
                                                              //         ?.google_Goalinfo
                                                              //         ?.lookupApp,
                                                              //   },
                                                              // ]}
                                                              onChange={(e) => {
                                                                appNameHandler(
                                                                  e,
                                                                );
                                                              }}
                                                            />
                                                          ) : (
                                                            <ReactSelect
                                                              isSearchable={
                                                                true
                                                              }
                                                              options={
                                                                googleCreateCampaignData?.appList
                                                              }
                                                              // defaultValue={[]}
                                                              defaultValue={[
                                                                {
                                                                  value:
                                                                    googleCreateCampaignData
                                                                      ?.google_Goalinfo
                                                                      ?.lookupApp,
                                                                  label:
                                                                    googleCreateCampaignData
                                                                      ?.google_Goalinfo
                                                                      ?.lookupApp,
                                                                },
                                                              ]}
                                                              onChange={(e) => {
                                                                appNameHandler(
                                                                  e,
                                                                );
                                                              }}
                                                            />
                                                          )}
                                                        </div>
                                                      </CardHeader>
                                                    </div>
                                                  ) : null}
                                                </div>
                                                {showLeadFormInGoals && (
                                                  <div className='col-12 px-0'>
                                                    <div className='form-check-box'>
                                                      <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        name='isLeadFormSubmission'
                                                        id='flexRadioDefault50o'
                                                        defaultChecked={
                                                          googleCreateCampaignData
                                                            ?.google_Goalinfo
                                                            ?.isLeadFormSubmission
                                                        }
                                                        onChange={(e) => {
                                                          storeVisitsHandler(e);
                                                        }}
                                                      />
                                                      <label
                                                        className='form-check-label'
                                                        htmlFor='flexRadioDefault50'
                                                      >
                                                        Lead form submissions
                                                        {googleCreateCampaignData
                                                          ?.google_Goalinfo
                                                          ?.isLeadFormSubmission ? (
                                                          <p>
                                                            Add lead form on the
                                                            next step
                                                          </p>
                                                        ) : null}
                                                      </label>
                                                    </div>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              )}
                              {/* Select the ways you'd like to reach your goal ends here */}
                              {/* Location group starts */}
                              {showLocationGroups && (
                                <>
                                  <LocationGroups
                                    locationHandler={locationHandler}
                                    locationType={
                                      googleCreateCampaignData?.campaignLocationType
                                    }
                                  ></LocationGroups>
                                </>
                              )}

                              {/* Location group ends */}
                              {/* Merchent Center account starts here */}
                              {showMerchentCenter && (
                                <Card>
                                  <AdvancedCardHeader>
                                    <div className='card-lt-pane'>
                                      <h5>Merchent Center account</h5>
                                    </div>
                                  </AdvancedCardHeader>
                                  <CardBody>
                                    <div className='card-form form-panel'>
                                      <div className='row align-items-center'>
                                        <div className='form-item col-12'>
                                          <div className='label-icon'>
                                            <label>
                                              Merchent Center account
                                            </label>
                                          </div>
                                          <ReactSelect
                                            options={
                                              googleCreateCampaignData
                                                ?.google_Campaignsettings
                                                ?.merchentCenterAccountList
                                            }
                                            isMulti={false}
                                            isSearchable={true}
                                            onChange={
                                              merchantCenterAccountHandler
                                            }
                                            closeMenuOnSelect={true}
                                            value={googleCreateCampaignData?.google_Campaignsettings?.merchentCenterAccountList.find(
                                              (item) =>
                                                item.value ==
                                                googleCreateCampaignData?.Merchent_center_account,
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              )}
                              {/* Merchent Center account ends here */}
                              {/* feed starts here */}
                              {showFeedShopping && (
                                <>
                                  <Card>
                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='form-item col-12'>
                                            <div className='label-icon'>
                                              <label>feed label:</label>
                                            </div>
                                            {/* <div className='input-group'>
                                        <input
                                          type='text'
                                          name='Ad group'
                                          // value={adGroupData?.GoogleAdGroupName}
                                          id='text'
                                          placeholder='Enter the Url'
                                          // onChange={handleWebsiteUrlForDisplay}
                                        />
                                      </div> */}
                                            <ReactSelect />
                                          </div>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </>
                              )}
                              {/* feed ends here */}
                              {showBiddingForDisplay && (
                                <>
                                  <BiddingForDisaplyFlow
                                    bidStrategyHandler={googleBiddingHandler}
                                    bidStrategyError={formErrors?.bidding_focus}
                                    bidStrategy={
                                      googleCreateCampaignData?.google_Binding
                                        ?.bidStrategy
                                    }
                                    setTargetCostPerAction={
                                      googleCreateCampaignData?.google_Binding
                                        ?.setTargetCostPerAction
                                    }
                                    cpaCheckBoxHandler={cpaCheckBoxHandler}
                                    waysToGetConversionsHandler={
                                      waysToGetConversionsHandler
                                    }
                                    waysToGetConversions={
                                      googleCreateCampaignData?.google_Binding
                                        ?.wayOfGettingConversions
                                    }
                                    viewableCPMHandler={viewableCPMHandler}
                                    payForTypeHandler={payForTypeHandler}
                                    cpaCostHandler={cpaCostHandler}
                                    roasCheckBoxHandler={roasCheckBoxHandler}
                                    maximumCpcBidLimitValue={
                                      googleCreateCampaignData?.google_Binding
                                        ?.maximumCpcBidLimitValue
                                    }
                                    viewableCpmValue={
                                      googleCreateCampaignData?.google_Binding
                                        ?.viewableCpmValue
                                    }
                                    payForType={
                                      googleCreateCampaignData?.google_Binding
                                        ?.payForType
                                    }
                                    targetCpa={
                                      googleCreateCampaignData?.google_Binding
                                        ?.targetCpa
                                    }
                                    targetRoas={
                                      googleCreateCampaignData?.google_Binding
                                        ?.targetRoas
                                    }
                                  />
                                </>
                              )}
                              {showBiddingForApp && (
                                <>
                                  <BiddingForApp
                                    cpiCostHandler={cpiCostHandler}
                                    cpprCostHandler={cpprCostHandler}
                                    cpaCostHandler={cpaCostHandler}
                                    subCamp={campSubTypeApp}
                                    bidStrategyHandler={googleBiddingHandler}
                                    bidStrategyError={formErrors?.bidding_focus}
                                    bidStrategy={
                                      googleCreateCampaignData?.google_Binding
                                        ?.bidStrategy
                                    }
                                    googleCreateCampaignData={
                                      googleCreateCampaignData
                                    }
                                  />
                                </>
                              )}
                              {/* Bidding starts here */}
                              {showBidding && (
                                <>
                                  <Card>
                                    <CardHeader>
                                      <div className='card-lt-pane'>
                                        <h5>Bidding</h5>
                                      </div>
                                    </CardHeader>
                                    <CardHeader>
                                      <div className='card-lt-pane'>
                                        <h6>What do you want to focus on?</h6>
                                      </div>
                                    </CardHeader>
                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='form-item'>
                                            <div className='label-icon'>
                                              <label>Options</label>
                                            </div>
                                            {biddingFocusOptions == 'search' ? (
                                              <>
                                                <ReactSelect
                                                  options={bidOptionsForSearch}
                                                  onChange={
                                                    googleBiddingHandler
                                                  }
                                                  closeMenuOnSelect={true}
                                                  value={[
                                                    {
                                                      label:
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.bidStrategy,
                                                      value:
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.bidStrategy,
                                                    },
                                                  ]}
                                                />
                                              </>
                                            ) : biddingFocusOptions ==
                                              'Shopping' ? (
                                              <>
                                                <ReactSelect
                                                  options={
                                                    bidOptionsForShopping
                                                  }
                                                  onChange={
                                                    googleBiddingHandler
                                                  }
                                                  closeMenuOnSelect={true}
                                                  value={[
                                                    {
                                                      label:
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.bidStrategy,
                                                      value:
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.bidStrategy,
                                                    },
                                                  ]}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <ReactSelect
                                                  options={
                                                    bidOptionsForPerformance
                                                  }
                                                  onChange={
                                                    googleBiddingHandler
                                                  }
                                                  closeMenuOnSelect={true}
                                                  value={[
                                                    {
                                                      label:
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.bidStrategy,
                                                      value:
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.bidStrategy,
                                                    },
                                                  ]}
                                                />
                                              </>
                                            )}
                                            {/* <ReactSelect
                                        
                                        options={
                                          biddingFocusOptions == 'search'
                                            ? bidOptionsForPerformance
                                            : bidOptionsForPerformance
                                        }
                                        onChange={googleBiddingHandler}
                                        closeMenuOnSelect={true}
                                      /> */}
                                            <span
                                              className='error'
                                              id='error-bidding_focus'
                                            >
                                              {formErrors?.bidding_focus}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </CardBody>

                                    <CardBody>
                                      <div className='action-btn-both action-btn-both-check row relative'>
                                        {googleCreateCampaignData
                                          ?.google_Binding?.bidStrategy ==
                                          'Manual CPC' && (
                                          <div className='card-form form-panel'>
                                            <div className='row align-items-center'>
                                              <div className='form-item input-group-label col-6'>
                                                <div className='budget-cont'>
                                                  <div className='label-icon'>
                                                    <label>Manual CPC</label>
                                                  </div>

                                                  <div className='input-group'>
                                                    <span className='input-group-text rs-text'>
                                                      %
                                                    </span>
                                                    <input
                                                      type='number'
                                                      id='email'
                                                      placeholder='X.XX'
                                                      name='maximumCpcBidLimitValue'
                                                      value={
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.maximumCpcBidLimitValue
                                                      }
                                                      onChange={cpcHandler}
                                                      min='0'
                                                      onFocus={handleFocus}
                                                      onKeyDown={
                                                        blockInvalidChar
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <span
                                                  className='error'
                                                  id='error-maximumCpcBidLimitValue'
                                                >
                                                  {formErrors?.targetSpend}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </CardBody>

                                    <CardBody>
                                      <div className='action-btn-both action-btn-both-check row relative'>
                                        {googleCreateCampaignData
                                          ?.google_Binding?.bidStrategy ==
                                          'Conversions' && (
                                          <div className='form-check-box'>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              name='targetCpa'
                                              // onChange={costTargetCheckBoxHandler}
                                              onChange={cpaCheckBoxHandler}
                                              id='inlineCheckbox1'
                                              defaultValue='option1'
                                              checked={
                                                googleCreateCampaignData
                                                  ?.google_Binding
                                                  ?.setTargetCostPerAction &&
                                                googleCreateCampaignData
                                                  ?.google_Binding
                                                  ?.bidStrategy == 'Conversions'
                                              }
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor='inlineCheckbox1'
                                            >
                                              Set a target cost per action{' '}
                                              <span className='opt-text'>
                                                (Optional)
                                              </span>
                                            </label>
                                          </div>
                                        )}

                                        {googleCreateCampaignData
                                          ?.google_Binding
                                          ?.setTargetCostPerAction &&
                                        googleCreateCampaignData?.google_Binding
                                          ?.bidStrategy == 'Conversions' ? (
                                          <div className='card-form form-panel'>
                                            <div className='row align-items-center'>
                                              <div className='form-item input-group-label col-6'>
                                                <div className='budget-cont'>
                                                  <div className='label-icon'>
                                                    <label>Target CPA</label>
                                                  </div>
                                                  <div className='input-group'>
                                                    <span className='input-group-text rs-text'>
                                                      <i className='icon-rupee' />
                                                    </span>
                                                    <input
                                                      type='number'
                                                      id='email'
                                                      placeholder='X.XX'
                                                      name='targetCpa'
                                                      value={
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.targetCpa
                                                      }
                                                      onChange={cpaCostHandler}
                                                      min='0'
                                                      onFocus={handleFocus}
                                                      onKeyDown={
                                                        blockInvalidChar
                                                      }
                                                    />
                                                    <span className='input-group-text inr-text rs-text'>
                                                      INR
                                                    </span>
                                                  </div>
                                                </div>
                                                <span
                                                  className='error'
                                                  id='error-targetSpend'
                                                >
                                                  {formErrors?.targetSpend}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {googleCreateCampaignData
                                          ?.google_Binding?.bidStrategy ==
                                          'Conversions value' && (
                                          <div className='form-check-box'>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              name='targetRoas'
                                              // onChange={costTargetCheckBoxHandler}
                                              onChange={roasCheckBoxHandler}
                                              id='inlineCheckbox1'
                                              defaultValue='option1'
                                              checked={
                                                googleCreateCampaignData
                                                  ?.google_Binding
                                                  ?.setTargetCostPerAction &&
                                                googleCreateCampaignData
                                                  ?.google_Binding
                                                  ?.bidStrategy ==
                                                  'Conversions value'
                                              }
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor='inlineCheckbox1'
                                            >
                                              Set a target return on ad{' '}
                                              <span className='opt-text'>
                                                (Optional)
                                              </span>
                                            </label>
                                          </div>
                                        )}

                                        {googleCreateCampaignData
                                          ?.google_Binding
                                          ?.setTargetCostPerAction &&
                                        googleCreateCampaignData?.google_Binding
                                          ?.bidStrategy ==
                                          'Conversions value' ? (
                                          <div className='card-form form-panel'>
                                            <div className='row align-items-center'>
                                              <div className='form-item input-group-label col-6'>
                                                <div className='budget-cont'>
                                                  <div className='label-icon'>
                                                    <label>Target ROAS</label>
                                                  </div>

                                                  <div className='input-group'>
                                                    <span className='input-group-text rs-text'>
                                                      %
                                                    </span>
                                                    <input
                                                      type='number'
                                                      id='email'
                                                      placeholder='X.XX'
                                                      name='targetRoas'
                                                      value={
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.targetRoas
                                                      }
                                                      onChange={cpaCostHandler}
                                                      min='0'
                                                      onFocus={handleFocus}
                                                      onKeyDown={
                                                        blockInvalidChar
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <span
                                                  className='error'
                                                  id='error-targetSpend'
                                                >
                                                  {formErrors?.targetSpend}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {/* Display input in the Target ROAS case start  */}
                                        {googleCreateCampaignData
                                          ?.google_Binding?.bidStrategy ==
                                        'Target ROAS' ? (
                                          <div className='card-form form-panel'>
                                            <div className='row align-items-center'>
                                              <div className='form-item input-group-label col-6'>
                                                <div className='budget-cont'>
                                                  <div className='label-icon'>
                                                    <label>Target ROAS</label>
                                                  </div>

                                                  <div className='input-group'>
                                                    <span className='input-group-text rs-text'>
                                                      %
                                                    </span>
                                                    <input
                                                      type='number'
                                                      id='email'
                                                      placeholder='X.XX'
                                                      name='targetRoas'
                                                      value={
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.targetRoas
                                                      }
                                                      onChange={cpaCostHandler}
                                                      min='0'
                                                      onFocus={handleFocus}
                                                      onKeyDown={
                                                        blockInvalidChar
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <span
                                                  className='error'
                                                  id='error-targetSpend'
                                                >
                                                  {formErrors?.targetSpend}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {/*  Display input in the Target ROAS case end */}
                                        {googleCreateCampaignData
                                          ?.google_Binding
                                          ?.setTargetCostPerAction &&
                                        googleCreateCampaignData?.google_Binding
                                          ?.bidStrategy == 'Manual CPC' ? (
                                          <div className='card-form form-panel'>
                                            <div className='row align-items-center'>
                                              <div className='form-item input-group-label col-6'>
                                                <div className='budget-cont'>
                                                  <div className='label-icon'>
                                                    <label>Target ROAS</label>
                                                  </div>

                                                  <div className='input-group'>
                                                    <span className='input-group-text rs-text'>
                                                      %
                                                    </span>
                                                    <input
                                                      type='number'
                                                      id='email'
                                                      placeholder='X.XX'
                                                      name='targetRoas'
                                                      value={
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.targetRoas
                                                      }
                                                      onChange={cpaCostHandler}
                                                      min='0'
                                                      onFocus={handleFocus}
                                                      onKeyDown={
                                                        blockInvalidChar
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <span
                                                  className='error'
                                                  id='error-targetSpend'
                                                >
                                                  {formErrors?.targetSpend}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                        {googleCreateCampaignData
                                          ?.google_Binding?.bidStrategy ==
                                          'Clicks' && (
                                          <div className='form-check-box'>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              name='maximumCpcBidLimitValue'
                                              // onChange={costTargetCheckBoxHandler}
                                              onChange={cpcCheckBoxHandler}
                                              id='inlineCheckbox1'
                                              defaultValue='option1'
                                              checked={
                                                googleCreateCampaignData
                                                  ?.google_Binding
                                                  ?.maximumCpcBidLimit
                                              }
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor='inlineCheckbox1'
                                            >
                                              Set a maximum cost per click bid
                                              limit{' '}
                                              <span className='opt-text'>
                                                (Optional)
                                              </span>
                                            </label>
                                          </div>
                                        )}

                                        {googleCreateCampaignData
                                          ?.google_Binding
                                          ?.maximumCpcBidLimit ? (
                                          <div className='card-form form-panel'>
                                            <div className='row align-items-center'>
                                              <div className='form-item input-group-label col-6'>
                                                <div className='budget-cont'>
                                                  <div className='label-icon'>
                                                    <label>
                                                      Maximim CPC bid limit
                                                    </label>
                                                  </div>
                                                  <div className='input-group'>
                                                    <span className='input-group-text rs-text'>
                                                      <i className='icon-rupee' />
                                                    </span>
                                                    <input
                                                      type='number'
                                                      id='email'
                                                      placeholder='X.XX'
                                                      name='maximumCpcBidLimitValue'
                                                      value={
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.maximumCpcBidLimitValue
                                                      }
                                                      onChange={cpaCostHandler}
                                                      min='0'
                                                      onFocus={handleFocus}
                                                      onKeyDown={
                                                        blockInvalidChar
                                                      }
                                                    />
                                                    <span className='input-group-text inr-text rs-text'>
                                                      INR
                                                    </span>
                                                  </div>
                                                </div>
                                                <span
                                                  className='error'
                                                  id='error-targetSpend'
                                                >
                                                  {formErrors?.targetSpend}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        {googleCreateCampaignData
                                          ?.google_Binding?.bidStrategy ==
                                          'Impression share' && (
                                          <div className='card-form form-panel'>
                                            <div className='row align-items-center'>
                                              <div className='form-item col-6'>
                                                <h6 className='card-header-title'>
                                                  Where do you want your ads to
                                                  appear?
                                                </h6>
                                                <div className='label-icon'>
                                                  <label>Ad location</label>
                                                </div>
                                                <ReactSelect
                                                  options={[
                                                    {
                                                      label:
                                                        'Anywhere on results page',
                                                      value:
                                                        'AnyWhereOnResultPage',
                                                    },
                                                    {
                                                      label:
                                                        'Top of results page',
                                                      value: 'TopOfResultPage',
                                                    },
                                                    {
                                                      label:
                                                        'Absolute top of results page',
                                                      value:
                                                        'AbsoluteTopOfResultPage',
                                                    },
                                                  ]}
                                                  onChange={adLocationHandler}
                                                  value={[
                                                    {
                                                      label:
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.adLocation,
                                                      value:
                                                        googleCreateCampaignData
                                                          ?.google_Binding
                                                          ?.adLocation,
                                                    },
                                                  ]}
                                                  closeMenuOnSelect={true}
                                                />
                                              </div>
                                              <div className='form-item input-group-label col-6'>
                                                <h6 className='card-header-title'>
                                                  Percent (%) impression share
                                                  to target
                                                </h6>
                                                <div className='label-icon'>
                                                  <label>
                                                    Impression share to target
                                                  </label>
                                                </div>
                                                <div className='input-group'>
                                                  <span className='input-group-text rs-text'>
                                                    %
                                                  </span>
                                                  <input
                                                    type='number'
                                                    id='email'
                                                    placeholder='X.XX'
                                                    name='impressionShareToTarget'
                                                    value={
                                                      googleCreateCampaignData
                                                        ?.google_Binding
                                                        ?.impressionShareToTarget
                                                    }
                                                    onChange={cpaCostHandler}
                                                    min='0'
                                                    onFocus={handleFocus}
                                                    onKeyDown={blockInvalidChar}
                                                  />
                                                </div>
                                              </div>
                                              <div className='form-item input-group-label col-6'>
                                                <h6 className='card-header-title'>
                                                  Maximum CPC bid limit
                                                </h6>
                                                <div className='label-icon'>
                                                  <label>
                                                    Maximum CPC bid limit
                                                  </label>
                                                </div>
                                                <div className='input-group'>
                                                  <span className='input-group-text rs-text'>
                                                    <i className='icon-rupee' />
                                                  </span>
                                                  <input
                                                    type='number'
                                                    id='email'
                                                    placeholder='X.XX'
                                                    name='maximumCpcBidLimitValue'
                                                    value={
                                                      googleCreateCampaignData
                                                        ?.google_Binding
                                                        ?.maximumCpcBidLimitValue
                                                    }
                                                    onChange={cpaCostHandler}
                                                    min='0'
                                                    onFocus={handleFocus}
                                                    onKeyDown={blockInvalidChar}
                                                  />
                                                  <span className='input-group-text inr-text rs-text'>
                                                    INR
                                                  </span>
                                                </div>
                                                <span
                                                  className='error'
                                                  id='error-bid_all_fields'
                                                >
                                                  {formErrors?.bid_all_fields}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </CardBody>
                                  </Card>
                                </>
                              )}
                              {/* Bidding ends here */}
                              {/* location starts */}
                              {showLocation && (
                                <>
                                  <Card>
                                    <div className='card-header-top d-flex'>
                                      <div className='card-lt-pane'>
                                        <h5>Location</h5>
                                      </div>
                                    </div>
                                    <div className='card-body'>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='action-btn-cont'>
                                            <div
                                              className='action-btn-both action-btn-no-icon row'
                                              onChange={(e) => {}}
                                            >
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefaultLocal'
                                                    id='flexRadioDefault50Location'
                                                    defaultChecked={true}
                                                    value='Performance_Max_Campaign'
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault50Location'
                                                  >
                                                    India
                                                    {/* <p>Jul 18, 2023 - Aug 18, 2023</p> */}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                </>
                              )}
                              {/* Location ends */}
                              {showCustomerAcquisition && (
                                <>
                                  {/* customer acquisition starts */}
                                  <Card>
                                    <div className='card-header-top'>
                                      <div className='card-lt-pane'>
                                        <h6>Customer acquisition</h6>
                                      </div>
                                    </div>
                                    <div className='card-body'>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='action-btn-cont'>
                                            <div className='action-btn-both action-btn-no-icon row'>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    name='flexRadioDefault'
                                                    id='Optimize campaign'
                                                    onChange={
                                                      optimizeCampaignHandler
                                                    }
                                                    defaultChecked={
                                                      googleCreateCampaignData
                                                        ?.google_Binding
                                                        ?.customerAcquisition
                                                        ?.optimizeCampaignAcquiringNewCustomers
                                                    }
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='Optimize campaign'
                                                  >
                                                    Optimize campaign for
                                                    acquiring new customers
                                                  </label>
                                                </div>

                                                {googleCreateCampaignData
                                                  ?.google_Binding
                                                  ?.customerAcquisition
                                                  ?.optimizeCampaignAcquiringNewCustomers && (
                                                  <div className='dv-from-check-field'>
                                                    <div className='dv-check-box'>
                                                      <div className='action-btn-both action-btn-both-check row'>
                                                        <div className='form-check-box'>
                                                          <input
                                                            className='form-check-input'
                                                            type='radio'
                                                            id='inlineCheckbox8'
                                                            defaultValue='option1'
                                                            name='sample'
                                                            onChange={
                                                              bidHigherForNewCustomersHandler
                                                            }
                                                            checked={
                                                              googleCreateCampaignData
                                                                ?.google_Binding
                                                                ?.customerAcquisition
                                                                ?.bidHigherForNewCustomers
                                                            }
                                                          />
                                                          <label
                                                            className='form-check-labe2'
                                                            htmlFor='inlineCheckbox8'
                                                          >
                                                            Bid higher for new
                                                            customers than for
                                                            existing customers
                                                            (Recommended)
                                                          </label>
                                                        </div>
                                                        <div className='form-check-box'>
                                                          <input
                                                            className='form-check-input'
                                                            type='radio'
                                                            id='inlineCheckbox8opti'
                                                            defaultValue='option1'
                                                            name='sample'
                                                            onChange={
                                                              bidOnlyForNewCustomersHandler
                                                            }
                                                            checked={
                                                              googleCreateCampaignData
                                                                ?.google_Binding
                                                                ?.customerAcquisition
                                                                ?.bidForNewCustomersOnly
                                                            }
                                                          />
                                                          <label
                                                            className='form-check-labe2'
                                                            htmlFor='inlineCheckbox8opti'
                                                          >
                                                            Only bid for new
                                                            customers
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                  {/* customer acquisition ends */}
                                </>
                              )}
                              {/* Campaign settings starts here */}
                              <Card>
                                {/* network starts here */}
                                {showNetworks && (
                                  <>
                                    <AdvancedCardHeader>
                                      <div className='card-lt-pane'>
                                        <h5>Campaign settings</h5>
                                        <p>
                                          To reach the right people, start by
                                          defining key settings for your
                                          campaign
                                        </p>
                                        {/* <h6>Networks</h6> */}
                                      </div>
                                    </AdvancedCardHeader>
                                    <CardHeader>
                                      <h6>Networks</h6>
                                    </CardHeader>
                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='action-btn-cont'>
                                            <div className='action-btn-both action-btn-img-tag row'>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    name='Search'
                                                    value='Search'
                                                    id='flexRadioDefault570'
                                                    onChange={handleNetworkId}
                                                    checked={googleCreateCampaignData?.google_Campaignsettings?.network_id.includes(
                                                      'Search',
                                                    )}
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault570'
                                                  >
                                                    <figure className='crt-img'>
                                                      <img
                                                        src={searchImg}
                                                        alt='No creatives assigned'
                                                      />
                                                    </figure>
                                                    <div className='label-multitext'>
                                                      Search Network
                                                      <p>
                                                        Ads can appear near
                                                        Google Search results
                                                        and other Google sites
                                                        when people search for
                                                        terms that are relevant
                                                        to your keywords
                                                      </p>
                                                    </div>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    name='Display'
                                                    value='Display'
                                                    onChange={handleNetworkId}
                                                    id='flexRadioDefault571'
                                                    checked={googleCreateCampaignData?.google_Campaignsettings?.network_id.includes(
                                                      'Display',
                                                    )}
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault571'
                                                  >
                                                    <figure className='crt-img'>
                                                      <img
                                                        src={displayImg}
                                                        alt='No creatives assigned'
                                                      />
                                                    </figure>
                                                    <div className='label-multitext'>
                                                      Display Network
                                                      <p>
                                                        Easy way to get
                                                        additional conversions
                                                        at similar or lower
                                                        costs than Search with
                                                        unused Search budget.
                                                      </p>
                                                    </div>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </>
                                )}

                                {/* network ends here */}
                                {/* search network starts here */}
                                {showsearchNetwork && (
                                  <>
                                    <CardHeader>
                                      <h6>Networks</h6>
                                    </CardHeader>
                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='action-btn-cont'>
                                            <div className='action-btn-both action-btn-img-tag row'>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    name='Search'
                                                    value='Search'
                                                    id='flexRadioDefault570net'
                                                    onChange={handleNetworkId}
                                                    defaultChecked={googleCreateCampaignData?.google_Campaignsettings?.network_id.includes(
                                                      'Search',
                                                    )}
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault570net'
                                                  >
                                                    <figure className='crt-img'>
                                                      <img
                                                        src={searchImg}
                                                        alt='No creatives assigned'
                                                      />
                                                    </figure>
                                                    <div className='label-multitext'>
                                                      Search Network
                                                      <p>
                                                        Ads can appear near
                                                        Google Search results
                                                        and other Google sites
                                                        when people search for
                                                        terms that are relevant
                                                        to your keywords
                                                      </p>
                                                    </div>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </>
                                )}

                                {/* search network ends here */}
                                {/* languages starts here */}
                                {showLanguages && (
                                  <>
                                    <CardHeader>
                                      <h6>Languages</h6>
                                    </CardHeader>
                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <ReactSelect
                                            options={
                                              googleCreateCampaignData
                                                ?.google_Campaignsettings
                                                ?.languageList
                                            }
                                            isMulti={true}
                                            onChange={handleLanguage}
                                            value={
                                              googleCreateCampaignData
                                                ?.google_Campaignsettings
                                                ?.languageSelected
                                            }
                                          />
                                        </div>
                                      </div>
                                    </CardBody>
                                  </>
                                )}
                                {/* languages ends here */}

                                {/* siteLinks starts here */}
                                {showSiteLinks && (
                                  <>
                                    <CardHeader>
                                      <h6>SiteLink</h6>
                                    </CardHeader>
                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <ReactSelect
                                            options={[
                                              { label: 'test', value: '1' },
                                            ]}
                                          />
                                        </div>
                                      </div>
                                    </CardBody>
                                  </>
                                )}
                                {/* siteLinks ends here */}
                                {/* LeadForm starts here */}
                                {showLeadForm && (
                                  <>
                                    <CardHeader>
                                      <h6>LeadForm</h6>
                                    </CardHeader>
                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <ReactSelect
                                            options={[
                                              { label: 'test', value: '1' },
                                            ]}
                                          />
                                        </div>
                                      </div>
                                    </CardBody>
                                  </>
                                )}
                                {/* LeadForm ends here */}
                                {/* AudienceSegments starts here */}
                                {showAudienceSegments && (
                                  <>
                                    <CardHeader>
                                      <h6>Audience segments</h6>
                                    </CardHeader>
                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <ReactSelect
                                            isMulti={true}
                                            options={
                                              googleCreateCampaignData
                                                ?.google_Campaignsettings
                                                ?.audience_segments_List
                                            }
                                            // closeMenuOnSelect={true}
                                            onChange={handleAudienceSeg}
                                            value={
                                              googleCreateCampaignData
                                                ?.google_Campaignsettings
                                                ?.audience_segments_id
                                            }
                                          />
                                        </div>
                                      </div>
                                    </CardBody>
                                  </>
                                )}
                                {false && (
                                  <>
                                    <CardHeader>
                                      <h6>
                                        Targeting setting for this campaign
                                      </h6>
                                    </CardHeader>
                                    <div className='card-body'>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='action-btn-cont'>
                                            <div className='action-btn-both action-btn-no-icon row'>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefaultty'
                                                    id='flexRadioDefault50Target'
                                                    defaultChecked={true}
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault50Target'
                                                  >
                                                    Targeting
                                                    <p>
                                                      Narrow the reach of your
                                                      campaign to the selected
                                                      segments, with the option
                                                      to adjust the bids
                                                    </p>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefaultty'
                                                    id='flexRadioDefault51Target'
                                                    defaultChecked=''
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault51Target'
                                                  >
                                                    Observation (recommended)
                                                    <p>
                                                      Don't narrow the reach of
                                                      your campaign, with the
                                                      option to adjust the bids
                                                      on the selected segments
                                                    </p>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {/* AudienceSegments ends here */}
                                {/* AudienceSegments starts here */}
                                {showBroadMatchKeywords && (
                                  <>
                                    <CardHeader>
                                      <h6>Broad match keywords</h6>
                                    </CardHeader>
                                    <div className='card-body'>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='action-btn-cont'>
                                            <div className='action-btn-both action-btn-no-icon row'>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefaultrtt'
                                                    id='flexRadioDefault50Keyword'
                                                    defaultChecked={true}
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault50Keyword'
                                                  >
                                                    On
                                                    <p>
                                                      Use broad match keywords
                                                      for your entire campaign
                                                    </p>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefaultrtt'
                                                    id='flexRadioDefault51Keyword'
                                                    defaultChecked=''
                                                    disabled={true}
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault51Keyword'
                                                  >
                                                    Off
                                                    <p>
                                                      Use keyword match types
                                                    </p>
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {/* AudienceSegments ends here */}
                                {/* Ad rotation starts here */}
                                {showAdRotation && (
                                  <>
                                    <CardHeader>
                                      <h6>Ad rotation</h6>
                                    </CardHeader>
                                    <div className='card-body'>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='action-btn-cont'>
                                            <div
                                              className='action-btn-both action-btn-no-icon row'
                                              onChange={adRotationHandler}
                                            >
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefaultrotate'
                                                    id='flexRadioDefault50rotate'
                                                    defaultChecked={true}
                                                    // checked={googleCreateCampaignData?.google_Campaignsettings?.add_rotation_value == true}
                                                    // checked={googleCreateCampaignData?.google_Campaignsettings?.add_rotation_value == true}
                                                    value={true}
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault50rotate'
                                                  >
                                                    Optimize
                                                    <p>
                                                      Prefer best performing ads
                                                    </p>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefaultrotate'
                                                    id='flexRadioDefault51rotate'
                                                    // checked={googleCreateCampaignData?.google_Campaignsettings?.add_rotation_value == false}
                                                    // checked={false}
                                                    value={false}
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault51rotate'
                                                  >
                                                    Do not optimize
                                                    <p>
                                                      Rotate ads indefinitely
                                                    </p>
                                                  </label>
                                                </div>
                                              </div>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefault'
                                                    id='flexRadioDefault52rotate'
                                                    defaultChecked=''
                                                    disabled={true}
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault52rotate'
                                                  >
                                                    Optimize for conversions
                                                    (Not supported)
                                                  </label>
                                                </div>
                                              </div>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='flexRadioDefault'
                                                    id='flexRadioDefault53rotate'
                                                    defaultChecked=''
                                                    disabled={true}
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault54rotate'
                                                  >
                                                    Rotate evenly (Not
                                                    supported)
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                                {/* Ad rotation ends here */}
                                {showAutomaticallyCreatedAssets && (
                                  <>
                                    <CardHeader>
                                      <h6>Automatically created assets</h6>
                                    </CardHeader>
                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='action-btn-cont'>
                                            <div className='action-btn-both action-btn-img-tag row'>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    name=''
                                                    id='flexRadioDefault50qq'
                                                    onChange={(e) => {
                                                      textAssetsHandler(e);
                                                    }}
                                                    checked={
                                                      !!googleCreateCampaignData?.automaticallyCreatedAssetText
                                                    }
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault50qq'
                                                  >
                                                    Text assets
                                                    {true ? (
                                                      <p>
                                                        Use content from your
                                                        landing page, domain,
                                                        and ads in combination
                                                        with assets you provide
                                                        directly. Customize
                                                        assets based on
                                                        relevance
                                                      </p>
                                                    ) : null}
                                                  </label>
                                                </div>
                                              </div>
                                              <div className='col-12 px-0'>
                                                <div className='form-check-box'>
                                                  <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    name=''
                                                    id='flexRadioDefault50pp'
                                                    onChange={(e) => {
                                                      finalUrlHandler(e);
                                                    }}
                                                    checked={
                                                      !!googleCreateCampaignData?.automaticallyCreatedAssetFinalUrl
                                                    }
                                                  />
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='flexRadioDefault50pp'
                                                  >
                                                    Final URL
                                                    {true ? (
                                                      <p>
                                                        If you choose to
                                                        subdivide inventory in
                                                        the next step, Final Url
                                                        expansion will only send
                                                        traffic to landing pages
                                                        related to the
                                                        campaign's product
                                                        inventory
                                                      </p>
                                                    ) : null}
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </>
                                )}
                                {showSingleStartDateEndDate && (
                                  <>
                                    <CardHeader>
                                      <h6>Start and end dates</h6>
                                    </CardHeader>

                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='form-item input-group-label col-6'>
                                            <div className='label-icon'>
                                              <label>Start date</label>
                                            </div>
                                            <div className='input-group'>
                                              <span className='input-group-text rs-text'>
                                                <i className='icon-calendar' />
                                              </span>
                                              {/* <input
                                      type="text"
                                      id="datepicker"
                                      placeholder="DD/MM/YYYY"
                                    /> */}
                                              <ReactDatePicker
                                                // selected={null}
                                                selected={
                                                  googleCreateCampaignData
                                                    ?.google_Campaignsettings
                                                    ?.startDate &&
                                                  new Date(
                                                    googleCreateCampaignData?.google_Campaignsettings?.startDate,
                                                  )
                                                }
                                                // onChange={(e) => setStartDate(e)}
                                                onChange={(e) =>
                                                  startDateHandler(e)
                                                }
                                                minDate={new Date()}
                                                dateFormat='dd/MM/yyyy'
                                                showMonthDropdown
                                                showYearDropdown
                                                placeholderText='DD/MM/YYYY'
                                                disabled={
                                                  params['id'] &&
                                                  moment(
                                                    googleCreateCampaignData
                                                      ?.google_Campaignsettings
                                                      ?.startDate,
                                                  ).isBefore(
                                                    moment().add(0, 'day'),
                                                  )
                                                }
                                              />
                                            </div>
                                            {formErrors?.startDate && (
                                              <span
                                                className='error'
                                                id='error-startDate'
                                              >
                                                {formErrors?.startDate}
                                              </span>
                                            )}
                                          </div>
                                          <div className='form-item input-group-label col-6'>
                                            <div className='label-icon'>
                                              <label>End date</label>
                                            </div>
                                            <div className='input-group time'>
                                              <span className='input-group-text rs-text'>
                                                <i className='icon-calendar' />
                                              </span>
                                              {/* <input
                                      type="text"
                                      id="datepicker"
                                      placeholder="DD/MM/YYYY"
                                    /> */}
                                              <ReactDatePicker
                                                selected={
                                                  googleCreateCampaignData
                                                    ?.google_Campaignsettings
                                                    ?.endDate &&
                                                  new Date(
                                                    googleCreateCampaignData?.google_Campaignsettings?.endDate,
                                                  )
                                                }
                                                onChange={(e) =>
                                                  endDateHandler(e)
                                                }
                                                minDate={
                                                  googleCreateCampaignData
                                                    ?.google_Campaignsettings
                                                    ?.startDate &&
                                                  new Date(
                                                    moment(
                                                      googleCreateCampaignData
                                                        ?.google_Campaignsettings
                                                        ?.startDate,
                                                    )
                                                      .add(1, 'd')
                                                      .format(),
                                                  )
                                                }
                                                dateFormat='dd/MM/yyyy'
                                                showMonthDropdown
                                                showYearDropdown
                                                placeholderText='DD/MM/YYYY'
                                                disabled={
                                                  !googleCreateCampaignData
                                                    ?.google_Campaignsettings
                                                    ?.startDate ||
                                                  (params['id'] &&
                                                    moment(
                                                      googleCreateCampaignData
                                                        ?.google_Campaignsettings
                                                        ?.endDate,
                                                    ).isBefore(new Date()))
                                                }
                                              />
                                            </div>
                                            <span
                                              className='error'
                                              id='error-endDate'
                                            >
                                              {formErrors?.endDate}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </>
                                )}
                                {showAdSchedule && (
                                  <>
                                    <CardHeader>
                                      <h6 className='d-flex align-items-center'>
                                        Ad schedule
                                      </h6>
                                    </CardHeader>
                                    <CardBody>
                                      {googleCreateCampaignData?.google_Campaignsettings?.schedule?.map(
                                        (item, index) => {
                                          return (
                                            <div
                                              className='card-form form-panel form-panel-group-add'
                                              key={index}
                                            >
                                              <div className='row align-items-center'>
                                                <div className='form-item col-box-3'>
                                                  <div className='label-icon'>
                                                    <label>Select Days</label>
                                                  </div>
                                                  <ReactSelect
                                                    options={[
                                                      // {
                                                      //   label: 'All days',
                                                      //   value: 'All days',
                                                      // },
                                                      // {
                                                      //   label: 'Monday-Friday',
                                                      //   value: 'Monday-Friday',
                                                      // },
                                                      // {
                                                      //   label: 'Saturday-Sunday',
                                                      //   value: 'Saturday-Sunday',
                                                      // },
                                                      {
                                                        label: 'Monday',
                                                        value: 'Monday',
                                                      },
                                                      {
                                                        label: 'Tuesday',
                                                        value: 'Tuesday',
                                                      },
                                                      {
                                                        label: 'Wednesday',
                                                        value: 'Wednesday',
                                                      },
                                                      {
                                                        label: 'Thursday',
                                                        value: 'Thursday',
                                                      },
                                                      {
                                                        label: 'Friday',
                                                        value: 'Friday',
                                                      },
                                                      {
                                                        label: 'Saturday',
                                                        value: 'Saturday',
                                                      },
                                                      {
                                                        label: 'Sunday',
                                                        value: 'Sunday',
                                                      },
                                                    ]}
                                                    onChange={(e) => {
                                                      handleSelectDays(
                                                        e,
                                                        index,
                                                      );
                                                    }}
                                                    value={[
                                                      {
                                                        label:
                                                          googleCreateCampaignData
                                                            ?.google_Campaignsettings
                                                            ?.schedule[index]
                                                            ?.scheduleDays,
                                                        value:
                                                          googleCreateCampaignData
                                                            ?.google_Campaignsettings
                                                            ?.schedule[index]
                                                            ?.scheduleDays,
                                                      },
                                                    ]}
                                                    isClearable={false}
                                                    deleteRemoves={false}
                                                    closeMenuOnSelect={true}
                                                  />
                                                </div>

                                                <div className='form-item input-group-label col-box-3'>
                                                  <div className='label-icon'>
                                                    <label>Start time</label>
                                                  </div>
                                                  <div className='input-group input-width-less'>
                                                    <span className='input-group-text rs-text'>
                                                      <i className='icon-clock' />
                                                    </span>
                                                    {/* <input
                                      type="text"
                                      id="datepicker"
                                      placeholder="00:00"
                                    /> */}
                                                    <ReactDatePicker
                                                      placeholderText='00:00'
                                                      // selected={null}
                                                      selected={
                                                        googleCreateCampaignData
                                                          ?.google_Campaignsettings
                                                          ?.schedule[index]
                                                          ?.scheduleStartTime &&
                                                        new Date(
                                                          googleCreateCampaignData?.google_Campaignsettings?.schedule[
                                                            index
                                                          ]?.scheduleStartTime,
                                                        )
                                                      }
                                                      onChange={(time) => {
                                                        handleAdScheduleTime(
                                                          time,
                                                          index,
                                                          'start',
                                                        );
                                                      }}
                                                      isClearable={
                                                        googleCreateCampaignData
                                                          ?.google_Campaignsettings
                                                          ?.schedule[index]
                                                          ?.scheduleStartTime
                                                      }
                                                      showTimeSelect
                                                      showTimeSelectOnly
                                                      timeIntervals={15}
                                                      timeCaption='Time'
                                                      dateFormat='HH:mm'
                                                      timeFormat='HH:mm'
                                                    />
                                                  </div>
                                                  {formErrors?.schedule &&
                                                    formErrors?.schedule[index]
                                                      ?.start_time && (
                                                      <span
                                                        className='error'
                                                        id={`error-startDate`}
                                                      >
                                                        {formErrors?.startDate}
                                                      </span>
                                                    )}
                                                </div>
                                                <div className='form-item input-group-label col-box-3'>
                                                  <div className='label-icon'>
                                                    <label>End time</label>
                                                  </div>
                                                  <div className='input-group input-width-less'>
                                                    <span className='input-group-text rs-text'>
                                                      <i className='icon-clock' />
                                                    </span>
                                                    {/* <input
                                      type="text"
                                      id="datepicker1"
                                      placeholder="00:00"
                                    /> */}
                                                    <ReactDatePicker
                                                      placeholderText='00:00'
                                                      selected={
                                                        googleCreateCampaignData
                                                          ?.google_Campaignsettings
                                                          ?.schedule[index]
                                                          ?.scheduleEndTime &&
                                                        new Date(
                                                          googleCreateCampaignData?.google_Campaignsettings?.schedule[
                                                            index
                                                          ]?.scheduleEndTime,
                                                        )
                                                      }
                                                      onChange={(time) => {
                                                        // setEndTime(time);
                                                        handleAdScheduleTime(
                                                          time,
                                                          index,
                                                          'end',
                                                        );
                                                      }}
                                                      isClearable={
                                                        googleCreateCampaignData
                                                          ?.google_Campaignsettings
                                                          ?.schedule[index]
                                                          ?.scheduleEndTime
                                                      }
                                                      showTimeSelect
                                                      showTimeSelectOnly
                                                      timeIntervals={15}
                                                      timeCaption='Time'
                                                      dateFormat='HH:mm'
                                                      timeFormat='HH:mm'
                                                      minTime={
                                                        new Date(
                                                          moment(
                                                            googleCreateCampaignData
                                                              ?.google_Campaignsettings
                                                              ?.schedule[index]
                                                              ?.scheduleStartTime,
                                                          )
                                                            .add(15, 'm')
                                                            .format(),
                                                        )
                                                      }
                                                      maxTime={
                                                        new Date(
                                                          0,
                                                          0,
                                                          0,
                                                          23,
                                                          45,
                                                        )
                                                      }
                                                      disabled={
                                                        !googleCreateCampaignData
                                                          ?.google_Campaignsettings
                                                          ?.schedule[index]
                                                          ?.scheduleStartTime
                                                      }
                                                    />
                                                  </div>
                                                  {formErrors?.schedule &&
                                                    formErrors?.schedule[index]
                                                      ?.end_time && (
                                                      <span
                                                        className='error'
                                                        id='error-endDate'
                                                      >
                                                        {formErrors?.endDate}
                                                      </span>
                                                    )}
                                                </div>

                                                {googleCreateCampaignData
                                                  ?.google_Campaignsettings
                                                  ?.schedule?.length > 1 ? (
                                                  <div className='media-btn'>
                                                    <button
                                                      type='button'
                                                      className='btn-icon btn-icon-primary btn-bedcrumb-act'
                                                      onClick={() => {
                                                        handleRemoveAdSchedule(
                                                          index,
                                                        );
                                                      }}
                                                    >
                                                      <i className='icon-delete' />
                                                      Remove
                                                    </button>
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>
                                          );
                                        },
                                      )}

                                      <div className='add-section'>
                                        <button
                                          className='btn-add-plus'
                                          onClick={handleAddSchedule}
                                        >
                                          <i className='icon-plus-circle' /> Add
                                          more schedules
                                        </button>
                                      </div>
                                    </CardBody>
                                  </>
                                )}

                                {/* campaign url option starts */}
                                {showCampaignUrl && (
                                  <>
                                    <CardHeader>
                                      <h6>Campaign URL options</h6>
                                    </CardHeader>
                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='form-item'>
                                            <div className='budget-cont'>
                                              <div className='label-icon'>
                                                <label>Tracking Template</label>
                                              </div>

                                              <ReactSelect
                                                options={
                                                  googleCreateCampaignData?.trackingTemplateList
                                                }
                                                onChange={(e) => {
                                                  handleCampURLTemplate(e);
                                                }}
                                                closeMenuOnSelect={true}
                                                value={googleCreateCampaignData?.trackingTemplateList?.find(
                                                  (item) =>
                                                    item.label ==
                                                    googleCreateCampaignData
                                                      ?.google_Campaign_Url_Data
                                                      ?.tracking_templates,
                                                )}
                                              />
                                              {/* </div> */}
                                            </div>
                                            <span
                                              className='error'
                                              id='error-trackingTemplate'
                                            >
                                              {formErrors?.trackingTemplate}
                                            </span>
                                          </div>
                                          <div class='info-icon-body mt-2 mb-2'>
                                            <div class='more-info-text'>
                                              <p>
                                                Example:
                                                https://www.trackingtemplate.foo/?url=
                                                {''}
                                                &id=5
                                              </p>
                                            </div>
                                          </div>

                                          <div className='form-item'>
                                            <div className='budget-cont'>
                                              <div className='label-icon'>
                                                <label>Final URL suffix</label>
                                              </div>
                                              <div className='input-group'>
                                                <input
                                                  type='text'
                                                  id='email'
                                                  placeholder='Final URL suffix'
                                                  name='suffix'
                                                  onChange={(e) => {
                                                    handleCampURL(e);
                                                  }}
                                                  value={
                                                    googleCreateCampaignData
                                                      ?.google_Campaign_Url_Data
                                                      ?.suffix
                                                  }
                                                  readOnly={
                                                    googleCampaignTypeList?.[
                                                      'Performance Max'
                                                    ]?.campaign_type_id ==
                                                    googleCreateCampaignData?.campaign_type_id
                                                  }
                                                  disabled={
                                                    googleCampaignTypeList?.[
                                                      'Performance Max'
                                                    ]?.campaign_type_id ==
                                                    googleCreateCampaignData?.campaign_type_id
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </>
                                )}
                                {/* campaign url option ends */}

                                <CardBody>
                                  {/* <div className="card-body"> */}
                                  {/* <div className='info-icon-body'>
                              <div className='more-info-icon'>
                                <i className='icon-info' />
                              </div>
                              <div className='more-info-text'>
                                <p>
                                  Based on account time zone: (GMT+05:30) India
                                  Standard Time
                                </p>
                                <p>
                                  Saving this removes the settings you changed
                                  and adds new ones, resetting any performance
                                  data
                                </p>
                              </div>
                            </div> */}
                                  {/* </div> */}
                                </CardBody>
                                {/* brandRestricrted starts here */}
                                {showBrandRestricted && (
                                  <>
                                    <CardHeader>
                                      <h6>Brand restrictions</h6>
                                      <p>
                                        Your ads will only show on searches that
                                        match your keywords and mention selected
                                        brands, including related products and
                                        services. Brand restrictions will limit
                                        search traffic, so apply only necessary
                                        brands.
                                      </p>
                                    </CardHeader>
                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='form-item'>
                                            <div className='label-icon'>
                                              <label>Options</label>
                                            </div>
                                            <ReactSelect
                                              isMulti={true}
                                              isSearchable={true}
                                              options={
                                                googleCreateCampaignData
                                                  ?.google_Campaignsettings
                                                  ?.brandRestrictedList
                                              }
                                              onChange={brandRestricedHandler}
                                              value={
                                                googleCreateCampaignData
                                                  ?.google_Campaignsettings
                                                  ?.brand_restricted
                                              }
                                              isClearable={true}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </CardBody>
                                    {showBroadMatchKeywords && (
                                      <>
                                        <CardBody>
                                          <div className='info-icon-body'>
                                            <div className='more-info-icon'>
                                              <i className='icon-info' />
                                            </div>
                                            <div className='more-info-text'>
                                              <p>
                                                To add brand restrictions, turn
                                                on broad match keywords for this
                                                campaign. All new and existing
                                                keywords will be converted to
                                                broad match.
                                              </p>
                                            </div>
                                          </div>
                                        </CardBody>
                                      </>
                                    )}
                                  </>
                                )}
                                {/* brandRestricrted ends here */}
                              </Card>
                              {/* Campaign settings ends here */}
                              {showNetworkForVideo && (
                                <Card>
                                  <AdvancedCardHeader>
                                    <div className='card-lt-pane'>
                                      <h5>Network</h5>
                                    </div>
                                  </AdvancedCardHeader>
                                  <CardBody>
                                    <div className='card-form form-panel'>
                                      <div className='row align-items-center'>
                                        <div className='action-btn-cont'>
                                          <div className='action-btn-both action-btn-no-icon row'>
                                            <div className='col-12 px-0'>
                                              <div className='form-check-box'>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  name='Store visits'
                                                  id='flexRadioDefault500network'
                                                  checked={true}
                                                  disabled={true}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor='flexRadioDefault500network'
                                                >
                                                  YouTube
                                                  <p>
                                                    Ads can appear on YouTube
                                                    videos and channels, YouTube
                                                    home, and in YouTube search
                                                    results
                                                  </p>
                                                </label>
                                              </div>
                                            </div>
                                            <div className='col-12 px-0'>
                                              <div className='form-check-box'>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  name='Store visits'
                                                  id='flexRadioDefault50network'
                                                  checked={true}
                                                  disabled={true}
                                                />
                                                <label
                                                  className='form-check-label'
                                                  htmlFor='flexRadioDefault50network'
                                                >
                                                  Video partners on the Google
                                                  Display Network
                                                  <p>
                                                    Video partners extend the
                                                    reach of video ads to a
                                                    collection of sites and apps
                                                    in the Google Display
                                                    Network.
                                                  </p>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              )}
                              {/* Prodcut Feed Start Here */}
                              {showProductFeed && (
                                <>
                                  <div className='card-primary-repet'>
                                    <div className='card-primary'>
                                      <div className='card-header-top'>
                                        <div className='card-lt-pane'>
                                          <h5>Product feed</h5>
                                          {/* <p>Define who you want to see your ads.</p> */}
                                          <div className='action-btn-both action-btn-both-check row'>
                                            <div className='form-check-box'>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                id='inlineCheckbox1'
                                                onChange={
                                                  handleProductFeedStatus
                                                }
                                                checked={
                                                  googleCreateCampaignData?.productFeeds ==
                                                  1
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                className='form-check-label'
                                                htmlFor='inlineCheckbox1'
                                              >
                                                Use a Google Merchant Center
                                                feed to show products with your
                                                ads
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='card-body'>
                                        <div className='card-form form-panel'>
                                          <div className='row align-items-center'>
                                            <div className='form-item col-12'>
                                              <div className='label-icon'>
                                                <label>
                                                  Merchent Center account
                                                </label>
                                              </div>
                                              <ReactSelect
                                                options={
                                                  googleCreateCampaignData?.merchentCenterAccount
                                                }
                                                isMulti={false}
                                                isSearchable={true}
                                                onChange={
                                                  handleSelectMerchentAccount
                                                }
                                                closeMenuOnSelect={true}
                                                value={googleCreateCampaignData?.google_Campaignsettings?.merchentCenterAccountList.find(
                                                  (item) =>
                                                    item.value ==
                                                    googleCreateCampaignData?.productFeedSelectedAccount,
                                                )}
                                                isDisabled={
                                                  googleCreateCampaignData?.productFeeds ==
                                                  1
                                                    ? false
                                                    : true
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {/* Prodcut Feed Ends Here */}
                              {/* CampaignGoal start */}
                              {showCampaignGoalDemandGen && (
                                <CampaignGoal
                                  handleSetCampaignGoalVal={
                                    handleSetCampaignGoalVal
                                  }
                                  handleSetConversionGoal={
                                    handleSetConversionGoal
                                  }
                                  options={
                                    googleCreateCampaignData?.conversionGoals
                                  }
                                  selectedCampaignGoal={
                                    googleCreateCampaignData?.campaignGoal
                                  }
                                  selectedConversionGoalId={
                                    googleCreateCampaignData?.conversionGoalId
                                  }
                                />
                              )}
                              {/* CampaignGoal end */}
                              {/* Target Cost Per Action Start */}
                              {showTargetCostPerAction && (
                                <Card>
                                  <CardHeader>
                                    <div className='card-lt-pane'>
                                      <h5>Target cost per action</h5>
                                    </div>
                                  </CardHeader>
                                  <CommonInfoComponent
                                    info={`By default, your campaign will aim to maximize your conversions. You can set an optional target cost per action (Target CPA) to optimize for getting conversions at a specific cost per conversion.`}
                                  />
                                  <CommonAmountComponent
                                    handleSetAmountVal={handleSetAmountVal}
                                    value={
                                      googleCreateCampaignData?.targetCostPerClick
                                    }
                                  />
                                  <CommonInfoComponent
                                    info={`For the month, you won't pay more than your daily budget times the average number of days in a month. Some days you might spend less than your daily budget, and on others you might spend up to twice as much.`}
                                  />
                                </Card>
                              )}
                              {/* Target Cost Per Action End */}
                              {/* Budget & dates start */}
                              {showBudgetAndDates && (
                                <Card>
                                  <CardHeader>
                                    <div className='card-lt-pane'>
                                      <h5>Budget and dates</h5>
                                    </div>
                                  </CardHeader>
                                  <BudgetAndDates
                                    handleBudgetAndDate={handleBudgetAndDateVal}
                                    startDateHandler={(e) =>
                                      startDateHandler(e)
                                    }
                                    endDateHandler={(e) => endDateHandler(e)}
                                    selectedStartDate={
                                      googleCreateCampaignData
                                        ?.google_Campaignsettings?.startDate &&
                                      new Date(
                                        googleCreateCampaignData?.google_Campaignsettings?.startDate,
                                      )
                                    }
                                    selectedEndDate={
                                      googleCreateCampaignData
                                        ?.google_Campaignsettings?.endDate &&
                                      new Date(
                                        googleCreateCampaignData?.google_Campaignsettings?.endDate,
                                      )
                                    }
                                    campaignBudget={
                                      googleCreateCampaignData
                                        ?.google_Campaignsettings
                                        ?.campaignBudget
                                    }
                                    getCampaignBudgetError={
                                      getCampaignBudgetError
                                    }
                                    getCurrentPageStatus={getCurrentPageStatus}
                                  />
                                  <CommonInfoComponent
                                    info={`For the month, you won't pay more than your daily budget times the average number of days in a month. Some days you might spend less than your daily budget, and on others you might spend up to twice as much.`}
                                  />
                                </Card>
                              )}
                              {/* Budget & dates end */}
                              {/* Location And Language start */}
                              {showLocationAndLanguage && (
                                <Card>
                                  <CardHeader>
                                    <div className='card-lt-pane'>
                                      <h5>Location and language</h5>
                                    </div>
                                  </CardHeader>
                                  <CommonInfoComponent
                                    info={[
                                      'We recommend setting location and language at the ad group level to better align with your other targeting and to make sure your budget is being allocated to where your ads are performing best.',
                                      'Setting location and language at the campaign level is only recommended if you need to target a radius around a location, and cannot be changed after publishing this campaign.',
                                      'Setting campaign level location and language will clear your ad group level location and language settings.',
                                    ]}
                                    iconClass={'icon-exclam'}
                                  />
                                  <LocationAndLanguage
                                    languageList={
                                      googleCreateCampaignData
                                        ?.google_Campaignsettings?.language
                                    }
                                    handleLangEnableDesable={
                                      handleLangEnableDesableVal
                                    }
                                    handleLanguageSelect={
                                      handleLanguageSelectVal
                                    }
                                    campaignLevelLocationLanguage={
                                      googleCreateCampaignData?.campaignLevelLocationLanguage
                                    }
                                    getCurrentPageStatus={getCurrentPageStatus}
                                  />
                                  <CommonInfoComponent
                                    info={`Language targeting allows you to restrict where your ads can appear based on the user's language settings and the language of the site. Ads can be shown to people who understand any, but not necessarily all of the languages you select.`}
                                  />
                                </Card>
                              )}
                              {/* Location And Language end */}
                              {/* Demand Gen Devices start */}
                              {showDeviceWidget && (
                                <Card>
                                  <CardHeader>
                                    <div className='card-lt-pane'>
                                      <h5>Devices</h5>
                                    </div>
                                  </CardHeader>
                                  <CardBody>
                                    <div className='action-btn-both action-btn-both-check row relative'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          id='first-device-options'
                                          onChange={handleDeviceTye}
                                          value={'ShowAllDevices'}
                                          checked={
                                            googleCreateCampaignData?.DeviceType ==
                                            'ShowAllDevices'
                                              ? true
                                              : false
                                          }
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='first-device-options'
                                        >
                                          Show on all eligible devices
                                          (computers, mobile, tablet, and TV
                                          screens){' '}
                                        </label>
                                      </div>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          id='second-device-option'
                                          onChange={handleDeviceTye}
                                          value={'SpecificTargetingDevices'}
                                          checked={
                                            googleCreateCampaignData?.DeviceType ==
                                            'SpecificTargetingDevices'
                                              ? true
                                              : false
                                          }
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='second-device-option'
                                        >
                                          Set specific targeting for devices{' '}
                                        </label>
                                      </div>
                                    </div>
                                    {/* Operating system start */}
                                    {googleCreateCampaignData?.DeviceType ==
                                      'SpecificTargetingDevices' && (
                                      <>
                                        <ReactSelect
                                          options={[
                                            {
                                              label: 'Computers',
                                              value: 'COMPUTERS',
                                            },
                                            {
                                              label: 'Mobile phones',
                                              value: 'MOBILE',
                                            },
                                            {
                                              label: 'Tablets',
                                              value: 'TABLET',
                                            },
                                            {
                                              label: 'TV screens',
                                              value: 'CONNECTED_TV',
                                            },
                                          ]}
                                          onChange={handleSetSpecificTargeting}
                                          closeMenuOnSelect={true}
                                          isMulti={true}
                                          value={googleCreateCampaignData?.specificTargetingDevices?.map(
                                            (i) => {
                                              return { label: i, value: i };
                                            },
                                          )}
                                        />
                                        {googleCreateCampaignData
                                          ?.specificTargetingDevices?.length ==
                                          1 &&
                                        googleCreateCampaignData
                                          ?.specificTargetingDevices?.[0] ==
                                          'COMPUTERS' ? (
                                          <></>
                                        ) : (
                                          <>
                                            <CardHeader>
                                              <div className='card-lt-pane'>
                                                <h5>Operating systems</h5>
                                              </div>
                                            </CardHeader>
                                            {googleCreateCampaignData?.devicesState?.map(
                                              (item, index) => (
                                                <Devices
                                                  listing={
                                                    googleCreateCampaignData?.getOperatingSystems
                                                  }
                                                  minorOptions={
                                                    googleCreateCampaignData?.getOperatingSystemsMinorOptions
                                                  }
                                                  majorOptions={
                                                    googleCreateCampaignData?.getOperatingSystemsMajorOptions
                                                  }
                                                  handleSelectOption={(e) =>
                                                    handleSelectOperatingSystem(
                                                      e,
                                                      index,
                                                    )
                                                  }
                                                  handleSelectFinalOption={(
                                                    e,
                                                  ) =>
                                                    handleSelectSectionOptionVal(
                                                      e,
                                                      index,
                                                    )
                                                  }
                                                  handleFinalSelectedOptions={(
                                                    e,
                                                  ) =>
                                                    handleSelectedOSFinalOptions(
                                                      e,
                                                      index,
                                                    )
                                                  }
                                                  index={index}
                                                />
                                              ),
                                            )}
                                            <div className='add-section'>
                                              <button
                                                className='btn-add-plus'
                                                onClick={
                                                  handleAddOperatingSystem
                                                }
                                              >
                                                <i className='icon-plus-circle' />{' '}
                                                Add more Operating system
                                              </button>
                                            </div>
                                            {/* Operating system end */}
                                            {/* Devicde models start */}
                                            <CardHeader>
                                              <div className='card-lt-pane'>
                                                <h5>Device Models</h5>
                                              </div>
                                            </CardHeader>
                                            {googleCreateCampaignData?.devicesModelState?.map(
                                              (item, index) => (
                                                <Devices
                                                  listing={
                                                    googleCreateCampaignData?.getDeviceModelsLists
                                                  }
                                                  minorOptions={
                                                    googleCreateCampaignData?.getSubDeviceOptions
                                                  }
                                                  majorOptions={
                                                    googleCreateCampaignData?.getFinalDeviceOptions
                                                  }
                                                  handleSelectOption={(e) =>
                                                    handleSelectDeviceModel(
                                                      e,
                                                      index,
                                                    )
                                                  }
                                                  handleSelectFinalOption={(
                                                    e,
                                                  ) =>
                                                    handleFinalDevice(e, index)
                                                  }
                                                  handleFinalSelectedOptions={(
                                                    e,
                                                  ) =>
                                                    handleSelectedDeviceModelFinalOptions(
                                                      e,
                                                      index,
                                                    )
                                                  }
                                                  firstDropdownVal={[
                                                    {
                                                      label:
                                                        googleCreateCampaignData
                                                          ?.devicesModelState[
                                                          index
                                                        ]?.selectedDeviceModel,
                                                      value:
                                                        googleCreateCampaignData
                                                          ?.devicesModelState[
                                                          index
                                                        ]?.selectedDeviceModel,
                                                    },
                                                  ]}
                                                  secondDropdownVal={[
                                                    {
                                                      label:
                                                        googleCreateCampaignData
                                                          ?.devicesModelState[
                                                          index
                                                        ]?.selectedSubChild,
                                                      value:
                                                        googleCreateCampaignData
                                                          ?.devicesModelState[
                                                          index
                                                        ]?.selectedSubChild,
                                                    },
                                                  ]}
                                                  thirdDropdownVal={[
                                                    {
                                                      label:
                                                        googleCreateCampaignData
                                                          ?.devicesModelState[
                                                          index
                                                        ]?.selectedFinalChild,
                                                      value:
                                                        googleCreateCampaignData
                                                          ?.devicesModelState[
                                                          index
                                                        ]?.googleSalesDevicesId,
                                                    },
                                                  ]}
                                                  index={index}
                                                  getCurrentPageStatus={
                                                    getCurrentPageStatus
                                                  }
                                                />
                                              ),
                                            )}
                                            <div className='add-section'>
                                              <button
                                                className='btn-add-plus'
                                                onClick={handleAddDeviceModel}
                                              >
                                                <i className='icon-plus-circle' />{' '}
                                                Add more Device models
                                              </button>
                                            </div>
                                            <CardHeader>
                                              <div className='card-lt-pane'>
                                                <h5>Networks</h5>
                                              </div>
                                            </CardHeader>
                                            <ReactSelect
                                              options={
                                                googleCreateCampaignData?.network
                                              }
                                              isMulti={true}
                                              isSearchable={true}
                                              onChange={handleNetworksVal}
                                              closeMenuOnSelect={true}
                                              value={
                                                googleCreateCampaignData?.networkIdObj
                                              }
                                              // value={googleCreateCampaignData?.network?.find(
                                              //   (item) =>
                                              //     item?.id ==
                                              //     googleCreateCampaignData?.networkId
                                              //       ?.googleNetworkid,
                                              // )}
                                            />
                                          </>
                                        )}
                                      </>
                                    )}
                                    {/* Devicde models end */}
                                    <CommonSmallDescription
                                      copy={`Showing ads on all devices helps expand your reach. To focus your reach on specific devices, set device targeting.`}
                                    />
                                  </CardBody>
                                </Card>
                              )}
                              {/* Demand Gen Devices end */}
                              {/* Budget with daily and campaign total starts */}
                              {showBudgetWithType && (
                                <>
                                  <Card>
                                    <CardHeader>
                                      <h5>Campaign Budget</h5>
                                      <h6 className='d-flex align-items-center'>
                                        Budget type and amount
                                      </h6>
                                    </CardHeader>
                                    <CardBody>
                                      <div className='card-form form-panel'>
                                        <div className='row align-items-center'>
                                          <div className='form-item col-6'>
                                            <div className='label-icon'>
                                              <label>Budget Type</label>
                                            </div>
                                            <SimpleSelectBox
                                              name='kpi'
                                              placeholder='Select'
                                              options={[
                                                'Daily',
                                                'Campaign total',
                                              ]}
                                            ></SimpleSelectBox>
                                          </div>

                                          <div className='form-item input-group-label col-6'>
                                            <div className='budget-cont'>
                                              <div className='label-icon'>
                                                <label>Budget</label>
                                              </div>
                                              <div className='input-group'>
                                                {/* <span className="input-group-text rs-text">
                                                                             <i className="icon-rupee" />
                                                                         </span> */}
                                                <input
                                                  type='email'
                                                  id='email'
                                                  placeholder='X.XX'
                                                  name='kpi goal'
                                                />
                                                <span className='input-group-text inr-text rs-text'>
                                                  INR
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </>
                              )}
                              {/* Budget with daily and campaign total ends */}
                              {/* Data feed Start Here */}
                              {showDataFeedApp && (
                                <>
                                  <div className='card-primary-repet'>
                                    <div className='card-primary'>
                                      <div className='card-header-top d-flex'>
                                        <div className='card-lt-pane'>
                                          <h5>Data feed</h5>
                                        </div>
                                      </div>
                                      <div className='card-body'>
                                        <div className='card-form form-panel'>
                                          <div className='row align-items-center'>
                                            <div className='action-btn-cont'>
                                              <div className='action-btn-both action-btn-no-icon row'>
                                                <div className='col-12 px-0'>
                                                  <div className='form-check-box'>
                                                    <input
                                                      className='form-check-input'
                                                      type='checkbox'
                                                      name='flexRadioDefault'
                                                      id='flexRadioDefault49Datafeed'
                                                      defaultChecked={
                                                        googleCreateCampaignData?.isDataFeedForApp
                                                      }
                                                      onChange={dataFeedForApp}
                                                    />
                                                    <label
                                                      className='form-check-label'
                                                      htmlFor='flexRadioDefault49Datafeed'
                                                    >
                                                      Attach a feed to improve
                                                      targeting and enable ads
                                                      that feature your feed
                                                      items
                                                      <p>
                                                        {/* Select your mobile app's platform */}
                                                      </p>
                                                    </label>
                                                  </div>
                                                  <div className='dv-from-check-field'>
                                                    <div className='action-btn-cont'>
                                                      <div
                                                        className='action-btn-both action-btn-no-icon row'
                                                        onChange={
                                                          FeedTypeHandler
                                                        }
                                                      >
                                                        <div className='col-12 px-0'>
                                                          <div className='form-check-box'>
                                                            <input
                                                              className='form-check-input'
                                                              type='radio'
                                                              name='flexRadioDefault'
                                                              id='flexRadioDefault50Datafeed'
                                                              defaultChecked={
                                                                googleCreateCampaignData?.DataFeedTypeForApp ===
                                                                'Dynamic_ad_feed'
                                                              }
                                                              value={
                                                                'Dynamic_ad_feed'
                                                              }
                                                            />
                                                            <label
                                                              className='form-check-label'
                                                              htmlFor='flexRadioDefault50Datafeed'
                                                            >
                                                              Dynamic ad feed
                                                            </label>
                                                          </div>
                                                        </div>
                                                        <div className='col-12 px-0'>
                                                          <div className='form-check-box'>
                                                            <input
                                                              className='form-check-input'
                                                              type='radio'
                                                              name='flexRadioDefault'
                                                              id='flexRadioDefault51Datafeed'
                                                              defaultChecked={
                                                                googleCreateCampaignData?.DataFeedTypeForApp ===
                                                                'merchent_center_ad_feed'
                                                              }
                                                              value={
                                                                'merchent_center_ad_feed'
                                                              }
                                                            />
                                                            <label
                                                              className='form-check-label'
                                                              htmlFor='flexRadioDefault51Datafeed'
                                                            >
                                                              Google Merchant
                                                              Center feed
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <CardHeader>
                                                      <div class='card-lt-pane'>
                                                        <h6>
                                                          Select a Google
                                                          Merchant Center
                                                          account with the
                                                          products featured in
                                                          your app
                                                        </h6>
                                                      </div>

                                                      <div className='form-item'>
                                                        <div className='label-icon'>
                                                          <label>
                                                            Google Merchant
                                                            Center account
                                                          </label>
                                                        </div>
                                                        {/* <div className="select-multiple-components"> */}
                                                        <ReactSelect
                                                          options={
                                                            googleCreateCampaignData
                                                              ?.google_Campaignsettings
                                                              ?.merchentCenterAccountList
                                                          }
                                                          isMulti={false}
                                                          isSearchable={true}
                                                          onChange={
                                                            merchantCenterAccountHandler
                                                          }
                                                          closeMenuOnSelect={
                                                            true
                                                          }
                                                          value={googleCreateCampaignData?.google_Campaignsettings?.merchentCenterAccountList.find(
                                                            (item) =>
                                                              item.value ==
                                                              googleCreateCampaignData?.Merchent_center_account,
                                                          )}
                                                        />
                                                        {/* </div> */}
                                                      </div>
                                                    </CardHeader>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {/* Data feed for app Ends Here */}
                              {/* Budget starts here */}
                              {showBudget && (
                                <>
                                  <Card>
                                    <CardHeader>
                                      <h5>Budget</h5>
                                      <p>
                                        Select the average you want to spend
                                        each day.
                                      </p>
                                    </CardHeader>
                                    <CardHeader>
                                      <h6 class='d-flex align-items-center'>
                                        Set your average daily budget for this
                                        campaign
                                        <span
                                          class='label-information tooltips'
                                          tooltip='tootltip'
                                          tooltip-position='top'
                                        >
                                          {/* <i class='icon-info'></i> */}
                                          <span></span>
                                        </span>
                                      </h6>
                                    </CardHeader>
                                    <CardBody>
                                      <div class='card-form form-panel'>
                                        <div class='row align-items-center'>
                                          <div class='form-item input-group-label col-6'>
                                            <div class='budget-cont'>
                                              <div class='label-icon'>
                                                <label>Amount</label>
                                              </div>
                                              <div class='input-group'>
                                                <span class='input-group-text rs-text'>
                                                  <i class='icon-rupee'></i>
                                                </span>
                                                <input
                                                  type='number'
                                                  id='email'
                                                  placeholder='X.XX'
                                                  name='campaignBudget'
                                                  min='0'
                                                  step='any'
                                                  value={
                                                    googleCreateCampaignData
                                                      ?.google_Campaignsettings
                                                      ?.campaignBudget
                                                  }
                                                  onChange={
                                                    handleChangeCampBudget
                                                  }
                                                  onFocus={handleFocus}
                                                  onKeyDown={blockInvalidChar}
                                                />
                                                <span class='input-group-text inr-text rs-text'>
                                                  INR
                                                </span>
                                              </div>
                                            </div>
                                            <span
                                              className='error'
                                              id='error-budget'
                                            >
                                              {formErrors?.budget}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </>
                              )}
                              {/* Budget ends here */}
                              {/* video enhance Start Here */}
                              {showVideoEnhancement && (
                                <>
                                  <div className='card-primary-repet'>
                                    <div className='card-primary'>
                                      <div className='card-header-top'>
                                        <div className='card-lt-pane'>
                                          <h5>Video enhancements</h5>

                                          <div className='action-btn-both action-btn-both-check row'>
                                            <div className='form-check-box'>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                id='inlineCheckbox1Videoenhancements'
                                                defaultValue='option1'
                                              />
                                              <label
                                                className='form-check-label'
                                                htmlFor='inlineCheckbox1Videoenhancements'
                                              >
                                                Allow Google to generate
                                                enhanced versions of your video
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='card-body'>
                                        <div className='info-icon-body'>
                                          <div className='more-info-icon'>
                                            <i className='icon-bulb' />
                                          </div>
                                          <div className='more-info-text'>
                                            <p>
                                              Get enhanced versions of your
                                              video for a better viewer
                                              experience.
                                            </p>
                                            <p>
                                              View enhanced videos, track their
                                              performance, or delete them
                                              anytime on your Videos page.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        {/* video enhance Ends Here */}
                      </article>
                    </div>
                  </div>

                  {/* view starts */}
                  <div
                    className='tab-pane fade'
                    id='review'
                    role='tabpanel'
                    aria-labelledby='review-tab'
                  >
                    <div className='rv-details-cont'>
                      <div className='card-primary-repet'>
                        <div className='card-primary'>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Campaign name</h5>
                              <p>New Awareness Campaign</p>
                              <p>ID: 23857805967620728</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Special ad categories</h5>
                              <p>No categories declared</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Buying type</h5>
                              <p>Awareness</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Ad sets</h5>
                              <p className='add-data'>Please add: Ad sets</p>
                            </div>
                          </div>
                          <div className='card-header-top d-flex'>
                            <div className='card-lt-pane'>
                              <h5>Format</h5>
                              <p>Image and Video</p>
                            </div>
                            <div className='card-rt-pane d-flex me-auto align-items-center'>
                              <figure className='prev-icon'>
                                <img src={atdplaceholder} />
                              </figure>
                              <button
                                type='button'
                                className='btn-icon btn-icon-primary btn-bedcrumb-act me-0'
                              >
                                Preview
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* view ends */}
                  <div
                    className='tab-pane fade'
                    id='history'
                    role='tabpanel'
                    aria-labelledby='history-tab'
                  ></div>
                </div>
              </article>
            </section>
          </div>
          {/* {params['id'] && (
            <CommonModal
              show={showModal}
              handleClose={() => setShowModal(false)}
              handleRedirection={() => handleRedirection()}
              copy={'Campaign has been successfully saved.'}
              isUpdatedStatus={params['id']}
            />
          )} */}
          <Footer
            prevPath={GoogleAdOptions.Campaigns.newGoogleCampaign}
            // nextPath="/google-ad-group"
            handleSubmit={handleNext}
            nextBtnlabel={params['id'] ? 'Save' : 'Next'}
            getAtdStatus={googleCreateCampaignData?.atdStatus}
          >
            {' '}
          </Footer>
          {isDraft && (
            <CommonModal
              show={isDraft}
              handleClose={() => handleCloseAndRedirectToEdit(false)}
              handleRedirection={() => {
                navigate(`${GoogleAdOptions?.Campaigns.url}`);
              }}
              copy={'Your google campaign successfully saved.'}
              isUpdatedStatus={true}
            />
          )}
          {getApprovalPublisherModelShowStatus && (
            <SelectApprovalPublisher
              type={'Google'}
              handleApprovalPublisher={handleApprovalPublisher}
              handleSetPublisherId={handleSetPublisherId}
              getPublisherInfo={getPublisherInfo}
              getPublisherError={getPublisherError}
              handleCloseModel={handleCloseModel}
            />
          )}
          {getApprovalCoinfirmationPopupShowStatus && (
            <ApprovalConfirmationPopup
              type={'Google'}
              handleApprovalPublisherRequest={handleApprovalPublisherRequest}
              handleCloseModel={handleCloseModel}
              isPublisher={isPublisher}
            />
          )}
          {getThankYouApprovalPopupShowStatus && (
            <ThankYouApprovalPopup
              handleRedirect={handleRedirection}
              type={'Google'}
              approved={isPublisher ? 'approved' : ''}
            />
          )}
          {loader && <Loader />}
        </div>
      </div>
    </div>
  );
}

export default CreateGoogleAdCampaign;
