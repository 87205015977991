import { Link } from 'react-router-dom';

const DisplaySelectedWidgetItem = (props) => {
  return (
    <>
      {props?.item &&
        props?.item?.map((val) => (
          <div
            className='slideshow-box-group-rept'
            key={val?.mediaId || val?.medId}
          >
            <div className='slideshow-box d-flex'>
              <div className='slideshow-imgbox d-flex align-items-center'>
                <img
                  src={
                    val?.fileType == 'Youtube' || val?.medType == 'Youtube'
                      ? val?.medThumbnailPath || val?.mediaThumbnail
                      : val?.mediaThumbnail || val?.medPath
                  }
                  alt={val?.mediaName || val?.medFileName}
                />
              </div>
              <div className='slideshow-cont'>
                <div className='slideshow-cont-top'>
                  <h3 className='tag-link'>
                    {val?.fileType == 'Youtube' || val?.medType == 'Youtube' ? (
                      <Link
                        to={val?.youtubeUrl}
                        target='_blank'
                        className='url-txt'
                      >
                        {val?.mediaName || val?.medFileName}
                      </Link>
                    ) : val?.fileType == 'Video' ||
                      val?.medType == 'Video' ||
                      val?.fileType == 'Image' ||
                      val?.medType == 'Image' ? (
                      <>{val?.mediaName || val?.medFileName}</>
                    ) : (
                      <>{val?.mediaName || val?.medFileName}</>
                    )}
                  </h3>
                </div>
              </div>
              <button
                className='close-slideshow'
                onClick={(e) => props?.handledeleteItem(val?.medId)}
              >
                <i className='icon-close' />
              </button>
            </div>
          </div>
        ))}
    </>
  );
};

export default DisplaySelectedWidgetItem;
