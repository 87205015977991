import React from 'react';
import { Link } from 'react-router-dom';
function Footer(props) {
  return (
    <>
      <footer className='footer-cont media-section-left'>
        <div className='footer-main'>
          <div className='footer-btn'>
            <div className='footer-left-cont'>
              <Link
                to={props?.closePath}
                className='primary-btn footer-close-btn'
              >
                Close
              </Link>
            </div>
            {props.nextBtnlabel && (
              <div className='footer-right-cont'>
                {props?.getAtdStatus == 'In Moderation' ||
                props?.getAtdStatus == 'in_moderation' ? (
                  <Link
                    className='primary-btn footer-close-btn'
                    title='Entry under moderation: Editing disabled until review completion.'
                  >
                    {props.nextBtnlabel}
                  </Link>
                ) : (
                  <Link
                    to={props?.nextPath}
                    className='primary-btn'
                    onClick={props.handleSubmit}
                  >
                    {props.nextBtnlabel}
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
