import ErrorDisplay from '../common/ErrorDisplay';
import SimpleSelectBox from '../common/SimpleSelectBox';
import CommonBudget from '../common/commonBudget';

const ControlBidAndBudgetCase = (props) => {
  return (
    <div className='card-body'>
      <div className='card-form form-panel'>
        <div className='row align-items-center'>
          <div className='action-btn-cont'>
            <div className='action-btn-both action-btn-no-icon row'>
              <div className='col-12 px-0'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name="Unlimited up to the insertion order's budget"
                    id='flexRadioDefault500'
                    defaultValue="Unlimited up to the insertion order's budget"
                    onChange={(e) => props?.handleBudgetPacingType(e)}
                    checked={
                      props?.budget_spacing_type ==
                      "Unlimited up to the insertion order's budget"
                        ? true
                        : false
                    }
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexRadioDefault500'
                  >
                    {' '}
                    Unlimited up to insertion order's budget.
                  </label>
                </div>
              </div>
              <div className='col-12 px-0 d-flex'>
                <div className='form-check-box'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='custom_set'
                    id='flexRadioDefault501'
                    defaultValue='custom_set'
                    onChange={(e) => props?.handleBudgetPacingType(e)}
                    checked={
                      props?.budget_spacing_type == 'custom_set' ? true : false
                    }
                  />
                </div>

                <div className='card-form form-panel form-panel-group-add mt-0 form-panel-no-border ps-5'>
                  <div className='form-item input-group-label col-5'>
                    <div className='budget-cont'>
                      <div className='label-icon'>
                        <label>Amount</label>
                      </div>
                      <div className='input-group'>
                        <span className='input-group-text rs-text'>
                          <i className='icon-rupee' />
                        </span>
                        <input
                          type='text'
                          id='email'
                          placeholder='X.XX'
                          name='Planned spend'
                          onChange={props?.handleBudgetPacingAmount}
                          readOnly={
                            props?.budget_spacing_type == 'custom_set'
                              ? false
                              : true
                          }
                          value={props?.budget_spacing_amount}
                        />
                        <span className='input-group-text inr-text rs-text'>
                          INR
                        </span>
                        <ErrorDisplay val={props?.numberCheckPacingAmount} />
                      </div>
                    </div>
                  </div>
                  <span className='error-message' style={{ color: 'red' }}>
                    {props?.budget_spacing_type_error}
                  </span>
                  <div className='row align-items-center'>
                    <div className='form-item col-box-3'>
                      <div className='label-icon'>
                        <label>Select</label>
                      </div>
                      <div className='select-box'>
                        <SimpleSelectBox
                          name='drinks'
                          placeholder='Select'
                          options={props?.commonOptions}
                          onChangeHandler={props?.handleBudgetSpacingFirstType}
                          value={props?.budget_spacing_first_type}
                        ></SimpleSelectBox>
                      </div>
                      <ErrorDisplay
                        val={props?.budget_spacing_drop_down_check}
                      />
                    </div>
                    <div className='form-item col-box-3'>
                      <div className='label-icon'>
                        <label>Select</label>
                      </div>
                      <div className='select-box'>
                        <SimpleSelectBox
                          name='drinks'
                          placeholder='Select'
                          options={
                            props?.budget_spacing_type ==
                              "Unlimited up to the insertion order's budget" &&
                            props?.budget_spacing_first_type == 'Flight'
                              ? props?.budgetSpacingUnlimitedUpToFlightOptions
                              : props?.budget_spacing_type ==
                                    "Unlimited up to the insertion order's budget" &&
                                  props?.budget_spacing_first_type == 'Daily'
                                ? props?.budgetSpacingUnlimitedUpToDailyOptions
                                : props?.budget_spacing_type == 'custom_set' &&
                                    props?.budget_spacing_first_type == 'Flight'
                                  ? props?.budgetSpacingUnlimitedUpToFlightNumericOptions
                                  : props?.budget_spacing_type ==
                                        'custom_set' &&
                                      props?.budget_spacing_first_type ==
                                        'Daily'
                                    ? props?.budgetSpacingUnlimitedUpToDailyNumericOptions
                                    : []
                          }
                          value={props?.budget_spacing_second_type}
                          onChangeHandler={props?.handleBudgetSpacingSecondType}
                        ></SimpleSelectBox>
                      </div>
                      <ErrorDisplay
                        val={props?.budget_spacing_drop_down_check}
                      />
                    </div>
                    {props?.budget_spacing_first_type == 'Daily' && (
                      <div className='form-item input-group-label col-box-3 col-box-3-inr'>
                        <CommonBudget
                          handler={props?.handleDailyPrice}
                          value={props?.budget_spacing_daily_price}
                          err={props?.numberCheckDailySpacing}
                          dailySpacingValidation={props?.dailySpacingValidation}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ControlBidAndBudgetCase;
