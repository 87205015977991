// import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import '../../assets/css/style.css';
import Header from '../../components/common/Header';
import Sidebar from '../../components/common/Sidebar';
import Card from '../../components/common/Card';
import CardHeader from '../../components/common/CardHeader';
import CardBody from '../../components/common/CardBody';
import SimpleSelectBox from '../../components/common/SimpleSelectBox';
import InfoText from '../../components/common/InfoText';
import Footer from '../../components/common/Footer';
import { useEffect, useState } from 'react';
import AdvancedCardHeader from '../../components/common/AdvancedCardHeader';
import moment from 'moment/moment';
import axios from 'axios';
import Budget from '../../components/common/Budget';
import ReactSelect from '../../components/common/ReactSelect';
import ErrorDisplay from '../../components/common/ErrorDisplay';
import CommonBudget from '../../components/common/commonBudget';
import { resetInsertionState } from '../../state/DVInsertionOrder/CreateCampaignActions';
import {
  multiSelectVal,
  lengthFieldCheck,
  prefillSelect,
  lineItemUrlFormat,
} from '../../utils/validations';

import {
  setInsertionOrderName,
  setSpacingFirstType,
  setSpacingSecondType,
  setBudgetSpacingDailyPrice,
  setGoalType,
  setGoalValue,
  setOptimizationType,
  setOptimizationAction,
  setOptimizationCPMValue,
  setPriortizeDealValue,
  setFrequencyCapType,
  setFrequencyVal,
  setExposuresType,
  setExposuresValue,
  setInsertionOrderLists,
  setInsertionOrderId,
  setAddBudget,
  setQuality,
  setPublicInvetory,
  setDealsAndInventoryPackages,
  setGroups,
  setBudgetAMount,
  setBudgetStartDate,
  setBudgetEndDate,
  removeBudget,
  setBudgetType,
  setAddBudgetType,
  preFillValues,
  setOptimizationCheckedStatus,
} from '../../state/DVInsertionOrder/CreateCampaignActions';
import {
  commonOptions,
  insertionOrderFlightsOptions,
  insertionOrderDailyOptions,
  insertionGoal,
  baseUrlStg,
  createInsertionOrderUrl,
  publicInventoryOptions,
  dealsAndInventoryPackagesOptions,
  groupsOptions,
  EnabledApproval,
} from '../../utils/constants';
import { dateFormat } from '../../utils/validations';
import Loader from '../../components/common/Loader';
import { getApi, postApi, putApi } from '../../utils/Apis';
import SideTreeMenuForCreate from '../../components/dv360/SideTreeMenuForCreate';
import CommonModal from '../../components/common/commonModal';
import DvErrorDisplay from '../../components/dv360/dvErrorDisplay';
import { getCookies } from '../../utils/utils';
import SelectApprovalPublisher from '../../components/common/SelectApprovalPublisher';
import ApprovalConfirmationPopup from '../../components/common/ApprovalConfirmationPopup';
import ThankYouApprovalPopup from '../../components/common/ThankYouApprovalPopup';
import { ApproveReject } from '../../components/ApproveReject/ApproveReject';
function InsertionOrder() {
  const [date, setdate] = useState('');
  const [date1, setdate1] = useState('');
  const [segmentCount, setSegmentCount] = useState(1);
  let getCookie = getCookies();
  const CustomHeader = {
    accessToken: 'Test',
    Authorization: getCookie?.substring(1, getCookie?.length - 1) || '',
  };
  const addSegment = () => {
    setSegmentCount(segmentCount + 1);
  };
  //Created By Prashant Chavan
  const [formErrors, setFormErrors] = useState({});
  const [numberCheckDailySpacing, setNumberCheckDailySpacing] = useState('');
  const [numberCheckGoalPacing, setNumberCheckGoalPacing] = useState('');
  const [numberCheckBudgetAMount, setNumberCheckBudgetAMount] = useState('');
  const [getFeqErr, setFeqErr] = useState('');
  const [campaignTreeData, setCampaignTreeData] = useState({});
  const [getQueryParam, setQueryParam] = useState({
    campaign_id: '',
    flag: '',
  });
  const [getGoalTypeVal, setGoalTypeVal] = useState();
  const [
    getNumberCheckOptimizationCPMValue,
    setNumberCheckOptimizationCPMValue,
  ] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);
  const [isUpdate, setIsUpdate] = useState('');
  const [getLineItemAudioStatus, setLineItemAudioStatus] = useState(0);
  const [getModerationStatus, setgetModerationStatus] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [UpdateTreeOnsaveDraft, setUpdateTreeOnsaveDraft] = useState(false);
  const [getMultipleSelectParam, setMultipleSelectParam] = useState('');
  const [showTreeMenu, setShowTreeMenu] = useState(false);
  const [insertCampaignID, setInsertCampaignID] = useState('');
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const location = useLocation();
  const dataFromRoute = location.state;
  const DvInsertionOrderData = state?.DvInsertionOrder;
  let re = /^[0-9\b]+$/;
  const budgetTypeOptions = ['INR', 'Impressions'];
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  const params = useParams();
  let header = {
    accessToken: 'Test',
  };
  const [
    getApprovalPublisherModelShowStatus,
    setApprovalPublisherModelShowStatus,
  ] = useState(false);
  const [
    getApprovalCoinfirmationPopupShowStatus,
    setApprovalCoinfirmationPopupShowStatus,
  ] = useState(false);
  const [
    getThankYouApprovalPopupShowStatus,
    setThankYouApprovalPopupShowStatus,
  ] = useState(false);
  const [getPublisherInfo, setPublisherInfo] = useState([]);
  const [getPublisherError, setPublisherError] = useState('');
  const [getCreateStatus, setCreateStatus] = useState(false);
  const [getCreateId, setCreateID] = useState('');
  const getUserInfo = state?.loginReducer?.userToken;
  const { handleAPICall } = ApproveReject();
  const [isPublisher, setIsPublisher] = useState(false);

  const onChangeHandler = () => {
    return true;
  };

  const handleInsertionOrderName = (event) => {
    const value = event;
    dispatch(setInsertionOrderName(value?.label));
    dispatch(setInsertionOrderId(value?.value));
  };

  const handleRedirect = () => {
    window.open(createInsertionOrderUrl, '_blank');
  };

  const handlePacingFirstType = (e) => {
    dispatch(setSpacingFirstType(e.target.value));
    dispatch(setBudgetSpacingDailyPrice(0));
  };

  const handlePacingSecondType = (e) => {
    dispatch(setSpacingSecondType(e.target.value));
  };

  const handleBudgetType = (e) => {
    dispatch(setBudgetType(e.target.value));
  };

  const handleDailyPrice = (e) => {
    let currentVal = e.target.value;
    if (/^0/.test(currentVal)) {
      currentVal.replace(/^0/, '');
      setNumberCheckDailySpacing('The value should be greater than 0');
    } else {
      if (e.target.value === '' || re.test(e.target.value)) {
        dispatch(setBudgetSpacingDailyPrice(e.target.value));
      } else {
        setNumberCheckDailySpacing('Enter a monetary value');
      }
    }
  };

  const handleGoalType = (e) => {
    dispatch(setGoalType(e.target.value));
    setGoalTypeVal(e.target.value);
  };

  // Prashant Chavan Code
  // const handleGoalAmount = (e) => {
  //   let currentVal = e.target.value;
  //   if (/^0/.test(currentVal)) {
  //     currentVal.replace(/^0/, '');
  //     setNumberCheckGoalPacing('The value should be greater than 0');
  //     setFormErrors({ ...formErrors, goal_value_error: '' });
  //   } else {
  //     if (e.target.value === '' || re.test(e.target.value)) {
  //       dispatch(setGoalValue(e.target.value));
  //     } else {
  //       setNumberCheckGoalPacing('Enter a monitory value');
  //       setFormErrors({ ...formErrors, goal_value_error: '' });
  //     }
  //   }
  // };

  const handleGoalAmount = (e) => {
    let currentVal = e.target.value;
    if (/^0/.test(currentVal)) {
      currentVal.replace(/^0/, '');
      setFormErrors({ ...formErrors, goal_value_error: '' });
      setNumberCheckGoalPacing('The value should be greater than 0');
    } else {
      if (e.target.value === '' || re.test(e.target.value)) {
        setNumberCheckGoalPacing('');

        dispatch(setGoalValue(e.target.value));
      } else {
        setNumberCheckGoalPacing('Enter a monitory value');
        setFormErrors({ ...formErrors, goal_value_error: '' });
      }
    }
  };

  const handleOptimizationType = (e) => {
    if (e?.target?.value == 'Control bid and budget at the line item level') {
      dispatch(setOptimizationAction(''));
    }
    dispatch(setOptimizationType(e.target.value));
  };

  const handleOptimizationAction = (e) => {
    dispatch(setOptimizationAction(e.target.value));
    dispatch(setOptimizationCheckedStatus(e.target.checked));
  };

  const handleOptimizationCPMValue = (e) => {
    let currentVal = e.target.value;
    if (/^0/.test(currentVal)) {
      currentVal.replace(/^0/, '');
      setNumberCheckOptimizationCPMValue('The value should be greater than 0');
    } else {
      if (e.target.value === '' || re.test(e.target.value)) {
        dispatch(setOptimizationCPMValue(e.target.value));
      } else {
        setNumberCheckOptimizationCPMValue('Enter a monetary value');
      }
    }
  };

  const handleOptimizationPrioritizeDeal = (e) => {
    if (e.target.checked) {
      dispatch(setPriortizeDealValue(e.target.checked));
    } else {
      dispatch(setPriortizeDealValue(e.target.checked));
    }
  };

  const handleLimitFrequencey = (e) => {
    dispatch(setFrequencyCapType(e.target.value));
  };

  const handleFrequencyVal = (e) => {
    let currentVal = e.target.value;
    if (/^0/.test(currentVal)) {
      currentVal.replace(/^0/, '');
      setFeqErr('The value should be greater than 0');
    } else {
      if (e.target.value === '' || re.test(e.target.value)) {
        dispatch(setFrequencyVal(e.target.value));
      } else {
        setFeqErr('Enter a monetary value');
      }
    }
  };

  const handleExposureVal = (e) => {
    dispatch(setExposuresType(e.target.value));
    dispatch(setExposuresValue(''));
  };

  const handlesExposuresValue = (e) => {
    dispatch(setExposuresValue(e.target.value));
  };

  const handleAddBudget = (e) => {
    e.preventDefault();

    // Assuming 'budget_enddate' is in 'YYYY-MM-DD' format
    console.log(
      '------',
      DvInsertionOrderData?.budgets?.filter((item) => item?.status == 1)[
        DvInsertionOrderData?.budgets?.filter((item) => item?.status == 1)
          .length - 1
      ]['budget_enddate'],
    );
    // const budgetEndDate =
    //   DvInsertionOrderData?.budgets[DvInsertionOrderData?.budgets.length - 1][
    //     'budget_enddate'
    //   ];
    const budgetEndDate = DvInsertionOrderData?.budgets?.filter(
      (item) => item?.status == 1,
    )[
      DvInsertionOrderData?.budgets?.filter((item) => item?.status == 1)
        .length - 1
    ]['budget_enddate'];

    // Validate and convert 'budget_enddate' to a valid date format
    let convertedDate;
    try {
      convertedDate = new Date(budgetEndDate);
    } catch (error) {
      console.error('Invalid date format. Please use the "YYYY-MM-DD" format.');
      return;
    }

    // Call the 'setAddBudget' function with the converted date
    dispatch(setAddBudget(convertedDate));
  };

  const handleBudgetAmount = (e, index) => {
    let currentVal = e.target.value;
    if (/^0/.test(currentVal)) {
      currentVal.replace(/^0/, '');
      setNumberCheckBudgetAMount('The value should be greater than 0');
      setFormErrors({ ...formErrors, budget_value_error: '' });
    } else {
      if (e.target.value === '' || re.test(e.target.value)) {
        dispatch(setBudgetAMount(e.target.value, index));
        // dispatch(setAddBudgetType(DvInsertionOrderData?.budget_type, index));
      } else {
        setNumberCheckBudgetAMount('Enter a monetary value');
        setFormErrors({ ...formErrors, budget_value_error: '' });
      }
    }
  };

  const startDateHandler = (e, index) => {
    dispatch(setBudgetStartDate(e, index));
    dispatch(setBudgetEndDate(e, index));
  };

  const endDateHandler = (e, index) => {
    dispatch(setBudgetEndDate(e, index));
  };

  const handleDeleteBudget = (index) => {
    dispatch(removeBudget(index));
  };

  const handleQualityVal = (e) => {
    dispatch(setQuality(e.target.value));
  };

  const handlePublicInventory = (val) => {
    let values = multiSelectVal(val);
    dispatch(setPublicInvetory(values));
  };

  const handleDealInventory = (val) => {
    let values = multiSelectVal(val);
    dispatch(setDealsAndInventoryPackages(values));
  };

  const handleGroups = (val) => {
    let values = multiSelectVal(val);
    dispatch(setGroups(values));
  };

  const getOrdersLists = async () => {
    try {
      // const response = await axios.get(
      //   `${baseUrlStg}/api/Taxonomy/GetCampaignListByIdentifier?campaignIdentifier=dv360`,
      // );
      let response = await getApi(
        `${baseUrlStg}/api/Taxonomy/GetCampaignListByIdentifier?campaignIdentifier=dv360`,
      );
      let data = response?.map((item) => {
        return { label: item?.campignName, value: item?.campaignId };
      });
      dispatch(setInsertionOrderLists(data));
    } catch (err) {
      console.log(err);
    }
  };

  //side tree menu data - create flow -start
  const handleSideTreeDataAndUpdate = async () => {
    if (query.get('campaign_id')) {
      // New create case
      let response;
      if (query.get('campaign_id')) {
        response = await getApi(
          `${baseUrlStg}/api/Campaign/GetCampaignWithInsertionOrderAndLineItemByID?campaignID=${query.get(
            'campaign_id',
          )}`,
          { accessToken: '1234' },
        );
      }
      try {
        if (response?.response?.length > 0) {
          setCampaignTreeData(response?.response?.[0]);
          setShowTreeMenu(true);
        }
      } catch (err) {
        console.log(err);
      }
    } else if (params['id'] != undefined) {
      // Edit or Update case
      let response = await getApi(
        `${baseUrlStg}/api/InsertionOrder/getInsertionOrderByID?insertionOrderID=${params['id']}`,
        { accessToken: '1234' },
      );

      if (response?.status) {
        setDisplayLoader(false);
        dispatch(preFillValues(response?.response));
        setInsertCampaignID(response?.response['campaign_id']);
        setgetModerationStatus(response?.response['moderation_status']);
        let checkLineItem = response?.response['lineItems']?.filter(
          (v) => v.lineItem_type == 'audio',
        );
        setLineItemAudioStatus(checkLineItem?.length);

        // Create Line Item page url in Multiple case.
        const redirectUrl =
          response?.response?.optimization_action == ''
            ? `/dv-360/create-line-item/new?insertion_order_id=${response?.response?.insertion_order_id}&campaign_id=${response?.response?.campaign_id}&optimization=${response?.response?.optimization_type}&cID=${response?.response?.taxonomy_campaign_id}&flag=multiple`
            : `/dv-360/create-line-item/new?insertion_order_id=${response?.response?.insertion_order_id}&campaign_id=${response?.response?.campaign_id}&optimization=${response?.response?.optimization_type},${response?.response?.optimization_action}&cID=${response?.response?.taxonomy_campaign_id}&flag=multiple`;

        setMultipleSelectParam(redirectUrl);
      }
      let campaignid = await response?.response?.campaign_id;
      let responsee = await getApi(
        `${baseUrlStg}/api/Campaign/GetCampaignWithInsertionOrderAndLineItemByID?campaignID=${campaignid}`,
        { accessToken: '1234' },
      );
      try {
        if (responsee?.response?.length > 0) {
          setCampaignTreeData(responsee?.response?.[0]);
          setShowTreeMenu(true);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setCampaignTreeData({});
      setShowTreeMenu(true);
    }
  };

  useEffect(() => {
    handleSideTreeDataAndUpdate();
    setShowTreeMenu(false);
    setIsUpdate(params['id']);
    setQueryParam({
      ...getQueryParam,
      flag: query.get('flag'),
    });
  }, [query.get('campaign_id'), params['id'], UpdateTreeOnsaveDraft]);

  useEffect(() => {
    getOrdersLists();
    setQueryParam({
      ...getQueryParam,
      campaign_id: query.get('campaign_id'),
      flag: query.get('flag'),
    });
  }, []);

  const getBudgetSmallDate = (values) => {
    let newArray = [];

    // Push values to array
    values.map((v) => {
      newArray.push(v['budget_startdate']);
    });
    //Get a small date from the array lists
    let smallBudgetDate = newArray.reduce(function (a, b) {
      return a < b ? a : b;
    });

    return smallBudgetDate;
  };

  const getBudgetLargeDate = (values) => {
    let newArray = [];

    // Push values to array
    values.map((v) => {
      newArray.push(v['budget_enddate']);
    });

    //Get Large date from the array lists
    let largeBudgetDate = newArray.reduce(function (a, b) {
      return a > b ? a : b;
    });

    return largeBudgetDate;
  };

  const handlePostOrder = async (payload) => {
    try {
      const getSelectedOptimization =
        DvInsertionOrderData?.optimization_type.replace('&', 'and');

      const headers = {
        accessToken: 'Test',
        ...CustomHeader,
        EnabledApproval: EnabledApproval,
      };
      const response = await axios.post(
        `${baseUrlStg}/api/InsertionOrder/createInsertionOrder`,
        payload,
        {
          headers: headers,
          ...CustomHeader,
          EnabledApproval: EnabledApproval,
        },
      );
      if (response.data.status == 200 && getQueryParam?.flag == 'multiple') {
        if (getQueryParam?.flag == 'multiple' && EnabledApproval) {
          setApprovalPublisherModelShowStatus(true);
          setCreateStatus(true);
          setCreateID(response?.data?.insertionorderID);
        } else {
          setDisplayLoader(false);
          navigate(
            `/dv-360/create-insertion-order/${response?.data?.insertionorderID}`,
          );
        }
      } else {
        setDisplayLoader(false);
        const redirectUrl =
          DvInsertionOrderData?.optimization_action == ''
            ? `/dv-360/create-line-item/new?insertion_order_id=${response?.data?.insertionorderID}&campaign_id=${getQueryParam?.campaign_id}&optimization=${getSelectedOptimization}&cID=${DvInsertionOrderData?.taxonomy_campaign_id}`
            : `/dv-360/create-line-item/new?insertion_order_id=${response?.data?.insertionorderID}&campaign_id=${getQueryParam?.campaign_id}&optimization=${getSelectedOptimization},${DvInsertionOrderData?.optimization_action}&cID=${DvInsertionOrderData?.taxonomy_campaign_id}`;

        navigate(redirectUrl, {
          state: { isCreateFlow: dataFromRoute?.isCreateFlow },
        });
        dispatch(resetInsertionState());
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleUpdatePost = async (payload) => {
    header = {
      accessToken: 'Test',
    };

    const response = await putApi(
      `${baseUrlStg}/api/InsertionOrder/updateInsertionOrder`,
      '',
      payload,
      header,
    );

    if (response?.status == 200) {
      if (EnabledApproval) {
        setApprovalPublisherModelShowStatus(true);
        setDisplayLoader(false);
      } else {
        setShowModal(true);
        setDisplayLoader(false);
      }
      // dispatch(resetInsertionState());
    }
  };

  const handleRedirection = () => {
    navigate(`/dv-360/insertion-order-listing`);
  };

  const handleRedirectionToNextPage = (cID) => {
    navigate(
      `/dv-360/create-insertion-order/new?campaign_id=${cID}&flag=multiple`,
    );
  };

  const handleRedirectionToNextPageOfLineItem = (insertionOrder) => {
    const returnUrl = lineItemUrlFormat(insertionOrder);
    navigate(returnUrl);
  };

  useEffect(() => {
    if (typeof params['id'] == 'undefined') {
      dispatch(resetInsertionState());
    }
  }, []);

  // Prashant Chavan COde
  const handleSubmit = (e) => {
    setFormErrors(validate());
    // Get Small & Large date from the budget lists
    let getSmallDate = getBudgetSmallDate(DvInsertionOrderData?.budgets);
    let getLargeDates = getBudgetLargeDate(DvInsertionOrderData?.budgets);

    e.preventDefault();
    const getSelectedOptimization = DvInsertionOrderData?.optimization_type;
    // console.log('getSelectedOptimization', getSelectedOptimization);
    setTimeout(() => {
      if (Object.keys(validate()).length == 0) {
        setDisplayLoader(true);
        if (typeof isUpdate != 'undefined' && isUpdate != '') {
          const payload = {
            // line_item_id: 0,
            campaign_id: DvInsertionOrderData?.campaign_id,
            insertion_order_id: DvInsertionOrderData?.insertion_order_id,
            insertion_order_type: DvInsertionOrderData?.insertion_order_type,
            insertion_order_name: DvInsertionOrderData?.insertion_order_name,
            taxonomy_campaign_id: DvInsertionOrderData?.taxonomy_campaign_id,
            pacing_type: DvInsertionOrderData?.pacing_first_type,
            pacing_action: DvInsertionOrderData?.pacing_second_type,
            pacing_value: DvInsertionOrderData?.pacing_daily_price,
            goal_type: DvInsertionOrderData?.goal_type,
            goal_value: DvInsertionOrderData?.goal_value,
            billable_outcome: DvInsertionOrderData?.billable_outcome,
            optimization_type: DvInsertionOrderData?.optimization_type,
            optimization_action: DvInsertionOrderData?.optimization_action,
            optimization_CPM_value:
              DvInsertionOrderData?.optimization_CPM_value,
            optimization_priortize_deal:
              DvInsertionOrderData?.optimization_priortize_deal,
            frequency_cap_type: DvInsertionOrderData?.frequency_cap_type,
            frequency_limit:
              DvInsertionOrderData?.frequency_limit == ''
                ? 0
                : DvInsertionOrderData?.frequency_limit,
            frequency_limit_type: DvInsertionOrderData?.frequency_limit_type,
            frequency_cap_limit_value:
              DvInsertionOrderData?.frequency_cap_limit_value == ''
                ? 0
                : DvInsertionOrderData?.frequency_cap_limit_value,
            invsource_quality: DvInsertionOrderData?.quality,
            invsource_publicinventoryid: DvInsertionOrderData?.public_inventory,
            invsource_dealspackagesid:
              DvInsertionOrderData?.deals_and_inventory_packages,
            invsource_groupid: DvInsertionOrderData?.groups,
            budgets: DvInsertionOrderData?.budgets?.filter(
              (item) => item?.budget_value != '' && item?.budget_enddate != '',
            ),
            flight_startdate: getSmallDate,
            flight_enddate: getLargeDates,
            budget_type: DvInsertionOrderData?.budget_type,
            // moderation_status: DvInsertionOrderData?.moderation_status
            //   ? DvInsertionOrderData?.moderation_status.toLowerCase() == 'draft'
            //     ? 'in_moderation'
            //     : DvInsertionOrderData?.moderation_status
            //   : DvInsertionOrderData?.moderation_status,
            moderation_status: EnabledApproval
              ? 'draft'
              : DvInsertionOrderData?.moderation_status,
            rejection_reason: '',
            created_by: getUserInfo?.Userid,
            is_enabled: 1,
            status: 1,
            created_at: dateFormat(moment().toDate()),
          };
          handleUpdatePost(payload);
        } else {
          const payload = {
            line_item_id: 0,
            campaign_id: getQueryParam?.campaign_id,
            insertion_order_id: 1,
            insertion_order_type: DvInsertionOrderData?.insertion_order_type,
            taxonomy_campaign_id: DvInsertionOrderData?.taxonomy_campaign_id,
            insertion_order_name: DvInsertionOrderData?.insertion_order_name,
            pacing_type: DvInsertionOrderData?.pacing_first_type,
            pacing_action: DvInsertionOrderData?.pacing_second_type,
            pacing_value: DvInsertionOrderData?.pacing_daily_price,
            goal_type: DvInsertionOrderData?.goal_type,
            goal_value: DvInsertionOrderData?.goal_value,
            billable_outcome: DvInsertionOrderData?.billable_outcome,
            optimization_type: DvInsertionOrderData?.optimization_type,
            optimization_action: DvInsertionOrderData?.optimization_action,
            optimization_CPM_value:
              DvInsertionOrderData?.optimization_CPM_value,
            optimization_priortize_deal:
              DvInsertionOrderData?.optimization_priortize_deal,
            frequency_cap_type: DvInsertionOrderData?.frequency_cap_type,
            frequency_limit:
              DvInsertionOrderData?.frequency_limit == ''
                ? 0
                : DvInsertionOrderData?.frequency_limit,
            frequency_limit_type: DvInsertionOrderData?.frequency_limit_type,
            frequency_cap_limit_value:
              DvInsertionOrderData?.frequency_cap_limit_value == ''
                ? 0
                : DvInsertionOrderData?.frequency_cap_limit_value,
            invsource_quality: DvInsertionOrderData?.quality,
            invsource_publicinventoryid: DvInsertionOrderData?.public_inventory,
            invsource_dealspackagesid:
              DvInsertionOrderData?.deals_and_inventory_packages,
            invsource_groupid: DvInsertionOrderData?.groups,
            budgets: DvInsertionOrderData?.budgets,
            flight_startdate: getSmallDate,
            flight_enddate: getLargeDates,
            budget_type: DvInsertionOrderData?.budget_type,
            moderation_status: EnabledApproval ? 'draft' : 'in_moderation',
            rejection_reason: '',
            is_enabled: 1,
            created_by: getUserInfo?.Userid,
            status: 1,
            created_at: dateFormat(moment().toDate()),
          };
          // console.log('payload', JSON.stringify(payload));
          handlePostOrder(payload);
        }
      }
    }, 300);
  };

  const validate = (formData) => {
    const errors = {};
    //Get Values
    let insertionOrderName = DvInsertionOrderData?.insertion_order_name;
    let insertionOrderId = DvInsertionOrderData?.insertion_order_name;
    let pacing_first_type = DvInsertionOrderData?.pacing_first_type;
    let pacing_second_type = DvInsertionOrderData?.pacing_second_type;
    let goalTyep = DvInsertionOrderData?.goal_type;
    let goalBudget = DvInsertionOrderData?.goal_value;
    let optimizationType = DvInsertionOrderData?.optimization_type;
    let frequenceyCapType = DvInsertionOrderData?.frequency_cap_type;
    let quality = DvInsertionOrderData?.quality;
    let public_inventory = DvInsertionOrderData?.public_inventory;
    let deals_and_inventory_packages =
      DvInsertionOrderData?.deals_and_inventory_packages;
    let groups = DvInsertionOrderData?.groups;
    let getBudgets = DvInsertionOrderData?.budgets;

    // Conditions
    if (typeof insertionOrderName == 'undefined') {
      errors.insertionOrderName = 'Select a value';
    } else if (insertionOrderName.trim() == '' && insertionOrderId == '') {
      errors.insertionOrderName = 'Select a value';
    }

    if (pacing_first_type == '') {
      errors.pacing_first_type_error = 'Select a value';
    }
    if (pacing_second_type == '') {
      errors.pacing_second_type_error = 'Select a value';
    }
    if (goalTyep == '') {
      errors.goal_type_error = 'Select a value';
    }
    if (goalBudget == '') {
      // setFormErrors({ ...formErrors, goal_value_error: 'Enter a value' });
      errors.goal_value_error = 'Enter a value';
      console.log('goalBudget', goalBudget);
    }

    if (optimizationType == '') {
      errors.goal_optimization_type_error = 'Please choose atleast one';
    }
    if (frequenceyCapType == '') {
      errors.frequency_cap_type_error = 'Please choose atleast one';
    }
    // if (quality == '') {
    //   errors.qualityErr = 'Please Select a value';
    // }
    // if (public_inventory == '') {
    //   errors.public_inventory = 'Please Select a value';
    // }
    // if (deals_and_inventory_packages == '')
    //   errors.deals_and_inventory_packages = lengthFieldCheck(
    //     deals_and_inventory_packages,
    //   );
    // }
    // if (groups == '') {
    //   errors.groups = lengthFieldCheck(groups);
    // }
    getBudgets
      .filter((item) => item?.status == 1)
      ?.map((v) => {
        if (v?.budget_value == '') {
          errors.budget_value_error = 'Enter a value';
          setNumberCheckBudgetAMount('');
        }
        if (v?.budget_enddate == null || v?.budget_enddate == '') {
          errors.budget_value_error = 'Please select an end date';
        }
      });
    if (
      DvInsertionOrderData?.pacing_first_type == 'Daily' &&
      DvInsertionOrderData?.pacing_daily_price == 0
    ) {
      setNumberCheckDailySpacing('The value should be greater than 0');
    }

    if (DvInsertionOrderData?.budget_type == '') {
      errors.budget_type_error = 'Select a value';
    }
    if (
      DvInsertionOrderData?.frequency_limit_type != '' &&
      DvInsertionOrderData?.frequency_cap_limit_value == ''
    ) {
      errors.frequency_cap_limit_value_error = 'Select a exposures per value';
    }
    return errors;
  };

  //save as draft -starts
  const handleSaveAsDraft = async () => {
    setFormErrors(validateOnSaveDraft());

    setTimeout(async () => {
      let getSmallDate = getBudgetSmallDate(DvInsertionOrderData?.budgets);
      let getLargeDates = getBudgetLargeDate(DvInsertionOrderData?.budgets);
      let budget = DvInsertionOrderData?.budgets;
      if (budget?.length == 1) {
        if (!budget[0]?.budget_value) {
          {
            budget = [
              {
                budget_id: 0,
                insertion_order_id: 0,
                budget_type: 'INR',
                budget_value: 0,
                budget_startdate: dateFormat(moment().toDate()),
                budget_enddate: null,
                status: 1,
              },
            ];
          }
        }
      }
      if (Object.keys(validateOnSaveDraft()).length == 0) {
        try {
          const payload = {
            // line_item_id: 0,
            campaign_id:
              DvInsertionOrderData?.campaign_id || getQueryParam?.campaign_id,
            insertion_order_id: DvInsertionOrderData?.insertion_order_id,
            insertion_order_type: DvInsertionOrderData?.insertion_order_type,
            insertion_order_name: DvInsertionOrderData?.insertion_order_name,
            taxonomy_campaign_id: DvInsertionOrderData?.taxonomy_campaign_id,
            pacing_type: DvInsertionOrderData?.pacing_first_type,
            pacing_action: DvInsertionOrderData?.pacing_second_type,
            pacing_value: DvInsertionOrderData?.pacing_daily_price,
            goal_type: DvInsertionOrderData?.goal_type,
            goal_value: DvInsertionOrderData?.goal_value || 0,
            billable_outcome: DvInsertionOrderData?.billable_outcome,
            optimization_type: DvInsertionOrderData?.optimization_type,
            optimization_action: DvInsertionOrderData?.optimization_action,
            optimization_CPM_value:
              DvInsertionOrderData?.optimization_CPM_value,
            optimization_priortize_deal:
              DvInsertionOrderData?.optimization_priortize_deal,
            frequency_cap_type: DvInsertionOrderData?.frequency_cap_type,
            frequency_limit:
              DvInsertionOrderData?.frequency_limit == ''
                ? 0
                : DvInsertionOrderData?.frequency_limit,
            frequency_limit_type: DvInsertionOrderData?.frequency_limit_type,
            frequency_cap_limit_value:
              DvInsertionOrderData?.frequency_cap_limit_value == ''
                ? 0
                : DvInsertionOrderData?.frequency_cap_limit_value,
            invsource_quality: DvInsertionOrderData?.quality,
            invsource_publicinventoryid: DvInsertionOrderData?.public_inventory,
            invsource_dealspackagesid:
              DvInsertionOrderData?.deals_and_inventory_packages,
            invsource_groupid: DvInsertionOrderData?.groups,
            budgets: budget,
            flight_startdate: getSmallDate,
            flight_enddate: getLargeDates,
            budget_type: DvInsertionOrderData?.budget_type,
            moderation_status: 'draft',
            rejection_reason: '',
            is_enabled: 0,
            created_by: getUserInfo?.Userid,
            status: 1,
            created_at: dateFormat(moment().toDate()),
            // "created_by"
          };
          console.log('payload', payload);
          setDisplayLoader(true);

          let response = await postApi(
            `${baseUrlStg}/api/InsertionOrder/SaveInsertionOrderDraft`,
            payload,
            { accessToken: '2323' },
          );
          setDisplayLoader(false);
          if (response?.data?.status == 200) {
            console.log('res', response);
            setShowModal(true);
            setUpdateTreeOnsaveDraft((prev) => !prev);
            navigate(
              `/dv-360/create-insertion-order/${response?.data?.insertionorderID}`,
              { state: { isCreateFlow: dataFromRoute?.isCreateFlow } },
            );
            // alert('success');
            dispatch(resetInsertionState());
          }
        } catch (err) {
          console.log('saveAsDraftinsertion err', err);
        }

        // let response = await getApi(`{}/api/Campaign/SaveCampaignDraft`)
      }
    }, 20);
  };

  const validateOnSaveDraft = () => {
    let errors = {};
    if (!(DvInsertionOrderData?.campaign_id || getQueryParam?.campaign_id)) {
      errors.campaignId = 'Campaign Id required';
    }
    if (!DvInsertionOrderData?.insertion_order_type) {
      errors.insertion_order_type = 'insertion order type required';
    }
    if (!DvInsertionOrderData?.insertion_order_name) {
      errors.insertionOrderName = 'insertion order name required';
    }
    if (!DvInsertionOrderData?.taxonomy_campaign_id) {
      errors.insertionOrderName = 'insertion order name required';
    }

    console.log('err', errors, DvInsertionOrderData?.campaign_id);
    return errors;
  };

  let budgetsItems = DvInsertionOrderData?.budgets?.filter(
    (item) => item?.status == 1,
  );

  //save as draft ends
  const handleApprovalPublisher = () => {
    if (getPublisherInfo?.length == 0) {
      setPublisherError('Please Choose atleast one option');
    } else {
      setApprovalCoinfirmationPopupShowStatus(true);
      setApprovalPublisherModelShowStatus(false);
      setPublisherError('');
    }
  };

  const handleApprovalPublisherRequest = async () => {
    try {
      let campId =
        typeof isUpdate != 'undefined' && isUpdate != ''
          ? DvInsertionOrderData?.campaign_id
          : query.get('campaign_id');

      let payload = {
        platform: 'DV360',
        userId: getUserInfo?.Userid,
        publisherId: getPublisherInfo?.value,
        campaignId: campId,
        link: `${window.location.origin}/dv-360/campaignsView/${campId}`,
      };

      setDisplayLoader(true);
      setApprovalCoinfirmationPopupShowStatus(false);
      let response = await postApi(
        `${baseUrlStg}/api/CampaignApproval/SendToPublisherApproval`,
        payload,
      );

      if (response?.status) {
        if (isPublisher) {
          setDisplayLoader(true);
          setApprovalCoinfirmationPopupShowStatus(false);
          let campaignId =
            typeof isUpdate != 'undefined' && isUpdate != ''
              ? DvInsertionOrderData?.campaign_id
              : query.get('campaign_id');
          handleAPICall('DV360', campaignId, getPublisherInfo?.value).then(
            (res) => {
              if (res == 200) {
                setThankYouApprovalPopupShowStatus(true);
              }
            },
          );
        } else {
          setThankYouApprovalPopupShowStatus(true);
          setDisplayLoader(false);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleSetPublisherId = (e) => {
    if (e == null) {
      setPublisherInfo([]);
      setIsPublisher(false);
    } else {
      setPublisherInfo(e);
      e?.value == getUserInfo?.Userid && setIsPublisher(true);
    }
  };

  useEffect(() => {
    if (typeof params['id'] == 'undefined') {
      dispatch(resetInsertionState());
    }
  }, [location]);

  const handleCloseModel = () => {
    if (getCreateStatus) {
      navigate(`/dv-360/create-insertion-order/${getCreateId}`);
    }
    setDisplayLoader(false);
    setApprovalPublisherModelShowStatus(false);
    setApprovalCoinfirmationPopupShowStatus(false);
    setShowModal(false);
    setPublisherInfo([]);
    setIsPublisher(false);
  };

  const redirectToDashboard = () => {
    navigate('/dv-360/campaigns-listing');
  };

  return (
    <div className='main-container facebook-step-one'>
      <div className='container'>
        <div className='fb-panel'>
          <Sidebar />
          <div className='right-side-box padding-bottom-extra media-section-left'>
            {/* <Header /> */}

            {/* header code starts */}
            <Header
              saveHandler={handleSaveAsDraft}
              redirectToDashboard={redirectToDashboard}
            />
            {/* header code ends */}
            <section className='side-menu-form d-flex'>
              {/* side tree menu code  starts*/}
              {dataFromRoute?.isCreateFlow ? (
                <SideTreeMenuForCreate
                  campaignTreeData={campaignTreeData}
                  isCreateMode={true}
                  IdAndType={{
                    id: params['id'],
                    type: 'insertion',
                  }}
                  showTreeMenu={showTreeMenu}
                  pageType={'insertionOrder'}
                  AtdStatus={campaignTreeData?.moderation_status}
                  Dv360Status={campaignTreeData?.moderation_status || 'NA'}
                />
              ) : (
                <SideTreeMenuForCreate
                  campaignTreeData={campaignTreeData}
                  isCreateMode={false}
                  IdAndType={{
                    id: params['id'],
                    type: 'insertion',
                  }}
                  handleRedirectionToNextPage={handleRedirectionToNextPage}
                  handleRedirectionToNextPageOfLineItem={
                    handleRedirectionToNextPageOfLineItem
                  }
                  flagStatus={getQueryParam?.flag}
                  showTreeMenu={showTreeMenu}
                  pageType={'insertionOrder'}
                  AtdStatus={campaignTreeData?.moderation_status}
                  Dv360Status={campaignTreeData?.moderation_status || 'NA'}
                />
              )}
              {/* side tree menu code ends */}
              <article className='side-right-form-wrap side-right-pad view-edit-tab-remove'>
                {/* <FormHeader /> */}
                <div
                  className='tab-content scroll-vertical'
                  id='nav-tabContent'
                >
                  {/* Error display div start */}
                  {isUpdate && <DvErrorDisplay id={insertCampaignID} />}
                  {/* Error display div end */}
                  <div
                    className='tab-pane fade show active'
                    id='edit'
                    role='tabpanel'
                    aria-labelledby='edit-tab'
                  >
                    <div className='side-inner-form'>
                      <article className='side-right-from'>
                        <Card>
                          <CardHeader>
                            <h5 className='d-flex align-items-center'>
                              Insertion order type
                              <span
                                className='label-information tooltips tool-bottom'
                                tooltip='Cascading Style Sheets'
                                tooltip-position='top'
                              >
                                {' '}
                                {/* <i className='icon-info'></i> */}
                              </span>
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont pt-3 pb-3'>
                                  <div className='action-btn-both row'>
                                    <div className='form-check-box col-12'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='flexRadioDefaultt'
                                        id='flexRadioDefault1'
                                        checked={true}
                                        value={
                                          DvInsertionOrderData?.selected_insertion_item
                                        }
                                      />
                                      <label
                                        className='form-check-label'
                                        htmlFor='flexRadioDefault1'
                                      >
                                        <i className='icon-real-time'></i>
                                        Real-time bidding
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardHeader>
                            <h5>Insertion Order Name*</h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='form-item col-9'>
                                  <div className='label-icon'>
                                    <label>Insertion order name*</label>
                                  </div>
                                  <ReactSelect
                                    isSearchable={true}
                                    options={
                                      DvInsertionOrderData?.insertion_order_lists
                                    }
                                    onChange={(e) => {
                                      handleInsertionOrderName(e);
                                    }}
                                    value={[
                                      {
                                        value:
                                          DvInsertionOrderData?.taxonomy_campaign_id,
                                        label:
                                          DvInsertionOrderData?.insertion_order_name,
                                      },
                                    ]}
                                    closeMenuOnSelect={true}
                                  />
                                  <ErrorDisplay
                                    val={formErrors?.insertionOrderName}
                                  />
                                </div>
                                <div class='col-3'>
                                  <button
                                    class='btn-icon btn-icon-primary'
                                    onClick={handleRedirect}
                                  >
                                    <i class='icon-plus'></i>
                                    Create Insertion Order
                                  </button>
                                </div>
                                <p>
                                  {' '}
                                  Insertion order name will appear from Campaign
                                  name of Taxanomy manager, if you have not
                                  created if, use the create button to create a
                                  new insertion order.{' '}
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        <Card>
                          <AdvancedCardHeader>
                            <div className='card-lt-pane'>
                              <h5>Budget</h5>
                              <p>
                                Budget and pacing depend on both insertion order
                                and line item settings.
                              </p>
                              <h6 className='d-flex align-items-center'>
                                Budget*
                              </h6>
                              <p>
                                Specify this insertion order's flight dates and
                                budget segments
                              </p>
                            </div>
                            <div className='me-auto card-switch-rigth'>
                              <div className='form-panel select-with-side-label'>
                                <div className='form-item'>
                                  <div>
                                    <div className='label-icon'>
                                      <label>Budget Type</label>
                                    </div>
                                    {typeof isUpdate != 'undefined' &&
                                    isUpdate != '' &&
                                    getModerationStatus != 'draft' ? (
                                      <SimpleSelectBox
                                        placeholder={
                                          DvInsertionOrderData?.budget_type
                                        }
                                        disabled
                                      ></SimpleSelectBox>
                                    ) : (
                                      <SimpleSelectBox
                                        placeholder='Select'
                                        options={budgetTypeOptions}
                                        onChangeHandler={handleBudgetType}
                                        value={
                                          DvInsertionOrderData?.budget_type
                                        }
                                      ></SimpleSelectBox>
                                    )}
                                    <ErrorDisplay
                                      val={formErrors?.budget_type_error}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                          <CardBody>
                            {budgetsItems?.map((item, index) => {
                              return (
                                <Budget
                                  handleBudgetAmount={(e) =>
                                    handleBudgetAmount(e, index)
                                  }
                                  startDateHandler={(e) =>
                                    startDateHandler(e, index)
                                  }
                                  endDateHandler={(e) =>
                                    endDateHandler(e, index)
                                  }
                                  budgetVal={item?.budget_value}
                                  startDateVal={item?.budget_startdate}
                                  endDateVal={item?.budget_enddate}
                                  index={index}
                                  handleDeleteBudget={(e) =>
                                    handleDeleteBudget(index)
                                  }
                                  budgetLength={
                                    DvInsertionOrderData?.budgets?.filter(
                                      (item) => item?.status == 1,
                                    )?.length
                                  }
                                  isUpdate={isUpdate}
                                ></Budget>
                              );
                            })}
                            <span
                              className='error-message'
                              style={{ color: 'red' }}
                            >
                              {numberCheckBudgetAMount}
                            </span>
                            <span
                              className='error-message'
                              style={{ color: 'red' }}
                            >
                              {formErrors?.budget_value_error}
                            </span>
                            <div className='add-section'>
                              <button
                                className='btn-add-plus'
                                onClick={handleAddBudget}
                              >
                                <i className='icon-plus-circle' /> Add segments
                              </button>
                            </div>
                          </CardBody>

                          <CardHeader>
                            <h6>Pacing*</h6>
                            <p>How do you want to spend the flight budget?</p>
                          </CardHeader>
                          <div className='card-form form-panel form-panel-group-add form-panel-no-border'>
                            <div className='row align-items-center'>
                              <div className='form-item col-box-3'>
                                <div className='label-icon'>
                                  <label>Select</label>
                                </div>
                                <div className='col-12'>
                                  <SimpleSelectBox
                                    name='creative_optimization'
                                    placeholder='Select'
                                    options={commonOptions}
                                    onChangeHandler={handlePacingFirstType}
                                    value={
                                      DvInsertionOrderData?.pacing_first_type
                                    }
                                  ></SimpleSelectBox>
                                </div>
                                <ErrorDisplay
                                  val={formErrors?.pacing_first_type_error}
                                />
                              </div>
                              <div className='form-item col-box-3'>
                                <div className='label-icon'>
                                  <label>Select</label>
                                </div>
                                <div className='col-12'>
                                  <SimpleSelectBox
                                    placeholder='select'
                                    options={
                                      DvInsertionOrderData?.pacing_first_type ==
                                      'Flight'
                                        ? insertionOrderFlightsOptions
                                        : insertionOrderDailyOptions
                                    }
                                    onChangeHandler={handlePacingSecondType}
                                    value={
                                      DvInsertionOrderData?.pacing_second_type
                                    }
                                  ></SimpleSelectBox>
                                </div>
                                <ErrorDisplay
                                  val={formErrors?.pacing_first_type_error}
                                />
                              </div>
                              {DvInsertionOrderData?.pacing_first_type ==
                                'Daily' && (
                                <div className='form-item input-group-label col-box-3 col-box-3-inr'>
                                  <CommonBudget
                                    id='daily-price'
                                    handler={handleDailyPrice}
                                    err={numberCheckDailySpacing}
                                    value={
                                      DvInsertionOrderData?.pacing_daily_price
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <CardHeader>
                            <h6>Goal*</h6>
                            <p>What goal would you like to focus on?</p>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel form-panel-group-add form-panel-no-border'>
                              <div className='row align-items-center'>
                                <div className='form-item col-box-3'>
                                  <div className='label-icon'>
                                    <label>Select</label>
                                  </div>
                                  <div className='select-box'>
                                    <SimpleSelectBox
                                      placeholder='select'
                                      options={insertionGoal}
                                      onChangeHandler={handleGoalType}
                                      value={DvInsertionOrderData?.goal_type}
                                    ></SimpleSelectBox>
                                  </div>
                                  <ErrorDisplay
                                    val={formErrors?.goal_type_error}
                                  />
                                </div>
                                <div className='form-item input-group-label col-box-3 col-box-3-inr'>
                                  <CommonBudget
                                    id='goal-amount'
                                    handler={handleGoalAmount}
                                    err={numberCheckGoalPacing}
                                    value={DvInsertionOrderData?.goal_value}
                                    getGoalTypeVal={getGoalTypeVal}
                                  />
                                  {numberCheckGoalPacing == '' && (
                                    <ErrorDisplay
                                      val={formErrors?.goal_value_error}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </CardBody>

                          <CardHeader>
                            <h6>Billable outcome</h6>
                            <p>What would you like to pay for?</p>
                          </CardHeader>
                          <div className='card-form form-panel form-panel-group-add form-panel-no-border'>
                            <div className='row align-items-center'>
                              <div className='form-item col-box-3'>
                                <div className='label-icon'>
                                  <label>Select</label>
                                </div>
                                <SimpleSelectBox
                                  placeholder='Impression'
                                  disabled
                                ></SimpleSelectBox>
                              </div>
                            </div>
                          </div>

                          <CardHeader>
                            <h6>Optimization*</h6>
                            <p>How would you like to optimize?</p>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont'>
                                  <div className='action-btn-both action-btn-no-icon row'>
                                    <div className='col-12 px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='Automate bid & budget at insertion order level'
                                          id='automate_id'
                                          defaultValue='Automate bid & budget at insertion order level'
                                          onChange={(e) =>
                                            handleOptimizationType(e)
                                          }
                                          checked={
                                            DvInsertionOrderData?.optimization_type ==
                                            'Automate bid & budget at insertion order level'
                                              ? true
                                              : false
                                          }
                                          disabled={
                                            getLineItemAudioStatus > 0 &&
                                            getModerationStatus != 'draft'
                                              ? true
                                              : false
                                          }
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault50'
                                        >
                                          Automate bid & budget at insertion
                                          order level
                                          <p>
                                            Allow system to automatically adjust
                                            bids and shift budget to
                                            better-performing line items.
                                          </p>
                                        </label>
                                      </div>
                                      {DvInsertionOrderData?.optimization_type ==
                                        'Automate bid & budget at insertion order level' && (
                                        <div className='dv-from-check-field'>
                                          <div className='form-item'>
                                            <div className='label-icon'>
                                              <label>Select</label>
                                            </div>
                                            <SimpleSelectBox
                                              options={[
                                                'Maximize conversions',
                                                'Maximize clicks',
                                                'Maximize viewable impressions',
                                                'Maximize completed in-view and audible',
                                                'Maximize viewable at least 10 seconds',
                                              ]}
                                              placeholder='Select'
                                              onChangeHandler={
                                                handleOptimizationAction
                                              }
                                              value={
                                                DvInsertionOrderData?.optimization_action
                                              }
                                            ></SimpleSelectBox>

                                            {/* <span className="error" id="emailError">Please enter valid email id</span> */}
                                          </div>
                                          <p className='about-info-text'>
                                            while prioritizing spending my full
                                            budget (recommended)
                                          </p>

                                          <div className='d-flex dv-check-box'>
                                            <div className='action-btn-both action-btn-both-check row'>
                                              <div className='form-check-box'>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  id='inlineCheckbox7'
                                                  value='option1'
                                                  checked={
                                                    DvInsertionOrderData?.optimization_checked_status
                                                  }
                                                />
                                                <label
                                                  className='form-check-labe2'
                                                  htmlFor='inlineCheckbox7'
                                                >
                                                  Do not exceed average CPM of
                                                </label>
                                              </div>
                                            </div>
                                            <div className='form-item input-group-label col-box-3 col-box-3-inr'>
                                              <CommonBudget
                                                id='daily-price'
                                                handler={
                                                  handleOptimizationCPMValue
                                                }
                                                err={
                                                  getNumberCheckOptimizationCPMValue
                                                }
                                                value={
                                                  DvInsertionOrderData?.optimization_CPM_value
                                                }
                                              />
                                            </div>
                                          </div>

                                          <div className='dv-check-box'>
                                            <div className='action-btn-both action-btn-both-check row'>
                                              <div className='form-check-box'>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  id='inlineCheckbox8'
                                                  defaultValue='Prioritize_deal'
                                                  onChange={
                                                    handleOptimizationPrioritizeDeal
                                                  }
                                                />
                                                <label
                                                  className='form-check-labe2'
                                                  htmlFor='inlineCheckbox8'
                                                >
                                                  Prioritize deals over open
                                                  auction inventory
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>

                                    <div className='col-12 px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='Control bid and budget at the line item level'
                                          defaultValue='Control bid and budget at the line item level'
                                          id='cantrol-id'
                                          onChange={(e) =>
                                            handleOptimizationType(e)
                                          }
                                          checked={
                                            DvInsertionOrderData?.optimization_type ==
                                            'Control bid and budget at the line item level'
                                              ? true
                                              : false
                                          }
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault51'
                                        >
                                          Control bid and budget at the line
                                          item level
                                        </label>
                                      </div>

                                      <div className='dv-from-check-field'>
                                        <div className='dv-check-box'>
                                          <div className='action-btn-both action-btn-both-check row'>
                                            <div className='form-check-box'>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                id='inlineCheckbox8'
                                                defaultValue='Automatically optimize your budget allocation'
                                                onChange={
                                                  handleOptimizationAction
                                                }
                                                checked={
                                                  DvInsertionOrderData?.optimization_action ==
                                                    'Automatically optimize your budget allocation' &&
                                                  DvInsertionOrderData?.optimization_checked_status
                                                    ? true
                                                    : false
                                                }
                                                // disabled={
                                                //   DvInsertionOrderData?.optimization_type ==
                                                //   'Control bid and budget at the line item level'
                                                //     ? false
                                                //     : true
                                                // }
                                              />
                                              <label
                                                className='form-check-labe2'
                                                htmlFor='inlineCheckbox8'
                                              >
                                                Automatically optimize your
                                                budget allocation
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <span
                                      className='error-message'
                                      style={{ color: 'red' }}
                                    >
                                      {formErrors?.goal_optimization_type_error}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                          {/* </CardHeader> */}
                        </Card>

                        <Card>
                          <CardHeader>
                            <h5 className='d-flex align-items-center'>
                              Frequency cap*
                            </h5>
                          </CardHeader>
                          <CardBody>
                            <div className='card-form form-panel card-form-days dv-card-form-days'>
                              <div className='row align-items-center'>
                                <div className='action-btn-cont'>
                                  <div className='action-btn-both action-btn-no-icon row'>
                                    <div className='col-12 d-flex px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='no limit'
                                          id='flexRadioDefault232'
                                          defaultValue='no limit'
                                          onChange={(e) =>
                                            handleLimitFrequencey(e)
                                          }
                                          checked={
                                            DvInsertionOrderData?.frequency_cap_type ==
                                            'no limit'
                                              ? true
                                              : false
                                          }
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault22'
                                        >
                                          No limit
                                        </label>
                                      </div>
                                    </div>

                                    <div className='col-12 d-flex px-0'>
                                      <div className='form-check-box'>
                                        <input
                                          className='form-check-input'
                                          type='radio'
                                          name='limit_frequencey'
                                          id='flexRadioDefault333'
                                          defaultValue='limit_frequencey'
                                          onChange={(e) =>
                                            handleLimitFrequencey(e)
                                          }
                                          checked={
                                            DvInsertionOrderData?.frequency_cap_type ==
                                            'limit_frequencey'
                                              ? true
                                              : false
                                          }
                                        />
                                        <label
                                          className='form-check-label'
                                          htmlFor='flexRadioDefault33'
                                        >
                                          Limit frequency to
                                        </label>
                                      </div>
                                      <div className='form-item d-flex align-items-center me-3'>
                                        <input
                                          type='email'
                                          id='email'
                                          name='frequencey_val'
                                          placeholder='Enter a value'
                                          onChange={(e) => {
                                            handleFrequencyVal(e);
                                          }}
                                          value={
                                            DvInsertionOrderData?.frequency_limit
                                          }
                                          readOnly={
                                            DvInsertionOrderData?.frequency_cap_type !=
                                              '' &&
                                            DvInsertionOrderData?.frequency_cap_type !=
                                              'no limit'
                                              ? false
                                              : true
                                          }
                                        />
                                        <ErrorDisplay val={getFeqErr} />
                                        <label>exposures per </label>
                                      </div>
                                      {DvInsertionOrderData?.frequency_limit_type ==
                                        'Months' && (
                                        <div className='form-item d-flex align-items-center me-3'>
                                          <SimpleSelectBox
                                            name='Frequency limit exposure months'
                                            placeholder='Select'
                                            options={['1', '2']}
                                            onChangeHandler={
                                              handlesExposuresValue
                                            }
                                            value={
                                              DvInsertionOrderData?.frequency_cap_limit_value
                                            }
                                          ></SimpleSelectBox>
                                        </div>
                                      )}
                                      {DvInsertionOrderData?.frequency_limit_type ==
                                        'Weeks' && (
                                        <div className='form-item d-flex align-items-center me-3'>
                                          <SimpleSelectBox
                                            name='Frequency limit exposure weeks'
                                            placeholder='Select'
                                            options={['1', '2', '3', '4']}
                                            onChangeHandler={
                                              handlesExposuresValue
                                            }
                                            value={
                                              DvInsertionOrderData?.frequency_cap_limit_value
                                            }
                                          ></SimpleSelectBox>
                                        </div>
                                      )}
                                      {DvInsertionOrderData?.frequency_limit_type ==
                                        'Days' && (
                                        <div className='form-item d-flex align-items-center me-3'>
                                          <SimpleSelectBox
                                            name='Frequency limit exposure days'
                                            placeholder='Select'
                                            options={[
                                              '1',
                                              '2',
                                              '3',
                                              '4',
                                              '5',
                                              '6',
                                            ]}
                                            onChangeHandler={
                                              handlesExposuresValue
                                            }
                                            value={
                                              DvInsertionOrderData?.frequency_cap_limit_value
                                            }
                                          ></SimpleSelectBox>
                                        </div>
                                      )}
                                      {DvInsertionOrderData?.frequency_limit_type ==
                                        'Hours' && (
                                        <div className='form-item d-flex align-items-center me-3'>
                                          <SimpleSelectBox
                                            name='Frequency limit exposure hours'
                                            placeholder='Select'
                                            options={[...Array(24)].map(
                                              (item, index) => index.toString(),
                                            )}
                                            onChangeHandler={
                                              handlesExposuresValue
                                            }
                                            value={
                                              DvInsertionOrderData?.frequency_cap_limit_value
                                            }
                                          ></SimpleSelectBox>
                                        </div>
                                      )}
                                      {DvInsertionOrderData?.frequency_limit_type ==
                                        'Minutes' && (
                                        <div className='form-item d-flex align-items-center me-3'>
                                          <SimpleSelectBox
                                            name='Frequency limit exposure minutes'
                                            placeholder='Select'
                                            options={[...Array(59)].map(
                                              (item, index) => index.toString(),
                                            )}
                                            onChangeHandler={
                                              handlesExposuresValue
                                            }
                                            value={
                                              DvInsertionOrderData?.frequency_cap_limit_value
                                            }
                                          ></SimpleSelectBox>
                                        </div>
                                      )}
                                      <div className='form-item d-flex align-items-center'>
                                        <SimpleSelectBox
                                          name='Frequency limit exposure'
                                          placeholder='Lifetime of this campaign'
                                          options={[
                                            'Months',
                                            'Weeks',
                                            'Days',
                                            'Hours',
                                            'Minutes',
                                          ]}
                                          onChangeHandler={handleExposureVal}
                                          disabled={
                                            DvInsertionOrderData?.frequency_cap_type !=
                                              '' &&
                                            DvInsertionOrderData?.frequency_cap_type !=
                                              'no limit'
                                              ? false
                                              : true
                                          }
                                          value={
                                            DvInsertionOrderData?.frequency_limit_type
                                          }
                                        ></SimpleSelectBox>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <span
                                className='error-message'
                                style={{ color: 'red' }}
                              >
                                {formErrors?.frequency_cap_type_error}
                              </span>
                              <span
                                className='error-message'
                                style={{ color: 'red' }}
                              >
                                {formErrors?.frequency_cap_limit_value_error}
                              </span>
                            </div>
                          </CardBody>
                          <CardBody>
                            <InfoText></InfoText>
                          </CardBody>
                        </Card>
                        {/* Inventory source & Targetting start */}
                        {/* <Card>
                          <CardHeader>
                            <h5>Inventory source</h5>
                          </CardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>
                                New insertion orders and line items in this
                                campaign will inherit these settings.
                              </h6>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Quality</h6>
                              <div className='select-multiple-components'>
                                <SimpleSelectBox
                                  name='quality'
                                  placeholder='Select...'
                                  options={[
                                    'Authorized Direct Sellers',
                                    'Authorized Direct Sellers And Resellers',
                                    'Authorized and Non-Participating Publishers',
                                  ]}
                                  onChangeHandler={handleQualityVal}
                                  value={DvInsertionOrderData?.quality}
                                ></SimpleSelectBox>
                                <ErrorDisplay val={formErrors?.qualityErr} />
                              </div>
                              <div class='dv-header-icon'>
                                <div class='dv-icon-outer d-flex'>
                                  <div class='dv-data-icon'>
                                    <div class='dv-inner-icon d-flex'>
                                      <p class='dv-text-g'>
                                        Authorised and Non-partcipating
                                        Publishers.{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Public Inventory</h6>

                              <div className='select-multiple-components'>
                                <ReactSelect
                                  isMulti={true}
                                  options={publicInventoryOptions}
                                  value={prefillSelect(
                                    DvInsertionOrderData?.public_inventory,
                                    publicInventoryOptions,
                                  )}
                                  onChange={handlePublicInventory}
                                />

                                <span className='error'>
                                  {formErrors?.public_inventory}
                                </span>
                              </div>

                              <div class='dv-header-icon'>
                                <div class='dv-icon-outer d-flex'>
                                  <div class='dv-icon'></div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Deals and inventory packages.</h6>
                              <ReactSelect
                                isMulti={true}
                                options={dealsAndInventoryPackagesOptions}
                                value={prefillSelect(
                                  DvInsertionOrderData?.deals_and_inventory_packages,
                                  dealsAndInventoryPackagesOptions,
                                )}
                                onChange={handleDealInventory}
                              />

                              <span className='error'>
                                {formErrors?.deals_and_inventory_packages}
                              </span>
                            </div>
                          </AdvancedCardHeader>
                          <div class='dv-header-icon'>
                            <div class='dv-icon-outer d-flex'>
                              <div class='dv-data-icon'>
                                <div class='dv-inner-icon d-flex'>
                                  <p class='dv-text-g'>
                                    0 deals and inventory packages selected.{' '}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Groups</h6>
                              <div className='select-multiple-components'>
                                <ReactSelect
                                  isMulti={true}
                                  options={groupsOptions}
                                  value={prefillSelect(
                                    DvInsertionOrderData?.groups,
                                    groupsOptions,
                                  )}
                                  onChange={handleGroups}
                                />
                                <span className='error'>
                                  {formErrors?.groups}
                                </span>
                              </div>
                              <div class='dv-header-icon'>
                                <div class='dv-icon-outer d-flex'>
                                  <div class='dv-data-icon'>
                                    <div class='dv-inner-icon d-flex'>
                                      <p class='dv-text-g'>
                                        No inventory group selected.{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                        </Card> */}
                        {/* <Card>
                          <CardHeader>
                            <h5>Targetting</h5>
                          </CardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>
                                New insertion orders and line items in this
                                campaign will inherit these settings.
                              </h6>
                              <SimpleSelectBox></SimpleSelectBox>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Demographics</h6>
                              <div class='dv-header-icon'>
                                <div class='dv-icon-outer d-flex'>
                                  <div class='dv-data-icon'>
                                    <div class='dv-inner-icon d-flex'>
                                      <p class='dv-text-g'>
                                        All genders,ages,parental statuses and
                                        household incomes.{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>

                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Geography</h6>

                              <div class='dv-header-icon'>
                                <div class='dv-icon-outer d-flex'>
                                  <div class='dv-icon'>
                                    <i class='icon-check-mark'></i>
                                  </div>
                                  <div class='dv-data-icon'>
                                    <p>Target the following regions</p>
                                    <div class='dv-inner-icon d-flex'>
                                      <p class='dv-text-g'>India (Country)</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Language</h6>
                              <div class='dv-header-icon'>
                                <div class='dv-icon-outer d-flex'>
                                  <div class='dv-data-icon'>
                                    <div class='dv-inner-icon d-flex'>
                                      <p class='dv-text-g'>All languages </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                          <AdvancedCardHeader>
                            <div class='card-lt-pane'>
                              <h6>Brand safety</h6>
                              <div class='dv-header-icon'>
                                <div class='dv-icon-outer d-flex'>
                                  <div class='dv-data-icon'>
                                    <div class='dv-inner-icon d-flex'>
                                      <p class='dv-text-g'>
                                        No inventory group selected.{' '}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AdvancedCardHeader>
                        </Card> */}
                        {/* Inventory source & Targetting end */}
                      </article>
                    </div>
                  </div>
                </div>

                {/* view starts */}
                {/* <div
                  className="tab-pane fade"
                  id="review"
                  role="tabpanel"
                  aria-labelledby="review-tab"
                >
                  <div className="rv-details-cont">
                    <div className="card-primary-repet">
                      <div className="card-primary">
                        <div className="card-header-top d-flex">
                          <div className="card-lt-pane">
                            <h5>Campaign name</h5>
                            <p>New Awareness Campaign</p>
                            <p>ID: 23857805967620728</p>
                          </div>
                        </div>
                        <div className="card-header-top d-flex">
                          <div className="card-lt-pane">
                            <h5>Special ad categories</h5>
                            <p>No categories declared</p>
                          </div>
                        </div>
                        <div className="card-header-top d-flex">
                          <div className="card-lt-pane">
                            <h5>Buying type</h5>
                            <p>Awareness</p>
                          </div>
                        </div>
                        <div className="card-header-top d-flex">
                          <div className="card-lt-pane">
                            <h5>Ad sets</h5>
                            <p className="add-data">Please add: Ad sets</p>
                          </div>
                        </div>
                        <div className="card-header-top d-flex">
                          <div className="card-lt-pane">
                            <h5>Format</h5>
                            <p>Image and Video</p>
                          </div>
                          <div className="card-rt-pane d-flex me-auto align-items-center">
                            <figure className="prev-icon">
                              <img src={atdplaceholder} />
                            </figure>
                            <button
                              type="button"
                              className="btn-icon btn-icon-primary btn-bedcrumb-act me-0"
                            >
                              Preview
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* view ends */}
                <div
                  className='tab-pane fade'
                  id='history'
                  role='tabpanel'
                  aria-labelledby='history-tab'
                ></div>
              </article>
            </section>
          </div>
        </div>
        {displayLoader && <Loader />}
        <CommonModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          copy={'Insertion Order has been successfully saved as Draft.'}
        />
        {isUpdate && (
          <CommonModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            handleRedirection={() => handleRedirection()}
            copy={'Insertion order has been successfully saved.'}
            isUpdatedStatus={isUpdate}
          />
        )}
        {getApprovalPublisherModelShowStatus && (
          <SelectApprovalPublisher
            type={'dv360'}
            handleApprovalPublisher={handleApprovalPublisher}
            handleSetPublisherId={handleSetPublisherId}
            getPublisherInfo={getPublisherInfo}
            getPublisherError={getPublisherError}
            handleCloseModel={handleCloseModel}
          />
        )}
        {getApprovalCoinfirmationPopupShowStatus && (
          <ApprovalConfirmationPopup
            type={'dv360'}
            handleApprovalPublisherRequest={handleApprovalPublisherRequest}
            handleCloseModel={handleCloseModel}
            isPublisher={isPublisher}
          />
        )}
        {getThankYouApprovalPopupShowStatus && (
          <ThankYouApprovalPopup
            handleRedirect={handleRedirection}
            approved={isPublisher ? 'approved' : ''}
          />
        )}
        <Footer
          nextBtnlabel={
            typeof isUpdate != 'undefined' && isUpdate != ''
              ? 'Save'
              : getQueryParam?.flag == 'multiple'
                ? 'Save'
                : 'Next'
          }
          handleSubmit={handleSubmit}
          getAtdStatus={getModerationStatus}
          closePath={'/dv-360/campaigns-listing'}
        >
          {' '}
        </Footer>
      </div>
    </div>
  );
}

export default InsertionOrder;
