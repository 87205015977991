import moment from 'moment/moment';
import {
  DV_Optimization_Type,
  DV_Line_Item_Name,
  DV_Inventory_Source,
  DV_Quality,
  DV_Targetting,
  DV_Conversion_Counting,
  DV_Selected_Line_Item,
  DV_Flight_Details,
  DV_Selected_Start_Date,
  DV_Selected_End_Date,
  DV_Selected_Frequencey_Type,
  DV_Selected_Frequencey_Value,
  DV_Selected_Exposures_Type,
  DV_Selected_Exposures_Value,
  DV_Optimized_Target,
  DV_Budget_Spacing_Type,
  DV_Budget_Spacing_Amount,
  DV_Budget_Spacing_First_Type,
  DV_Budget_Spacing_Second_Type,
  DV_Public_Inventory,
  DV_Deals_And_Inventory_Packages,
  DV_Groups,
  DV_Budget_Spacing_Daily_Price,
  DV_Budget_Spacing_Second_Option_Type,
  DV_Automated_Bidding_Option_One_Value,
  DV_Automated_Bidding_Option_Two_Value,
  DV_Automated_Bidding_Exceed_Avg,
  DV_Automated_Bidding_Exceed_Amount,
  DV_Automated_Bidding_Prioritize_Deal,
  DV_Automated_Bidding_Type,
  DV_Fixed_Bid_Val,
  DV_Budget_Type,
  DV_Creative_Optimization,
  DV_Automate_Bidding_Prioritize_Target_Cpm,
  DV_Prefilled_Values,
  DV_Reset_Values,
} from './CreateCampaignActionTypes';
import { dateFormat } from '../../utils/validations';

const initialState = {
  selected_line_item: 'Display',
  line_item_name: '',
  quality: '',
  public_inventory: '',
  deals_and_inventory_packages: '',
  groups: '',
  optimized_target: false,
  dv_flight_details: {
    flight_selected_type: '',
  },
  budget_type: '',
  flight_start_date: moment().toDate(),
  flight_end_date: moment().add(1, 'days'),
  frequencey_type: '',
  limit_frequencey_value: '',
  limit_frequencey_exposures_type: '',
  limit_frequencey_exposures_value: '',
  budget_type: 'INR',
  budget_spacing_type: '',
  budget_spacing_amount: 0,
  budget_spacing_first_type: '',
  budget_spacing_second_type: '',
  budget_spacing_daily_price: '',
  budget_spacing_second_option_type: '',
  automated_bidding_type: '',
  automated_bidding_option_one_value: '',
  automated_bidding_option_two_value: '',
  automated_bidding_exceed_avg: false,
  automated_bidding_exceed_amount: 0,
  automated_bidding_prioritize_deal: false,
  fixed_bid_val: '',
  creative_optimization: '',
  automate_bidding_prioritize_target_cpm: '',
};

export const DvCreateLineItem = (state = initialState, { type, payload }) => {
  switch (type) {
    case DV_Line_Item_Name: {
      let data = payload;
      return {
        ...state,
        line_item_name: data,
      };
    }
    case DV_Optimization_Type: {
      let data = payload;
      return {
        ...state,
        optimization_type: data,
      };
    }
    case DV_Quality: {
      let data = payload;
      return {
        ...state,
        quality: data,
      };
    }
    case DV_Public_Inventory: {
      let data = payload;
      return {
        ...state,
        public_inventory: data,
      };
    }
    case DV_Deals_And_Inventory_Packages: {
      let data = payload;
      return {
        ...state,
        deals_and_inventory_packages: data,
      };
    }
    case DV_Groups: {
      let data = payload;
      return {
        ...state,
        groups: data,
      };
    }
    case DV_Inventory_Source: {
      return { ...state, DV_Inventory_Source: state?.dv_inventory_source };
    }
    case DV_Targetting: {
      return { ...state, DV_Targetting: state?.dv_targetting };
    }
    case DV_Conversion_Counting: {
      return {
        ...state,
        DV_Conversion_Counting: state?.dv_conversion_counting,
      };
    }
    case DV_Selected_Line_Item: {
      let data = payload;
      return {
        ...state,
        selected_line_item: data,
      };
    }
    case DV_Flight_Details: {
      let value, name;
      let data = payload?.target;
      if (data) {
        name = data?.name;
        value = data?.value;
      }
      let obj = state?.dv_flight_details;
      return { ...state, dv_flight_details: { ...obj, [name]: value } };
    }
    case DV_Selected_Start_Date: {
      let data = payload;
      return {
        ...state,
        flight_start_date: data,
      };
    }
    case DV_Selected_End_Date: {
      let data = payload;
      return {
        ...state,
        flight_end_date: data,
      };
    }
    case DV_Selected_Frequencey_Type: {
      let data = payload;
      return {
        ...state,
        frequencey_type: data,
      };
    }
    case DV_Selected_Frequencey_Value: {
      let data = payload;
      return {
        ...state,
        limit_frequencey_value: data,
      };
    }
    case DV_Selected_Exposures_Type: {
      let data = payload;
      return {
        ...state,
        limit_frequencey_exposures_type: data,
      };
    }
    case DV_Selected_Exposures_Value: {
      let data = payload;
      return {
        ...state,
        limit_frequencey_exposures_value: data,
      };
    }
    case DV_Optimized_Target: {
      let data = payload;
      return {
        ...state,
        optimized_target: data,
      };
    }
    case DV_Budget_Type: {
      let data = payload;
      return {
        ...state,
        budget_type: data,
      };
    }
    case DV_Budget_Spacing_Type: {
      let data = payload;
      return {
        ...state,
        budget_spacing_type: data,
      };
    }
    case DV_Budget_Spacing_Amount: {
      let data = payload;
      return {
        ...state,
        budget_spacing_amount: data,
      };
    }
    case DV_Budget_Spacing_First_Type: {
      let data = payload;
      return {
        ...state,
        budget_spacing_first_type: data,
      };
    }
    case DV_Budget_Spacing_Second_Type: {
      let data = payload;
      return {
        ...state,
        budget_spacing_second_type: data,
      };
    }
    case DV_Budget_Spacing_Daily_Price: {
      let data = payload;
      return {
        ...state,
        budget_spacing_daily_price: data,
      };
    }
    case DV_Budget_Spacing_Second_Option_Type: {
      let data = payload;
      return {
        ...state,
        budget_spacing_second_option_type: data,
      };
    }
    case DV_Automated_Bidding_Option_One_Value: {
      let data = payload;
      return {
        ...state,
        automated_bidding_option_one_value: data,
      };
    }
    case DV_Automated_Bidding_Option_Two_Value: {
      let data = payload;
      return {
        ...state,
        automated_bidding_option_two_value: data,
      };
    }
    case DV_Automated_Bidding_Type: {
      let data = payload;
      return {
        ...state,
        automated_bidding_type: data,
      };
    }
    case DV_Automated_Bidding_Exceed_Avg: {
      let data = payload;
      return {
        ...state,
        automated_bidding_exceed_avg: data,
      };
    }
    case DV_Automated_Bidding_Exceed_Amount: {
      let data = payload;
      return {
        ...state,
        automated_bidding_exceed_amount: data,
      };
    }
    case DV_Automated_Bidding_Prioritize_Deal: {
      let data = payload;
      return {
        ...state,
        automated_bidding_prioritize_deal: data,
      };
    }
    case DV_Fixed_Bid_Val: {
      let data = payload;
      return {
        ...state,
        fixed_bid_val: data,
      };
    }
    case DV_Creative_Optimization: {
      let data = payload;
      return {
        ...state,
        creative_optimization: data,
      };
    }
    case DV_Automate_Bidding_Prioritize_Target_Cpm: {
      let data = payload;
      return {
        ...state,
        automate_bidding_prioritize_target_cpm: data,
      };
    }
    case DV_Prefilled_Values: {
      return {
        ...state,
        line_item_id: payload?.line_item_id,
        insertion_order_id: payload?.insertion_order_id,
        selected_line_item: payload?.lineItem_type,
        line_item_name: payload?.line_item_name,
        flight_start_date: payload?.custom_startdate,
        flight_end_date: payload?.custom_enddate,
        frequencey_type: payload?.frequency_cap_type,
        limit_frequencey_value: payload?.frequency_limit,
        limit_frequencey_exposures_type: payload?.frequency_limit_type,
        limit_frequencey_exposures_value: payload?.frequency_cap_limit_value,
        conversion_tracking: 'string',
        conversion_counting: 'string',
        attribution_model: 'string',
        automated_bidding_type: payload?.bid_strategy,
        automated_bidding_option_one_value: payload?.automated_bid_type,
        automated_bidding_option_two_value: payload?.automated_bid_value,
        automated_bidding_exceed_amount: payload?.cpM_value,
        automated_bidding_prioritize_deal: payload?.priortize_deals,
        automate_bidding_prioritize_target_cpm:
          payload?.automate_bidding_prioritize_target_cpm,
        fixed_bid_val: payload?.fixed_bid_value,
        creative_optimization: payload?.creative_optimization,
        budget_spacing_type: payload?.budget_pacing_type,
        budget_spacing_first_type: payload?.budget_pacing_frequency,
        budget_spacing_second_type: payload?.budget_pacing_frequency_spread,
        budget_spacing_daily_price: payload?.budget_pacing_frequency_value,
        budget_spacing_amount:
          payload?.budget_pacing_type ==
          "Unlimited up to the insertion order's budget"
            ? 0
            : payload?.budget_pacing_fixed_budget_value,
        budget_spacing_second_option_type: payload?.budget_pacing_type,
        automated_bidding_exceed_avg: payload?.is_exceed_cpm,
        quality: payload?.invsource_quality,
        public_inventory: payload?.invsource_publicinventoryid,
        deals_and_inventory_packages: payload?.invsource_dealspackagesid,
        groups: payload?.invsource_groupid,
        created_by: payload?.created_by,
        status: payload?.status,
        created_at: dateFormat(moment().toDate()),
        creatives: [
          {
            creative_id: 0,
            line_item_id: 0,
            taxonomy_media_campaign_ad_cid_id: 0,
            med_id: 0,
            status: 0,
            created_at: '2023-12-01T09:58:12.153Z',
          },
        ],
        moderation_status: payload?.moderation_status,
        rejection_reason: payload?.rejection_reason,
      };
    }

    case DV_Reset_Values: {
      return initialState;
    }
    default:
      return state;
  }
};
